import { Box, Card, CardContent, Chip, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import StatCard from "../../components/statCard";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Lottie from "lottie-web";
import loadingwait from "./components/animations/loading.json";
import SessionStats from "../../components/sessionStats";
import { PieChart } from "@mui/x-charts/PieChart";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';

const Index = () => {
  function QuickSearchToolbar() {
    return <GridToolbarQuickFilter label="Recherchez ici" placeholder="" className="my-3" fullWidth />;
  }

  const [data, setData] = useState();
  const [id, setId] = useState();

  const token = Cookies.get("authToken");
  const isAdmin = JSON.parse(Cookies.get("authUser")).isAdmin;
  const isAce = JSON.parse(Cookies.get("authUser")).isAce;
  const isAgent = JSON.parse(Cookies.get("authUser")).isAgent;
  const iscompanyChief = Cookies.get("company_admin");

  const loadingref = useRef();

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  useEffect(() => {
    setId(JSON.parse(Cookies.get("authUser")).id);
    let api = "";
    if (isAdmin == 1) {
      api = "admin/home/admin_sys";
    } else if (isAce == 1 || isAgent == 1) {
      api = "admin/home/ace/" + id;
    } else if (isAgent == 1) {
      api = "admin/home/agn/" + id;
    } else if (iscompanyChief) {
      api = "admin/home/company_chief/" + id;
    } else {
      api = "";
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL + api}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const valueFormatter = (item) => `${item.value}%`;

  const columns = [
    { field: "raison_sociale", headerName: "Raison sociale", flex: 1 },
    { field: "activite_principale", headerName: "Activite principale", flex: 1 },
    { field: "type", headerName: "Type d'entreprise", flex: 1 },
    { field: "chef", headerName: "Chef d'entreprise", flex: 1 },
    { field: "chiffre_affaire", headerName: "Chiffre d'affaire", flex: 1 },
    { field: "cotisation_mensuelle", headerName: "Cotisations mensuelles", flex: 1 },
    {
      field: "statut",
      headerName: "Statut",
      flex: 1,
      renderCell: (params) => (params.value == 1 ? <Chip label="Actif" color="success" /> : <Chip label="Inactif" color="error" />),
    },
  ];

  const columns2 = [
    { field: "person", headerName: "Administrateurs", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Téléphone", flex: 1 },
    { field: "entreprise", headerName: "Entreprise", flex: 1 },
    {
      field: "statut",
      headerName: "Statut",
      flex: 1,
      renderCell: (params) => (params.value == 1 ? <Chip label="Actif" color="success" /> : <Chip label="Inactif" color="error" />),
    },
  ];
  console.log(data);

  return !data ? (
    <div className=" d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
      <div style={{ maxHeight: "100px", maxWidth: "100px" }} ref={loadingref}></div>
    </div>
  ) : (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1900px", margin: "0 auto" } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Dashboard
      </Typography>
      {isAdmin == 1 ? (
        <>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12} sm={6} lg={3}>
              <StatCard
                {...{
                  title: "Agents conseillers d'entreprises",
                  value: data.aces,
                  interval: "Last 30 days",
                  trend: "neutral",
                  route: "/dashboard/aces",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <StatCard
                {...{
                  title: "Agents de notations d'entreprises",
                  value: data.agents,
                  interval: "Last 30 days",
                  trend: "neutral",
                  route: "/dashboard/agents",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <StatCard
                {...{
                  title: "Chefs d'entreprises",
                  value: data.chiefs,
                  interval: "Last 30 days",
                  trend: "neutral",
                  route: "/dashboard/chefs_entreprises",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <StatCard
                {...{
                  title: "Autres utilisateurs",
                  value: data.others,
                  interval: "Last 30 days",
                  trend: "neutral",
                  route: "/dashboard/others",
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={2}>
            <Grid item xs={12} lg={6}>
              <SessionStats count={data.entreprises} data={data.cotisations} total={data.total_cotisations} />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <CardContent>
                  <Typography component="h2" variant="subtitle2" gutterBottom>
                    Entreprises
                  </Typography>

                  <Stack sx={{ justifyContent: "space-between" }}>
                    <Stack
                      direction="row"
                      sx={{
                        alignContent: { xs: "center", sm: "flex-start" },
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                      }}
                    >
                      <Typography variant="h4" component="p">
                        {data.entreprises}
                      </Typography>
                      <HomeWorkOutlinedIcon fontSize='large' />
                    </Stack>
                    <Typography variant="caption" sx={{ color: "text.secondary" }}>
                      Répartition des entreprises par types
                    </Typography>
                  </Stack>

                  <PieChart
                    series={[
                      {
                        data: data.types_entreprises,
                        highlightScope: { fade: "global", highlight: "item" },
                        faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
                        valueFormatter,
                      },
                    ]}
                    height={400}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Card variant="outlined" sx={{ width: "100%", mt: 2 }}>
            <CardContent>
              <Typography component="h2" variant="subtitle2" gutterBottom>
                Liste des entreprises
              </Typography>

              <DataGrid
                rows={data.infos_entreprises}
                columns={columns}
                sx={{
                  padding: "1em",
                  border: "none",
                  background: "white",
                  boxShadow: "none",
                  borderRadius: "3em",
                  "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "2px solid #8792c3",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-cell:focus-within,& .MuiDataGrid-columnHeader:focus": {
                    outline: "none",
                    boxShadow: "none",
                  },
                  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                    height: "7px",
                  },
                  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                    backgroundColor: "#a3aecb",
                    borderRadius: "5px",
                  },
                  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                    backgroundColor: "#e0e5f0",
                  },
                }}
                slots={{ toolbar: QuickSearchToolbar }}
                slotProps={{ toolbar: { showQuickFilter: true } }}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                pageSizeOptions={[10, 25, 30, { label: "Tout", value: data.infos_entreprises.length }]}
                disableRowSelectionOnClick
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            </CardContent>
          </Card>
        </>
      ) : iscompanyChief ? (
        <>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12} sm={6} lg={3}>
              <StatCard
                {...{
                  title: "Entreprises",
                  value: data.entreprise_count,
                  interval: "Last 30 days",
                  trend: "neutral",
                  route: "/dashboard/entreprises-ace/" + id,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <StatCard
                {...{
                  title: "Administrateurs secondaires",
                  value: data.admin_second_count,
                  interval: "Last 30 days",
                  trend: "neutral",
                  route: "/dashboard/agents",
                  visible:true
                }}
              />
            </Grid>
          </Grid>

          <Card variant="outlined" sx={{ width: "100%", mt: 2 }}>
            <CardContent>
              <Typography component="h2" variant="subtitle2" gutterBottom>
                Administrateurs secondaires
              </Typography>

              <DataGrid
                rows={data.admin_secondaire}
                columns={columns2}
                sx={{
                  padding: "1em",
                  border: "none",
                  background: "white",
                  boxShadow: "none",
                  borderRadius: "3em",
                  "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "2px solid #8792c3",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-cell:focus-within,& .MuiDataGrid-columnHeader:focus": {
                    outline: "none",
                    boxShadow: "none",
                  },
                  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                    height: "7px",
                  },
                  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                    backgroundColor: "#a3aecb",
                    borderRadius: "5px",
                  },
                  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                    backgroundColor: "#e0e5f0",
                  },
                }}
                slots={{ toolbar: QuickSearchToolbar }}
                slotProps={{ toolbar: { showQuickFilter: true } }}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                pageSizeOptions={[10, 25, 30, { label: "Tout", value: data.admin_secondaire.length }]}
                disableRowSelectionOnClick
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            </CardContent>
          </Card>
        </>
      ) : isAce == 1 ? (
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} sm={6} lg={3}>
            <StatCard
              {...{
                title: "Entreprises",
                value: data.entreprises,
                interval: "Last 30 days",
                trend: "neutral",
                route: "/dashboard/entreprises-ace/" + id,
              }}
            />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Index;
