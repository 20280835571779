import axios from 'axios';
import React, { useEffect, useState, useRef, createRef, Fragment } from 'react';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Alert, Autocomplete, Avatar, alpha, Badge, Box, Card, styled, FormControl, Grid, IconButton, MenuItem, Pagination, Stack, Tab, Tabs, TextField, Switch, Divider, Button, InputAdornment, Snackbar, Typography, Chip } from '@mui/material';
import { green } from '@mui/material/colors';
import { countries, diplomes, nationalites, naturesPiece, niveauxEtude } from '../components/liste';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PageHeader from '../components/pageHeader';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import NotationIcon from '@mui/icons-material/EditNote';
import { motion, AnimatePresence } from "framer-motion"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditIcon from '@mui/icons-material/Edit';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Dropzone from 'react-dropzone'
import Lottie from 'lottie-web';
import animationData from "../components/animations/not-found.json"
import loadingwait from "../components/animations/loading.json"
import switchLoading from "../components/animations/loaderSwitch.json"
import WorkIcon from '@mui/icons-material/Work';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { NumericFormat } from 'react-number-format';
import CloseIcon from '@mui/icons-material/Close';


const Admins = () => {

    dayjs.locale('fr');
    const [openDialog, setOpenDialog] = useState(false);
    const [users, setUsers] = useState();
    const [certifications, setCertification] = useState();
    const [token, seToken] = useState(Cookies.get("authToken"));
    const [searchTerm, setSearchTerm] = useState("");
    const [cardPerPage, setCardPerPage] = useState(10);
    const [isEmpty, setIsEmpty] = useState(false);
    const [switchRefs, setSwitchRef] = useState([]);
    const [findIndex, setFindIndex] = useState(0);
    const [success, setSuccess] = useState();
    const [errors, setErrors] = useState();
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [roles, setRoles] = useState();
    const [domaines, setDomaines] = useState();
    const [sliderLoading, setSliderLoader] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    ///refs
    const res = useRef();
    const loadingref = useRef()
    const containerRef = useRef()

    const reffirstname = useRef()
    const reflastname = useRef()
    const refemail = useRef()
    const refphone = useRef()
    const refpays = useRef()
    const refville = useRef()
    const refcommune = useRef()
    const refrue = useRef()
    const refnumPiece = useRef()
    const refnaturePiece = useRef()
    const refpiece_recto = useRef()
    const refpiece_verso = useRef()
    const refnationalite = useRef()
    const refbest_study = useRef()
    const refbest_diplome = useRef()
    const refspecialite = useRef()
    const refadhesion_date = useRef()
    const refformation_date = useRef()
    const refrecrut_date = useRef()
    const refniveauFormation = useRef()
    const refrole_id = useRef()
    const refgenre = useRef()
    const refbirthday = useRef()
    const refcertification = useRef()
    const refsolde = useRef()

    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [indicator, setIndicator] = useState();
    const [pays, setPays] = useState();
    const [ville, setVille] = useState();
    const [commune, setCommune] = useState();
    const [rue, setRue] = useState();
    const [numPiece, setNumPiece] = useState();
    const [naturePiece, setNaturePiece] = useState();
    const [certification, setCertificationSend] = useState();
    const [objectif, setObjectif] = useState();
    const [solde, setSolde] = useState();
    const [profil, setProfil] = useState();
    const [profilPreview, setProfilPreview] = useState()
    const [piece_recto, setPieceRecto] = useState();
    const [logoPreviewRecto, setLogoPreviewRecto] = useState()
    const [piece_verso, setPieceVerso] = useState();
    const [logoPreviewVerso, setLogoPreviewVerso] = useState()
    const [nationalite, setNationalite] = useState();
    const [best_study, setBestStudy] = useState();
    const [best_diplome, setBestDiplome] = useState();
    const [adhesion_date, setAdhesionDate] = useState();
    const [formation_date, setFormationDate] = useState();
    const [recrut_date, setRecrutDate] = useState();
    const [role_id, setRole] = useState();
    const [genre, setGenre] = useState();
    const [birthday, setBirthday] = useState();

    const [isACE, setTabValue] = useState(0);
    const [isjobseeker, setJobstatut] = useState(0);
    const [job, setJob] = useState(0);
    const [prevjob, setPrevJob] = useState(0);

    const [currentRaison, setCurrentraison] = useState();
    const [currentAdress, setCurrentAdress] = useState();
    const [currentPhone, setCurrentPhone] = useState();
    const [currentEmail, setCurrentEmail] = useState();
    const [currentFonction, setCurrentFonction] = useState();


    const [lastRaison, setLastraison] = useState();
    const [LastAdress, setLastAdress] = useState();
    const [lastPhone, setLastPhone] = useState();
    const [lastEmail, setLastEmail] = useState();
    const [lastFonction, setLastFonction] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}admin/admins`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }
                );

                const datas = response.data;
                setUsers(datas.users);
                setRoles(datas.roles);
                setDomaines(datas.domaines)
                setCertification(datas.certifications)
                const switchRefs = datas && datas.users.map(() => createRef())
                setSwitchRef(switchRefs)
            } catch (error) {
                console.error(error);
            }
        };
        fetchData()
    }, [reloadData]);


    const focusOnError = (errors, refs) => {
        for (const field in errors) {
            if (errors[field] && refs[field] && refs[field].current) {
                refs[field].current.focus({ behavior: 'smooth' });
                break;
            }
        }
    };
    const handleAddUser = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}admin/users/store`,
                {
                    profil,
                    firstname,
                    lastname,
                    email,
                    phone: `${indicator ? `+${indicator}` : ""} ${phone}`,
                    identifiant: phone,
                    pays,
                    nationalite,
                    ville,
                    commune,
                    rue,
                    num_piece: numPiece,
                    naturePiece,
                    piece_recto,
                    piece_verso,
                    nationalite,
                    best_study,
                    best_diplome,
                    adhesion_date,
                    formation_date,
                    objectif,
                    recrut_date,
                    role_id,
                    genre,
                    birthday,
                    certification: certification && certification.id,
                    fonction: 0,
                    solde,
                    currentRaison,
                    currentAdress,
                    currentPhone,
                    currentEmail,
                    currentFonction,
                    lastRaison,
                    LastAdress,
                    lastPhone,
                    lastEmail,
                    lastFonction,
                    isjobseeker,
                    job,
                    prevjob
                }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            if (response.data.success == true) {
                setReloadData(prev => !prev);
                setErrors()
                setSuccess(response.data.message)
                setOpenDialog(false)
                setTimeout(() => {
                    setOpenSnack(true)
                }, 50);
                res.current.reset()
                setProfil()
                setFirstname()
                setLastname()
                setEmail()
                setPhone()
                setPays()
                setVille()
                setCommune()
                setRue()
                setNumPiece()
                setNaturePiece()
                setPieceRecto()
                setPieceVerso()
                setNationalite()
                setBestStudy()
                setBestDiplome()
                setAdhesionDate()
                setFormationDate()
                setRecrutDate()
                setObjectif()
                setRole()
                setGenre()
                setBirthday()
                setCertificationSend()
                setSolde()
                setTabValue(0)
                setIndicator()
            } else {
                setSuccess()
                focusOnError(response.data.errors, {
                    firstname: reffirstname,
                    lastname: reflastname,
                    email: refemail,
                    phone: refphone,
                    pays: refpays,
                    nationalite: refnationalite,
                    ville: refville,
                    commune: refcommune,
                    rue: refrue,
                    num_piece: refnumPiece,
                    naturePiece: refnaturePiece,
                    piece_recto: refpiece_recto,
                    piece_verso: refpiece_verso,
                    nationalite: refnationalite,
                    best_study: refbest_study,
                    best_diplome: refbest_diplome,
                    specialite: refspecialite,
                    adhesion_date: refadhesion_date,
                    formation_date: refformation_date,
                    recrut_date: refrecrut_date,
                    niveauFormation: refniveauFormation,
                    role_id: refrole_id,
                    genre: refgenre,
                    birthday: refbirthday,
                    solde: refsolde
                });
                setErrors(response.data.errors)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    console.log(errors)
    const deleteObjectif = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}admin/users/delete/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
        } catch (error) {
            console.log(error)
        }
    };

    const handleDeleteUser = async (id) => {
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?');

        if (!confirmDelete) {
            return;
        }

        toast.promise(
            deleteObjectif(id),
            {
                pending: 'Suppression en cours...',
                success: "Suppression realisée avec succès!",
                error: "Il y'a eu une erreur lors de la suppression"
            }
        ).then(() => {
            setReloadData(prev => !prev)
        }).catch((error) => {
            console.error('Erreur lors de la suppression de l\'objectif', error);
        });

    };

    const handleClose = () => {
        setSuccess()
        setErrors()
        setOpenDialog(false)
        setFirstname()
        setLastname()
        setEmail()
        setPhone()
        setPays()
        setVille()
        setCommune()
        setRue()
        setNumPiece()
        setNaturePiece()
        setProfil()
        setPieceRecto()
        setPieceVerso()
        setNationalite()
        setBestStudy()
        setBestDiplome()
        setAdhesionDate()
        setFormationDate()
        setRecrutDate()
        setObjectif()
        setRole()
        setGenre()
        setBirthday()
        setTabValue(0)
        setCertificationSend()
        setSolde()
        setProfil()
        setIndicator()
    };

    const handleDropProfil = (acceptedFiles) => {
        setProfil(acceptedFiles);
        const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
        setProfilPreview(logoPreviewURL);
    };
    const handleRemoveProfil = () => {
        setProfil();
    };

    const handleDropRecto = (acceptedFiles) => {
        setPieceRecto(acceptedFiles);
        const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
        setLogoPreviewRecto(logoPreviewURL);
    };

    const handleRemoveRecto = () => {
        setPieceRecto();
    };
    const handleDropVerso = (acceptedFiles) => {
        setPieceVerso(acceptedFiles);
        const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
        setLogoPreviewVerso(logoPreviewURL);
    };

    const handleRemoveVerso = () => {
        setPieceVerso();
    };

    const deleteSwitch = async (id) => {
        try {
            const response = token && await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/users/state/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

        } catch (error) {
            console.log(error)
        }
    };
    const handleStatutChange = async (id) => {
        setSliderLoader(true)
        toast.promise(
            deleteSwitch(id),
            {
                pending: 'Changement en cours...',
                success: "Statut modifié avec succès!",
                error: "Il y'a eu une erreur lors du changement de statut"
            }
        ).then(() => {
            setReloadData(prev => !prev)
            setSliderLoader(false)
        }).catch((error) => {
            console.error('Erreur lors de la suppression de l\'objectif', error);
        });
    }

    const handleSoldeChange = (value) => {
        let cout_certification = certification && certification.cout;
        let solded = (value !== 'undefined' && value.floatValue !== 'undefined') && value.floatValue

        if (solded > cout_certification) {
            console.log("trop grand");
        } else {
            console.log("acceptable");
        }
        setSolde(solded)
    };


    refsolde.current && console.log(refsolde.current.helperText)

    const handleChangeTabValue = (event, newValue) => {
        setTabValue(newValue)
        setObjectif()
        setCertificationSend()
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const searchFields = [
        'firstname',
        'lastname',
        'pays',
        'ville',
        'commune',
        'rue',
        'email',
        'phone',
        'num_piece',
        'nature_piece',
        'gender',
        'best_study',
        'best_diplome',
        'role_id'
    ];
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * cardPerPage;
    const indexOfFirstItem = indexOfLastItem - cardPerPage;
    const currentItems = users && users.slice(indexOfFirstItem, indexOfLastItem);
    const filteredItems = searchTerm !== "" && users ? users.filter((user) =>
        searchFields.some((field) =>
            (field === 'role_id' ? user.role.title : user[field])?.toLowerCase().includes(searchTerm.toLowerCase())
        )
    ) : currentItems && currentItems.filter((user) =>
        searchFields.some((field) =>
            (field === 'role_id' ? user.role.title : user[field])?.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    const totalPages = Math.ceil(users && users.length / cardPerPage);

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };
    useEffect(() => {
        handleChangePage(null, 1);
    }, [searchTerm]);
    useEffect(() => {
        filteredItems && filteredItems.length == 0 ? setIsEmpty(true) : setIsEmpty(false)
    }, [filteredItems]);

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: containerRef.current,
            animationData: animationData,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        return () => {
            animation.destroy();
        };
    }, [isEmpty]);

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: switchRefs.length !== 0 && switchRefs[findIndex].current,
            animationData: switchLoading,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        return () => {
            animation.destroy();
        };
    }, [sliderLoading]);

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: loadingref.current,
            animationData: loadingwait,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        return () => {
            animation.destroy();
        };
    }, []);

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: green[600],
            '&:hover': {
                backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: green[600],
        },
    }));

    const Content = () => {
        return (
            <Box className='mt-3'>

                <form ref={res}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className='my-1 d-flex justify-content-center align-items-center'>
                            <Dropzone onDrop={handleDropProfil}>
                                {({ getRootProps, getInputProps }) => (
                                    <section className="mb-2">
                                        <div {...getRootProps()}>
                                            {!profil && <input {...getInputProps()} />}
                                            <Box>
                                                <Badge
                                                    role="button"
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    sx={{
                                                        borderRadius: "50%",
                                                        boxShadow: "22px 22px 58px #d0d0d0,-22px -22px 58px #f0f0f0"
                                                    }}
                                                    className='d-flex justify-content-center align-items-center'
                                                    badgeContent={
                                                        <>
                                                            {
                                                                !profil ? (
                                                                    <IconButton aria-label="share"
                                                                        sx={{
                                                                            backgroundColor: "white",
                                                                            borderRadius: "50%",
                                                                            boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                            '&:hover': {
                                                                                backgroundColor: "white"
                                                                            }
                                                                        }}
                                                                    >
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton aria-label="share"
                                                                        onClick={handleRemoveProfil}
                                                                        sx={{
                                                                            backgroundColor: "white",
                                                                            borderRadius: "50%",
                                                                            boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                            '&:hover': {
                                                                                backgroundColor: "white"
                                                                            }
                                                                        }}
                                                                    >
                                                                        <DeleteSweepIcon />
                                                                    </IconButton>
                                                                )

                                                            }


                                                        </>
                                                    }
                                                >
                                                    <div className='p-1 '>
                                                        <Avatar
                                                            alt="Logo"
                                                            src={profil ? profilPreview : "/no-profil.jpg"}
                                                            role='button'
                                                            loading="lazy"
                                                            sx={{ width: 120, height: 120 }}
                                                        />
                                                    </div>
                                                </Badge>


                                            </Box>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    tabIndex={-1}
                                    id="country-select-demo2"
                                    onChange={(event, newValue) => {
                                        setRole(newValue ? newValue.id : null);
                                    }}
                                    ref={refrole_id}
                                    value={role_id}
                                    options={roles && roles}
                                    autoHighlight
                                    fullWidth
                                    getOptionLabel={(option) => option ? option.title : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Choisir la fonction de l'employé"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}

                                            error={errors && errors.role_id}
                                            helperText={
                                                errors && errors.role_id && errors.role_id.join(', ')
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    tabIndex={-1}
                                    ref={reffirstname}
                                    error={errors && errors.firstname}
                                    helperText={
                                        errors && errors.firstname && errors.firstname.join(', ')
                                    }
                                    fullWidth
                                    value={firstname}
                                    label="Nom"
                                    onChange={(e) => setFirstname(e.target.value)}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                tabIndex={-1}
                                ref={reflastname}
                                error={errors && errors.lastname}
                                helperText={
                                    errors && errors.lastname && errors.lastname.join(', ')
                                }
                                fullWidth
                                value={lastname}
                                label="Prénoms"
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    tabIndex={-1}
                                    ref={refgenre}
                                    select
                                    error={errors && errors.genre}
                                    helperText={
                                        errors && errors.genre && errors.genre.join(', ')
                                    }
                                    fullWidth
                                    value={genre}
                                    label="Genre"
                                    onChange={(e) => setGenre(e.target.value)}
                                >
                                    <MenuItem value="homme">Homme</MenuItem>
                                    <MenuItem value="femme">Femme</MenuItem>
                                    <MenuItem value="n/a">Non defini</MenuItem>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="country-select-demo"
                                    value={pays}
                                    onChange={(event, newValue) => {
                                        setPays(newValue ? newValue : null);
                                    }}

                                    fullWidth

                                    options={countries}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                alt=""
                                            />
                                            {option.label} ({option.code}) +{option.phone}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Choisir le pays"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            ref={refpays}
                                            error={errors && errors.pays}
                                            helperText={errors && errors.pays && errors.pays.join(', ')}
                                        />
                                    )}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    tabIndex={-1}
                                    ref={refville}
                                    error={errors && errors.ville}
                                    helperText={
                                        errors && errors.ville && errors.ville.join(', ')
                                    }
                                    fullWidth
                                    value={ville}
                                    label="Ville"
                                    onChange={(e) => setVille(e.target.value)}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    tabIndex={-1}
                                    ref={refcommune}
                                    error={errors && errors.commune}
                                    helperText={
                                        errors && errors.commune && errors.commune.join(', ')
                                    }
                                    fullWidth
                                    value={commune}
                                    label="Commune"
                                    onChange={(e) => setCommune(e.target.value)}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    tabIndex={-1}
                                    ref={refrue}
                                    error={errors && errors.rue}
                                    helperText={
                                        errors && errors.rue && errors.rue.join(', ')
                                    }
                                    fullWidth
                                    value={rue}
                                    label="Rue"
                                    onChange={(e) => setRue(e.target.value)}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <FormControl fullWidth>
                                    <DatePicker
                                        onChange={(date) => setBirthday(date)}
                                        label="Date de naissance"
                                        value={birthday}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    tabIndex={-1}
                                    ref={refemail}
                                    error={errors && errors.email}
                                    helperText={
                                        errors && errors.email && errors.email.join(', ')
                                    }
                                    fullWidth
                                    value={email}
                                    label="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={5} className='d-flex'>
                            <FormControl fullWidth sx={{ maxWidth: "80px" }}>
                                <Autocomplete
                                    sx={{ "& .MuiAutocomplete-inputRoot": { borderRadius: 0 } }}
                                    disableClearable={true}
                                    forcePopupIcon={false}
                                    id="country-select-demo"
                                    value={indicator}
                                    onChange={(event, newValue) => {
                                        setIndicator(newValue && newValue.phone);
                                    }}
                                    fullWidth
                                    options={countries}
                                    autoHighlight
                                    getOptionLabel={(option) => option ? `+${option.phone}` : ''}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { flexShrink: 0 }, '& > span': { display: 'none' } }} {...props}>
                                            {`+${option.phone}`}
                                        </Box>
                                    )}

                                    renderInput={(params) => (
                                        <TextField

                                            {...params}
                                            label="Indice"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',

                                            }}
                                            ref={refpays}
                                            error={errors && errors.pays}
                                            helperText={errors && errors.pays && errors.pays.join(', ')}

                                        />
                                    )}
                                />

                            </FormControl>
                            <FormControl fullWidth >
                                <TextField
                                    sx={{ "& .MuiInputBase-root": { borderRadius: 0 } }}
                                    type="number"
                                    tabIndex={-1}
                                    ref={refphone}
                                    error={errors && errors.phone}
                                    helperText={
                                        errors && errors.phone && errors.phone.join(', ')
                                    }
                                    fullWidth
                                    value={phone}
                                    label="Téléphone"
                                    onChange={(e) => setPhone(e.target.value)}
                                />

                            </FormControl>


                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="country-select-demo2"
                                onChange={(event, newValue) => {
                                    setNationalite(newValue && newValue.label);
                                }}
                                value={nationalite}
                                options={nationalites}
                                autoHighlight
                                fullWidth
                                getOptionLabel={(option) => option ? option.label : ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Nationalité"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                        ref={refnationalite}
                                        error={errors && errors.nationalite}
                                        helperText={
                                            errors && errors.nationalite && errors.nationalite.join(', ')
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    tabIndex={-1}
                                    ref={refbest_study}
                                    select
                                    error={errors && errors.best_study}
                                    helperText={
                                        errors && errors.best_study && errors.best_study.join(', ')
                                    }
                                    fullWidth
                                    value={best_study}
                                    label="Meilleur niveau d'étude"
                                    onChange={(e) => setBestStudy(e.target.value)}
                                >
                                    <MenuItem value="">...</MenuItem>
                                    {
                                        niveauxEtude.map((study) => {
                                            return (
                                                <MenuItem value={study.value}>{study.label}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    tabIndex={-1}
                                    ref={refbest_diplome}
                                    select
                                    error={errors && errors.best_diplome}
                                    helperText={
                                        errors && errors.best_diplome && errors.best_diplome.join(', ')
                                    }
                                    fullWidth
                                    value={best_diplome}
                                    label="Meilleur diplome"
                                    onChange={(e) => setBestDiplome(e.target.value)}
                                >
                                    <MenuItem value="">...</MenuItem>
                                    {
                                        diplomes.map((diplome) => {
                                            return (
                                                <MenuItem value={diplome.value}>{diplome.label}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} className='mt-2'>
                            <span className='h6 fw-light'>Veuillez renseigner ci-dessous les informations concernant une pièce d'identité de l'employé</span>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    tabIndex={-1}
                                    ref={refnaturePiece}
                                    select
                                    error={errors && errors.naturePiece}
                                    helperText={
                                        errors && errors.naturePiece && errors.naturePiece.join(', ')
                                    }
                                    fullWidth
                                    value={naturePiece}
                                    label="Nature de la pièce"
                                    onChange={(e) => setNaturePiece(e.target.value)}
                                >
                                    <MenuItem value="">...</MenuItem>
                                    {
                                        naturesPiece.map((nature) => {
                                            return (
                                                <MenuItem value={nature.value}>{nature.label}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <TextField

                                    tabIndex={-1}
                                    ref={refnumPiece}
                                    error={errors && errors.num_piece}
                                    helperText={
                                        errors && errors.num_piece && errors.num_piece.join(', ')
                                    }
                                    fullWidth
                                    value={numPiece}
                                    label="Numero de la pièce"
                                    onChange={(e) => setNumPiece(e.target.value)}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} className='d-flex justify-content-center align-items-center '>
                            <Dropzone accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onDrop={handleDropRecto}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} >
                                        {!piece_recto && <input {...getInputProps()} />}
                                        <Badge
                                            role="button"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            sx={{
                                                border: "2px solid #f3f7fb"
                                            }}
                                            badgeContent={
                                                <>
                                                    {
                                                        !piece_recto ? (
                                                            <IconButton aria-label="share"
                                                                sx={{
                                                                    backgroundColor: "white",
                                                                    borderRadius: "50%",
                                                                    boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                    '&:hover': {
                                                                        backgroundColor: "white"
                                                                    }
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton aria-label="share"
                                                                onClick={handleRemoveRecto}
                                                                sx={{
                                                                    backgroundColor: "white",
                                                                    borderRadius: "50%",
                                                                    boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                    '&:hover': {
                                                                        backgroundColor: "white"
                                                                    }
                                                                }}
                                                            >
                                                                <DeleteSweepIcon />
                                                            </IconButton>
                                                        )
                                                    }
                                                </>
                                            }
                                        >
                                            <Box sx={{ height: "150px", width: "150px" }} className="d-flex flex-column justify-content-center align-items-center p-2">
                                                <div>Recto</div>
                                                <img
                                                    alt="Piece-recto"
                                                    src={piece_recto ? logoPreviewRecto : "/no-file.png"}
                                                    className='img-fluid'
                                                    role='button'
                                                    loading="lazy"
                                                    sx={{ width: "100%", height: "100%" }}
                                                />
                                            </Box>
                                        </Badge>
                                    </div>
                                )}
                            </Dropzone>
                        </Grid>
                        <Grid item xs={12} sm={3} className='d-flex justify-content-center align-items-center '>
                            <Dropzone accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onDrop={handleDropVerso}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} >
                                        {!piece_verso && <input {...getInputProps()} />}
                                        <Badge
                                            role="button"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            sx={{
                                                border: "2px solid #f3f7fb"
                                            }}
                                            badgeContent={
                                                <>
                                                    {
                                                        !piece_verso ? (
                                                            <IconButton aria-label="share"
                                                                sx={{
                                                                    backgroundColor: "white",
                                                                    borderRadius: "50%",
                                                                    boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                    '&:hover': {
                                                                        backgroundColor: "white"
                                                                    }
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton aria-label="share"
                                                                onClick={handleRemoveVerso}
                                                                sx={{
                                                                    backgroundColor: "white",
                                                                    borderRadius: "50%",
                                                                    boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                    '&:hover': {
                                                                        backgroundColor: "white"
                                                                    }
                                                                }}
                                                            >
                                                                <DeleteSweepIcon />
                                                            </IconButton>
                                                        )
                                                    }
                                                </>
                                            }
                                        >
                                            <Box sx={{ height: "150px", width: "150px" }} className="d-flex flex-column justify-content-center align-items-center p-2">
                                                <div>Verso</div>

                                                <img
                                                    alt="Piece-recto"
                                                    src={piece_verso ? logoPreviewVerso : "/no-file.png"}
                                                    className='img-fluid'
                                                    role='button'
                                                    loading="lazy"
                                                    sx={{ width: "100%", height: "100%" }}
                                                />
                                            </Box>
                                        </Badge>
                                    </div>
                                )}
                            </Dropzone>
                        </Grid>

                    </Grid>
                </form>
            </Box>
        )
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const action = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnack}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );
    const container = {
        hide: {
            opacity: 0,
            scale: 0
        },
        show: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.4,
                staggerChildren: 0.3
            }
        },
        exit: {
            y: 50,
            opacity: 0
        }

    }
    const item = {
        hide: {
            y: -50,
            opacity: 0
        },
        show: {
            y: 0,
            opacity: 1
        },
        exit: {
            y: 50,
            opacity: 0
        }

    }

    return (
        <>
            {
                success && (
                    <Snackbar
                        open={openSnack}
                        autoHideDuration={5000}
                        onClose={handleCloseSnack}
                        action={action}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                            {success}
                        </Alert>
                    </Snackbar>
                )
            }
            < PageHeader
                title={`Administrateurs (${users ? users.length : "..."})`}
                dialogTitle="Enregistrement des informations d'un administrateur"
                subtitle="Liste des administrateurs"
                labelButton="Ajouter un administrateur"
                content={Content()}
                loading={loading}
                icon={<SupervisedUserCircleIcon sx={{ color: "#3887BE", fontSize: "2em" }} />}
                onClick={handleAddUser}
                onClose={handleClose}
                open={openDialog}
                setOpen={setOpenDialog}
            />

            <br />
            {
                users && (
                    <motion.div
                    >
                        <Stack spacing={2} direction="row" >
                            <FormControl className='col-8 col-md-10'>
                                <TextField
                                    variant='standard'
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    label="Rechercher ici"
                                    value={searchTerm}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </FormControl>
                            <FormControl className='col-4 col-md-2'>
                                <TextField

                                    select
                                    value={cardPerPage}
                                    label="Cadre par page"
                                    onChange={(e) => setCardPerPage(e.target.value)}
                                    sx={{ minWidth: "3em" }}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={users && users.length}>Tout</MenuItem>
                                </TextField>
                            </FormControl>
                        </Stack>
                    </motion.div>
                )
            }
            {
                !users ? (
                    <div
                        className=' d-flex justify-content-center align-items-center' style={{ minHeight: '45vh' }}
                    >
                        <div
                            style={{ maxHeight: "100px", maxWidth: "100px" }}
                            ref={loadingref}
                        >

                        </div>
                    </div>

                ) : filteredItems.length > 0 ? (

                    <div>

                        <motion.div variants={container} initial="hide" animate="show" exit="exit">
                            <Grid container spacing={2} className='my-1'>

                                <AnimatePresence>
                                    {
                                        filteredItems && filteredItems.map((user, index) => {
                                            return (
                                                <Grid item xs={12} sm={6} sx={{ fontFamily: "roboto" }} key={index}>
                                                    <motion.div

                                                        whileHover={{
                                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                                                        }}
                                                        variants={item}
                                                        exit={{ y: 50, opacity: 0 }}
                                                    >

                                                        <Card onClick={() => setFindIndex(index)} sx={{ boxShadow: "none", backgroundColor: "white" }} className="p-2 position-relative">
                                                            <div className='position-absolute ' style={{ height: "0", width: "0", borderLeft: "40px solid transparent", borderRight: "40px solid transparent", borderBottom: "40px solid rgba(165 ,167, 177,0.2)", right: "-27px", top: "-10px", color: "#5d626f", rotate: "45deg" }}>
                                                                {
                                                                    user.isAce == 1 ? (
                                                                        <AssignmentIndIcon fontSize='small' sx={{ rotate: "-45deg", margin: "15px 0 0 -6px" }} />
                                                                    ) : user.isAdmin == 1 ? (
                                                                        <DashboardIcon fontSize='small' sx={{ rotate: "-45deg", margin: "15px 0 0 -6px" }} />
                                                                    ) : user.isAgent == 1 && (
                                                                        <NotationIcon fontSize='small' sx={{ rotate: "-45deg", margin: "15px 0 0 -6px" }} />
                                                                    )
                                                                }
                                                            </div>


                                                            <Box>
                                                                <Box className="my-2">
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12} sm={6} className="d-flex justify-content-center align-items-center d-md-block">
                                                                            <Avatar
                                                                                src={`${process.env.REACT_APP_BASE_URL}${user.image}`}
                                                                                alt={user.firstname}
                                                                                sx={{ height: "150px", width: "150px" }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6} className="d-flex justify-content-center align-items-center d-md-block">
                                                                            <Stack spacing={1}>
                                                                                <Box className="text-center text-md-start" sx={{ fontWeight: "600" }}>{user.lastname.split(" ")[0]} {user.firstname}</Box>
                                                                                <Stack direction="row" spacing={1}>
                                                                                    <IconButton sx={{ backgroundColor: "rgba(165 ,167, 177,0.2)", color: "#5d626f" }}>
                                                                                        <WorkIcon />
                                                                                    </IconButton>
                                                                                    <Stack>
                                                                                        <span style={{ color: "#5d626f" }}>Fonction</span>
                                                                                        <span>{user.role && user.role.title}</span>
                                                                                    </Stack>
                                                                                </Stack>

                                                                                <Stack direction="row" spacing={1}>
                                                                                    <IconButton sx={{ backgroundColor: "rgba(165 ,167, 177,0.2)", color: "#5d626f" }}>
                                                                                        <LocalPhoneIcon />
                                                                                    </IconButton>
                                                                                    <Stack>
                                                                                        <span style={{ color: "#5d626f" }}>Téléphone</span>
                                                                                        <span>{user.phone}</span>
                                                                                    </Stack>
                                                                                </Stack>
                                                                                <Stack direction="row" spacing={1}>
                                                                                    <IconButton sx={{ backgroundColor: "rgba(165 ,167, 177,0.2)", color: "#5d626f" }}>
                                                                                        <EditAttributesIcon />
                                                                                    </IconButton>
                                                                                    <Stack>
                                                                                        <span style={{ color: "#5d626f" }}>Statut ({user.statut == 0 ? 'inactif' : 'actif'}) </span>
                                                                                        {
                                                                                            sliderLoading && findIndex == index ? (
                                                                                                <div className='d-flex justify-content-start'
                                                                                                    style={{ height: "25.42px", width: "92.88px" }}>
                                                                                                    <div ref={switchRefs[index]} />
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    key={user.id}
                                                                                                >
                                                                                                    <GreenSwitch size='small' defaultChecked={user.statut == 1} onChange={() => handleStatutChange(user.id)} />

                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </Stack>
                                                                                </Stack>
                                                                            </Stack>

                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                                <Divider variant="middle" />
                                                                <Box className="my-2">
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={6}>
                                                                            <Link to={`/dashboard/admin/show/${user.id}`} className='d-flex'>
                                                                                <Button fullWidth className='text-capitalize d-flex justify-content-center align-items-center' sx={{ backgroundColor: "rgba(165 ,167, 177,0.2)", color: "#5d626f" }}>
                                                                                    Voir profil
                                                                                </Button>
                                                                            </Link>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <Link to={`/dashboard/admin/edit/${user.id}`} className='d-flex'>
                                                                                <Button fullWidth className='text-capitalize d-flex justify-content-center align-items-center' sx={{ backgroundColor: "rgba(165 ,167, 177,0.2)", color: "#5d626f" }}>
                                                                                    <BorderColorIcon sx={{ color: "#5d626f" }} />
                                                                                </Button>
                                                                            </Link>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <Link onClick={() => handleDeleteUser(user.id)} className='d-flex'>
                                                                                <Button fullWidth className='text-capitalize d-flex justify-content-center align-items-center' sx={{ backgroundColor: "rgba(165 ,167, 177,0.2)", color: "#5d626f" }}>
                                                                                    <DeleteSweepIcon sx={{ color: "#5d626f" }} />
                                                                                </Button>
                                                                            </Link>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Box>
                                                        </Card>
                                                    </motion.div>
                                                </Grid>
                                            )
                                        }
                                        )
                                    }
                                </AnimatePresence>
                            </Grid>
                        </motion.div>

                        <br />
                        {
                            searchTerm == "" && (
                                <Box className="d-flex justify-content-center align-items-center">
                                    <Pagination
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={handleChangePage}
                                        variant="outlined"
                                        color="primary"
                                        showFirstButton
                                        showLastButton />
                                </Box>
                            )

                        }
                        <br />
                    </div >

                ) : filteredItems.length == 0 ? (
                    <div className='text-center d-flex justify-content-center align-items-center' style={{ minHeight: '60vh' }}>
                        <div ref={containerRef}>

                        </div>
                    </div>
                ) : ""
            }

            <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
                pauseOnHover={false}
            />
        </>
    );
}

export default Admins;
