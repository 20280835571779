import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Cookies from "js-cookie"
import { Table, FormGroup, FormControl, FormCheck, Row, Col, Modal, Form, Button, CloseButton } from 'react-bootstrap'
import Card from '../../../components/Card';
import PermissionsManagment from '../components/permissionsManagment';
import { Alert, AlertTitle, Checkbox, Collapse, FormControlLabel, IconButton } from '@mui/material';

// Importer ToastContainer de react-toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingButton from '@mui/lab/LoadingButton';
const Roles = () => {
    const token = Cookies.get('authToken');
    const [roles, setRoles] = useState();
    const [reloadData, setReloadData] = useState(false);

    const [allpermissions, setAllPermissions] = useState();
    const [permissions, setPermissions] = useState([]);
    const [permissionsu, setPermissionsu] = useState([]);
    const [title, setTitre] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState()
    const [errors, setErrors] = useState()
    const [allPermissionsChecked, setAllPermissionsChecked] = useState(false);


    const [open, setOpen] = useState(false);
    const err = useRef();
    const res = useRef();
    const resb = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/roles`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const datas = response.data;
                setRoles(datas.roles);
                setAllPermissions(datas.permissions)
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [reloadData]);

    const [showModal, setShowModal] = useState(null);
    const [selectedRoleId, setSelectedRoleId] = useState(null);

    const handleModalClose = () => {
        setShowModal(null);
    };

    const globalFetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/roles`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const datas = response.data;
            setRoles(datas.roles);
        } catch (error) {
            console.log(error);
        }
    };
    const handleCheckboxChange = (permissionId) => {
        setPermissions((prevSelectedPermissions) => {
            if (prevSelectedPermissions.includes(permissionId)) {
                return prevSelectedPermissions.filter((id) => id !== permissionId);
            } else {
                return [...prevSelectedPermissions, permissionId];
            }
        });
    };
    const handleAllPermissionsChange = () => {
        if (!allPermissionsChecked) {
            const allPermissionIds = allpermissions.map(permission => permission.id);
            setPermissions(allPermissionIds);
        } else {
            setPermissions([]);
        }
        setAllPermissionsChecked(prev => !prev);
    };


    const handleAdd = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}admin/roles/store`, { title, permissions }, {
                headers: { Authorization: `Bearer ${token}` },
            })
            if (response.data.success == true) {
                setOpen(true)
                setErrors(null)
                setSuccess(response.data.message)
                setTimeout(() => {
                    err.current.focus()
                }, 500);
                res.current.reset()
                setTitre("")
                setPermissions([])
            } else {
                setSuccess(null)
                setErrors(response.data.errors)
                setOpen(true)
                setTimeout(() => {
                    err.current.focus()
                }, 500);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            globalFetchData()
        }
    }

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        setTitre("")
        setSuccess()
        setErrors()
        setPermissions([])
        setAllPermissionsChecked(false)
        setOpen(false)
    };
    const handleShow = () => setShow(true);


    const handleUpdateButtonClick = (role) => {
        setSelectedRoleId(role.id);
        setShowModal(role.id);
        setTitre(role.title);
        setPermissionsu(role.permissions.map(permission => permission.id));
    };

    const handleUpdateSelected = (permissionId) => {
        setPermissionsu((prevSelectedPermissions) => {
            const isPermissionSelected = prevSelectedPermissions.includes(permissionId);

            if (isPermissionSelected) {
                return prevSelectedPermissions.filter((id) => id !== permissionId);
            } else {
                return [...prevSelectedPermissions, permissionId];
            }
        });
    };

    const handleUpdate = async (id) => {
        setLoading(true);
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}admin/roles/update/${id}`,
                {
                    title,
                    permissionsu
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.success == true) {
                setReloadData(prev => !prev)
                setOpen(true)
                setErrors()
                setSuccess(response.data.message)
            } else {
                setSuccess()
                setErrors(response.data.errors)
                setOpen(true)
                setTimeout(() => {
                    err.current.focus()
                }, 500);
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    const deleteObjectif = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}admin/roles/delete/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
        } catch (error) {
            console.error("Erreur lors de la suppression du rôle :", error);
        }
    };
    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cet role ?');
        if (!confirmDelete) {
            return;
        }
        toast.promise(
            deleteObjectif(id),
            {
                pending: 'Suppression en cours...',
                success: "Suppression realisée avec succès!",
                error: "Il y'a eu une erreur lors de la suppression"
            }
        ).then(() => {
            globalFetchData();
        }).catch((error) => {
            console.error('Erreur lors de la suppression de l\'objectif', error);
        });
    };

    console.log(errors)

    return (

        <Row>

            <Col sm="12">

                <Card>
                    <Card.Header className="d-flex justify-content-between flex-wrap">
                        <div className="header-title">
                            <h4 className="card-title mb-0">Roles</h4>

                        </div>

                        <div>
                            <Button className="text-center btn-primary btn-icon me-2 mt-lg-0 mt-md-0 mt-3" onClick={handleShow}>
                                <i className="btn-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                </i>
                                <span>Ajouter un role</span>
                            </Button>
                            <Modal show={show} onHide={handleClose} centered backdrop="static">

                                <Modal.Header closeButton>
                                    <Modal.Title>Ajouter un role</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Collapse ref={err} tabIndex={-1} in={open}>
                                        <Alert
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <CloseButton style={{ fontSize: '.5em', color: 'white' }} />
                                                </IconButton>
                                            }
                                            severity={errors ? "error" : success ? "success" : ""}
                                        >
                                            <AlertTitle>
                                                {success ? ("Création reussie") : errors ? ("Erreur lors de la création:") : null}
                                            </AlertTitle>
                                            {success ?
                                                (
                                                    <div>{success} <span>Pour revenir à la liste des roles cliquez sur le boutton ci-après ou réaliser un autre enregistrement. <span onClick={handleClose} style={{ textDecoration: 'underline', cursor: 'pointer' }}> Revenir à la liste</span> </span></div>
                                                ) :
                                                errors ? (errors) :
                                                    null}
                                        </Alert>
                                    </Collapse>
                                    <br />
                                    <Form ref={res} onSubmit={handleAdd} method='POST'>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Titre</Form.Label>
                                            <Form.Control type="text" onChange={e => setTitre(e.target.value)} placeholder="Titre du role" />
                                        </Form.Group>
                                        <FormGroup>
                                            <Form.Label>Permissions</Form.Label>
                                            <FormControlLabel
                                                key="allpermiss"
                                                control={<Checkbox checked={allPermissionsChecked} onChange={handleAllPermissionsChange} />}
                                                label="Attribuer toutes les permissions"
                                            />
                                            {
                                                allpermissions ? (
                                                    allpermissions.map((permission) => (
                                                        <FormControlLabel checked={permissions.includes(permission.id)} key={permission.id} control={<Checkbox onChange={() => handleCheckboxChange(permission.id)} />} label={permission.name} />
                                                    ))
                                                ) : "Chargement de la liste des permissions..."
                                            }
                                        </FormGroup>
                                        <Button variant='primary' onClick={handleAdd}> {loading ? "Enregistrement en cours..." : "Enregistrer"} </Button>
                                        {' '}
                                        <Button variant="danger" type='reset' onClick={handleClose}>
                                            Annuler
                                        </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {
                            roles ? (
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th scope="col">Titre</th>
                                            <th>Permissions</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {roles.map((role, index) => (
                                            <tr key={index}>
                                                <td>{role.title}</td>
                                                <td>
                                                    <PermissionsManagment role={role} permissions={allpermissions} />
                                                </td>
                                                <td>
                                                    <div className='d-flex justify-content-center gap-2'>
                                                        <Button variant="primary" onClick={() => handleUpdateButtonClick(role)}>
                                                            Modifier
                                                        </Button>
                                                        <Button variant="danger" onClick={() => handleDelete(role.id)}>
                                                            Supprimer
                                                        </Button>
                                                        <Modal show={showModal === role.id} onHide={handleModalClose} >
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Modification des informations de la fonction {role.title}</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <Form>
                                                                    <Form.Group controlId="title">
                                                                        <Form.Label>Titre</Form.Label>
                                                                        <Form.Control type="text" defaultValue={title} onChange={(e) => setTitre(e.target.value)} />
                                                                    </Form.Group>
                                                                    <Form.Group controlId="permissions">
                                                                        <Form.Label>Permissions</Form.Label>
                                                                        {allpermissions ? (
                                                                            allpermissions.map((permission) => (
                                                                                <Form.Check
                                                                                    key={permission.id}
                                                                                    type="checkbox"
                                                                                    id={permission.id}
                                                                                    label={permission.name}
                                                                                    checked={permissionsu.includes(permission.id)}
                                                                                    onChange={() => handleUpdateSelected(permission.id)}
                                                                                />
                                                                            ))
                                                                        ) : "Chargement de la liste des permissions..."}
                                                                    </Form.Group>
                                                                </Form>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={() => handleModalClose(role.id)}>
                                                                    Annuler
                                                                </Button>
                                                                <Button variant="primary" onClick={() => handleUpdate(role.id)}>
                                                                    {loading ? "Enregistrement en cours..." : "Enregistrer"}
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            ) :
                                'Chargement...'
                        }
                    </Card.Body>
                    <ToastContainer />
                </Card>


            </Col>
        </Row>
    );
}

export default Roles;