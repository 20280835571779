import { Box, CardHeader, IconButton, Tooltip, Button } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Lottie from "lottie-web";
import loadingwait from "../components/animations/loading.json";
import Cookies from "js-cookie";
import axios from "axios";
import ReplyIcon from "@mui/icons-material/Reply";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as dayjs from "dayjs";
import { formatNumber } from "../../../utilities/numberFormat";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdobe } from "@fortawesome/free-brands-svg-icons";
import Icon from "@mdi/react";
import { mdiFilePdfBox, mdiMicrosoftExcel } from "@mdi/js";
import { EtatCompany } from "../../../store/setting/context";

const DetailsMonth = () => {
    const {etat,setEtat}=useContext(EtatCompany);
  const { id, mat } = useParams();
  const loadingref = useRef();
  const token = Cookies.get("authToken");

  const [year, setYear] = useState();
  const [entreprise, setEntreprise] = useState();
  const [datas, setDatas] = useState();
  const [bilanMonth, setBilanMonth] = useState();
  const [month, setMonth] = useState();
  const [days, setDays] = useState([]);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  // Récupération des informations du compte résultat
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/cr/${mat}/details_month/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setYear(response.data.year);
        setEntreprise(response.data.entreprise);
        setDatas(response.data.datas);
        setBilanMonth(response.data.bilanMonth);
        setMonth(response.data.month);
        setDays(response.data.days);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [id, month, mat, token, reloadData]);

  function monthLabel(month) {
    const months = {
      "01": "Janvier",
      "02": "Février",
      "03": "Mars",
      "04": "Avril",
      "05": "Mai",
      "06": "Juin",
      "07": "Juillet",
      "08": "Août",
      "09": "Septembre",
      10: "Octobre",
      11: "Novembre",
      12: "Décembre",
    };

    return months[month];
  }

  const deleteEnreg = async (date) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}admin/enregistrement/delete/${date}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error("Erreur lors de la suppression");
    }
  };

  const handleDeleteEnreg = async (id, date) => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet enregistrement ? Cela risque de supprimer des informations sensibles et mettra automatiquement à jour l'exercie mensuel et annuel de ce compte de resultat."
    );

    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}admin/enregistrement/delete/${bilanMonth.id}/${date}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Suppression réalisée avec succès!");
      setReloadData((prev) => !prev);
    } catch (error) {
      console.error("Erreur lors de la suppression des informations", error);
      toast.error("Il y a eu une erreur lors de la suppression");
    }
  };

  // function formatNumber(number) {
  //     return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  // }

  const handleGeneratePDF = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/generate-mensual-bilan-pdf/${mat}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob", // Important pour télécharger le PDF correctement
      });

      // Créer une URL pour le blob
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));

      // Créer un lien et cliquer dessus pour télécharger le fichier
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Exercice au ${dayjs(`${year}-${month}-01`).endOf("month").date()} ${monthLabel(month)} ${year}_${entreprise ? entreprise.raison_sociale : "..."}.pdf`
      );
      document.body.appendChild(link);
      link.click();

      // Nettoyer l'URL de l'objet après le téléchargement
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erreur lors de la génération du PDF:", error);
      toast.error("Erreur lors de la génération du PDF");
    }
  };

  const handleGenerateExcel = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/generate-mensual-bilan-excel/${mat}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob", // Important pour télécharger le fichier correctement
      });

      // Créer une URL pour le blob
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));

      // Créer un lien et cliquer dessus pour télécharger le fichier
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Exercice au ${dayjs(`${year}-${month}-01`).endOf("month").date()} ${monthLabel(month)} ${year}_${entreprise ? entreprise.raison_sociale : "..."}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      // Nettoyer l'URL de l'objet après le téléchargement
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erreur lors de la génération de l'Excel:", error);
      toast.error("Erreur lors de la génération de l'Excel");
    }
  };

  return (
    <>
      <ToastContainer autoClose={5000} hideProgressBar={true} pauseOnHover={false} />

      {year && entreprise && datas && bilanMonth ? (
        <Box style={{ height: "100vh" }}>
          <CardHeader
            subheader={
              <div>
                <span className="h5">
                  Exercice au {dayjs(`${year}-${month}-01`).endOf("month").date()} {monthLabel(month)} {year}
                </span>
                <br />
                <Box className="d-flex gap-2 align-items-center">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${entreprise.logo}`}
                    style={{ width: "50px", height: "50px", objectFit: "cover", aspectRatio: 1 }}
                    alt="Logo Entreprise"
                  />
                  <Box>
                    <span>{entreprise ? entreprise.raison_sociale : "..."}</span>&nbsp;
                    <span>(Devise: {entreprise ? JSON.parse(entreprise.monnaie).currency + ", " + JSON.parse(entreprise.monnaie).code : "..."})</span>
                  </Box>
                </Box>
              </div>
            }
            action={
              <div className="d-flex align-items-center">
                <Link
                  to={`/dashboard/enregistrement/compte/${bilanMonth.id}`}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    textDecoration: "none",
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    padding: "0.5em 1em",
                    borderRadius: "4px",
                    fontSize: "16px",
                    fontWeight: "500",
                    transition: "background-color 0.3s",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#1565c0")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#1976d2")}
                >
                  {/* Ajouter un enregistrement */}
                  <IconButton aria-label="share" style={{ color: "#fff", marginLeft: "0.0em" }}>
                    <svg width="20" fill="#fff" viewBox="0 0 16 16" id="register-16px" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          id="Path_184"
                          data-name="Path 184"
                          d="M57.5,41a.5.5,0,0,0-.5.5V43H47V31h2v.5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V31h2v.5a.5.5,0,0,0,1,0v-1a.5.5,0,0,0-.5-.5H55v-.5A1.5,1.5,0,0,0,53.5,28h-3A1.5,1.5,0,0,0,49,29.5V30H46.5a.5.5,0,0,0-.5.5v13a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,57.5,41ZM50,29.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5V31H50Zm11.854,4.646-2-2a.5.5,0,0,0-.708,0l-6,6A.5.5,0,0,0,53,38.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.354-.146l6-6A.5.5,0,0,0,61.854,34.146ZM54,40V38.707l5.5-5.5L60.793,34.5l-5.5,5.5Zm-2,.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,52,40.5Zm0-3a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,52,37.5ZM54.5,35h-5a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1Z"
                          transform="translate(-46 -28)"
                        ></path>
                      </g>
                    </svg>
                  </IconButton>
                </Link>

                {etat == 1 && (
                  <>
                    <Button
                      onClick={handleGeneratePDF}
                      style={{
                        marginLeft: "1em",
                        backgroundColor: "#f44336",
                        color: "#fff",
                        padding: "0.5em 1em",
                        borderRadius: "4px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#000000")}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#f44336")}
                    >
                      <Icon path={mdiFilePdfBox} size={1} />
                    </Button>

                    <Button
                      onClick={handleGenerateExcel}
                      style={{
                        marginLeft: "1em",
                        backgroundColor: "#388E3C",
                        color: "#fff",
                        padding: "0.5em 1em",
                        borderRadius: "4px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#000000")}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#388E3C")}
                    >
                      <Icon path={mdiMicrosoftExcel} size={1} />
                    </Button>
                  </>
                )}

                <Tooltip title="Retourner à la selection">
                  <Link to={`/dashboard/details-enregistrement/compte/${mat}`}>
                    <IconButton aria-label="settings">
                      <ReplyIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </div>
            }
            sx={{ height: "15%" }}
          />
          <div className="table-wrapper">
            <table>
              <thead>
                <tr className="sticky">
                  <th className="sticky" style={{ backgroundColor: "#4ab8b3" }}>
                    REF
                  </th>
                  <th className="sticky" style={{ backgroundColor: "#4ab8b3" }}>
                    Libellé
                  </th>
                  {days &&
                    days.map((day) => (
                      <th key={day.id}>
                        {day.label}
                        <Link to={`/dashboard/modification/compte/${bilanMonth.id}/${day.date}`} style={{ textDecoration: "none" }}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            style={{ backgroundColor: "#fff", borderRadius: "50%", padding: "4px", marginRight: "1px", zIndex: "-999" }}
                          >
                            <EditIcon fontSize="small" style={{ color: "#1976d2" }} />
                          </IconButton>
                        </Link>
                        <Link onClick={() => handleDeleteEnreg(bilanMonth.id, day.date)} style={{ textDecoration: "none" }}>
                          <IconButton aria-label="delete" size="small" style={{ backgroundColor: "#fff", borderRadius: "50%", padding: "4px", zIndex: "-999" }}>
                            <DeleteIcon fontSize="small" style={{ color: "#f44336" }} />
                          </IconButton>
                        </Link>
                      </th>
                    ))}
                  <th>
                    Exercice au {dayjs(`${year}-${month}-01`).endOf("month").date()}/{month}/{year}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Vos données du tableau */}
                {datas.map((data, index) => (
                  <tr key={index}>
                    <td class="sticky">{data.ref}</td>
                    <td class="sticky">{data.libelle}</td>
                    {days &&
                      days.map((day) => (
                        <td key={day.id} className="text-center">
                          {formatNumber(data.valeurs_jours[day.id] || 0)}
                        </td>
                      ))}
                    <td className="text-center">{formatNumber(data.valeur_totale)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Box>
      ) : (
        <Box sx={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
          <div ref={loadingref} style={{ height: 85, width: 85 }} />
        </Box>
      )}
    </>
  );
};

export default DetailsMonth;
