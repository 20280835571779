import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap'

const PermissionsManagment = ({ role, permissions }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const spermissions = role.permissions

  

    return (
        <>
            <button type="button" onClick={handleShow} class="btn btn-outline-info">Voir les permissions</button>
            <Modal show={show} onHide={handleClose} centered backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title>Liste des permissions du role </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {permissions && (
                        permissions.map((permission) => {
                            return (

                                <div className="form-check form-check-inline">
                                    <input class="form-check-input"
                                        name='permissions[]'
                                        type="checkbox"
                                        id={`permission${permission.id}`}
                                        value={permission.name}
                                        checked={spermissions.some((rolePermission) => rolePermission.id === permission.id)}
                                        disabled
                                    />
                                    <label className="form-check-label" for={`permission${permission.id}`}> {permission.name} </label>
                                </div>


                            )
                        })

                    )
                    }

                </Modal.Body>
            </Modal>
        </>
    );
}

export default PermissionsManagment;
