import React from 'react'
import Index from '../views/dashboard/index'
// import { Switch, Route } from 'react-router-dom'
// user
import UserProfile from '../views/dashboard/app/user-profile';
import UserAdd from '../views/dashboard/app/user-add';
import UserList from '../views/dashboard/app/user-list';
// import userProfileEdit from '../views/dashboard/app/user-privacy-setting';
// widget
import Widgetbasic from '../views/dashboard/widget/widgetbasic';
import Widgetcard from '../views/dashboard/widget/widgetcard';
import Widgetchart from '../views/dashboard/widget/widgetchart';
// icon
import Solid from '../views/dashboard/icons/solid';
import Outline from '../views/dashboard/icons/outline';
import DualTone from '../views/dashboard/icons/dual-tone';
// Form
import FormElement from '../views/dashboard/from/form-element';
import FormValidation from '../views/dashboard/from/form-validation';
import FormWizard from '../views/dashboard/from/form-wizard';
// table
import BootstrapTable from '../views/dashboard/table/bootstrap-table';
import TableData from '../views/dashboard/table/table-data';

// map
import Vector from '../views/dashboard/maps/vector';
import Google from '../views/dashboard/maps/google';

//extra
// import PrivacyPolicy from '../views/dashboard/extra/privacy-policy';
// import TermsofService from '../views/dashboard/extra/terms-of-service';

//TransitionGroup
// import { TransitionGroup, CSSTransition } from "react-transition-group";
//Special Pages
import Billing from '../views/dashboard/special-pages/billing';
import Kanban from '../views/dashboard/special-pages/kanban';
import Pricing from '../views/dashboard/special-pages/pricing';
import Timeline from '../views/dashboard/special-pages/timeline';
import Calender from '../views/dashboard/special-pages/calender';
import RtlSupport from '../views/dashboard/special-pages/RtlSupport'

//admin
import Default from '../layouts/dashboard/default';
import Roles from '../views/dashboard/roles';
import Permissions from '../views/dashboard/permissions';
import Update from '../views/dashboard/ace/edit';
import Objectifs from '../views/dashboard/objectifs';
import ModifObjectif from '../views/dashboard/objectifs/edit';
import ModifEntreprise from '../views/dashboard/entreprises/edit';
import Entreprise from '../views/dashboard/entreprises';
import ShowEntreprise from '../views/dashboard/entreprises/show';
import Certification from '../views/dashboard/certification';
import ModifCertification from '../views/dashboard/certification/edit';
import ShowCertification from '../views/dashboard/certification/show';
import IndexChief from '../views/dashboard/chefs_entreprises';
import EditChief from '../views/dashboard/chefs_entreprises/edit';
import ShowChief from '../views/dashboard/chefs_entreprises/show';
import CompteResultat from '../views/dashboard/compte-resultat';
import Enregistrement from '../views/dashboard/enregistrement';
import EditEnregistrement from '../views/dashboard/enregistrement/edit';
import CompteEdit from '../views/dashboard/compte-resultat/edit';
import Aces from '../views/dashboard/ace';
import ModifAce from '../views/dashboard/ace/edit';
import AceShow from '../views/dashboard/ace/show';
import Agent from '../views/dashboard/agent';
import ModifAgent from '../views/dashboard/agent/edit';
import AgentShow from '../views/dashboard/agent/show';
import Others from '../views/dashboard/other';
import ModifOther from '../views/dashboard/other/edit';
import OtherShow from '../views/dashboard/other/show';
import Admins from '../views/dashboard/admin';
import ModifAdmin from '../views/dashboard/admin/edit';
import AdminShow from '../views/dashboard/admin/show';
import IndexCompte from '../views/dashboard/compte-resultat/index_compte';
import Profile from '../views/dashboard/profile';
import Cotisation from '../views/dashboard/entreprises/cotisation';


export const DefaultRouter = [
    {
        path: '/',
        element: <Default />,
        children: [
            {
                path: 'dashboard',
                element: <Index />
            },
            {
                path: 'dashboard/profil/:id',
                element: <Profile />
            },
            {
                path: 'dashboard/special-pages/billing',
                element: <Billing />
            },
            {
                path: 'dashboard/special-pages/calender',
                element: <Calender />
            },
            {
                path: 'dashboard/special-pages/kanban',
                element: <Kanban />
            },
            {
                path: 'dashboard/special-pages/pricing',
                element: <Pricing />
            },
            {
                path: 'dashboard/special-pages/timeline',
                element: <Timeline />
            },
            {
                path: 'dashboard/special-pages/rtl-support',
                element: <RtlSupport />,
            },
            {
                path: 'dashboard/app/user-profile',
                element: <UserProfile />
            },
            {
                path: 'dashboard/app/user-add',
                element: <UserAdd />
            },
            {
                path: 'dashboard/app/user-list',
                element: <UserList />
            },
            
            // Widget
            {
                path: 'dashboard/widget/widgetbasic',
                element: <Widgetbasic />
            },
            {
                path: 'dashboard/widget/widgetchart',
                element: <Widgetchart />
            },
            {
                path: 'dashboard/widget/widgetcard',
                element: <Widgetcard />
            },
            // Map
            {
                path: 'dashboard/map/google',
                element: <Google />
            },
            {
                path: 'dashboard/map/vector',
                element: <Vector />
            },
            // Form
            {
                path: 'dashboard/form/form-element',
                element: <FormElement />
            },
            {
                path: 'dashboard/form/form-wizard',
                element: <FormWizard />
            },
            {
                path: 'dashboard/form/form-validation',
                element: <FormValidation />
            },
            // Table
            {
                path: 'dashboard/table/bootstrap-table',
                element: <BootstrapTable />
            },
            {
                path: 'dashboard/table/table-data',
                element: <TableData />
            },
            // Icon
            {
                path: 'dashboard/icon/solid',
                element: <Solid />
            },
            {
                path: 'dashboard/icon/outline',
                element: <Outline />
            },
            {
                path: 'dashboard/icon/dual-tone',
                element: <DualTone />
            },
            //aces
            {
                path: 'dashboard/aces',
                element: <Aces />
            },
            {
                path: 'dashboard/ace/edit/:id',
                element: <ModifAce />
            },
            {
                path: 'dashboard/ace/show/:id',
                element: <AceShow />
            },
            //agent
            {
                path: 'dashboard/agents',
                element: <Agent />
            },
            {
                path: 'dashboard/agent/edit/:id',
                element: <ModifAgent />
            },
            {
                path: 'dashboard/agent/show/:id',
                element: <AgentShow />
            },
            //others
            {
                path: 'dashboard/others',
                element: <Others />
            },
            {
                path: 'dashboard/other/edit/:id',
                element: <ModifOther />
            },
            {
                path: 'dashboard/other/show/:id',
                element: <OtherShow />
            },
            //admins
            {
                path: 'dashboard/admins',
                element: <Admins />
            },
            {
                path: 'dashboard/admin/edit/:id',
                element: <ModifAdmin />
            },
            {
                path: 'dashboard/admin/show/:id',
                element: <AdminShow />
            },
            //chefs entreprises
            {
                path: 'dashboard/chefs_entreprises',
                element: <IndexChief />
            },
            {
                path: 'dashboard/chefs_entreprises/edit/:id',
                element: <EditChief />
            },
            {
                path: 'dashboard/chefs_entreprises/show/:id',
                element: <ShowChief />
            },

            //roles
            {
                path: 'dashboard/roles',
                element: <Roles />
            },
            {
                path: 'dashboard/permissions',
                element: <Permissions />
            },
            {
                path: 'dashboard/users/update/:id',
                element: <Update />
            },
            //objectifs
            {
                path: 'dashboard/objectifs',
                element: <Objectifs />
            },
            {
                path: 'dashboard/objectifs/edit/:id',
                element: <ModifObjectif />
            },
            //entreprise
            {
                path: 'dashboard/gestion-entreprise',
                element: <Entreprise />
            },
           
            {
                path: 'dashboard/gestion-entreprise/edit/:id',
                element: <ModifEntreprise />
            },
            {
                path: 'dashboard/gestion-entreprise/show/:id',
                element: <ShowEntreprise />
            },
            {
                path: 'dashboard/gestion-entreprise/cotisation/:id',
                element: <Cotisation />
            },
            ///certifications
            {
                path: 'dashboard/certifications',
                element: <Certification />
            },
            {
                path: 'dashboard/certification/edit/:id',
                element: <ModifCertification />
            },
            {
                path: 'dashboard/certification/show/:id',
                element: <ShowCertification />
            },
            //compte resultat
            {
                path: 'dashboard/entreprises-ace/:id',
                element: <CompteResultat />
            },
            {
                path: 'dashboard/entreprise-comptes/:id',
                element: <IndexCompte />
            },
            {
                path: 'dashboard/compte-resultat/edit/:id',
                element: <CompteEdit />
            },
            
            //enregistrement
            {
                path: 'dashboard/enregistrement/compte/:id',
                element: <Enregistrement />
            },

            //modification
            {
                path: 'dashboard/modification/compte/:id/:date',
                element: <EditEnregistrement />
            },

            

        ]
    }
]

