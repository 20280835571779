import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Lottie from "lottie-web";
import loadingwait from "../components/animations/loading.json";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import ReplyIcon from "@mui/icons-material/Reply";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import * as dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { toast, ToastContainer } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QRCodeCanvas } from "qrcode.react";

const Diplome = () => {
  const pdfRef = useRef();
  const loadingref = useRef();

  const [diplome, setDiplome] = useState(null);
  const [certification, setCertification] = useState(null);
  const [role, setRole] = useState(null);
  const [user, setUser] = useState(null);
  const [reload, setReload] = useState(false);

  const [editDip, setEditDip] = useState(false);
  const [date_fin, setFin] = useState();
  const [date_soutenance, setSoutenance] = useState();
  const [mention, setMention] = useState();
  const [date_debut, setDebut] = useState();
  const [urlWithId, setUrlWithId] = useState();

  dayjs.locale("fr");
  const { id } = useParams();

  const token = Cookies.get("authToken");

  const handleDownload = async (name) => {
    const element = pdfRef.current;

    // Capture du DOM
    const canvas = await html2canvas(element, {
      scale: 2, // Améliore la qualité
      useCORS: true, // Supporte les images externes
      scrollX: -window.scrollX, // Capture correctement sur les écrans responsives
      scrollY: -window.scrollY,
    });

    const imgData = canvas.toDataURL("image/png");

    // Dimensions du contenu capturé
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;

    // Conversion en millimètres
    const pageWidth = contentWidth * 0.264583;
    const pageHeight = contentHeight * 0.264583;

    // Orientation dynamique
    const orientation = pageWidth > pageHeight ? "landscape" : "portrait";

    // Génération du PDF
    const pdf = new jsPDF({
      orientation,
      unit: "mm",
      format: [pageWidth, pageHeight],
    });

    pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight);
    pdf.save(`${name}.pdf`);
  };

  const handleEditDiploma = async () => {
    if (date_fin && date_soutenance && mention) {
      toast
        .promise(exportInfos(), {
          pending: "Application en cours...",
        })
        .then(() => {})
        .catch((error) => {
          console.error(
            "Erreur lors de la modification des informations",
            error
          );
        });
    }
  };

  const exportInfos = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/users/certification/diplome`,
        {
          id: diplome.id,
          date_debut,
          date_fin,
          date_soutenance,
          mention,
          user_id: user.id,
          certification_id: certification.id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      );

      toast.success("Modification realisée avec succès!");
      setEditDip(false);
      setFin();
      setSoutenance();
      setMention();
      setReload(!reload);
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la modification");
    }
  };

  const handleClickEditDip = (data) => {
    setFin(data.date_fin ?? null);
    setSoutenance(data.date_soutenance ?? null);
    setMention(data.mention ?? null);
    setEditDip(true);
  };

  const handleCloseDip = () => {
    setEditDip(false);
    setFin();
    setSoutenance();
    setMention();
  };

  //chargement des données du diplome
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}admin/diplome/preview/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDiplome(response.data.diplome);
        setCertification(response.data.certification);
        setRole(response.data.role);
        setUser(response.data.user);
        setMention(response.data.diplome.mention);
        setFin(response.data.diplome.date_fin);
        setDebut(response.data.diplome.date_debut);
        setSoutenance(response.data.diplome.date_soutenance);
        setUrlWithId(
          process.env.REACT_APP_VERIFY_URL +
            `verify/diplome/${response.data.diplome.code}`
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reload]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  const formatDate = (inputDate) => {
    // Vérifie si `inputDate` est déjà un objet Date
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

    const day = String(date.getDate()).padStart(2, "0"); // Ajoute un 0 si nécessaire
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Mois commence à 0
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return diplome && certification && role && user ? (
    <Box sx={{ padding: "2em", height:"100vh",overflow:"auto"}}>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: 10,
          marginBottom: "1.5em",
          padding: "1em",
        }}
      >
        <IconButton
          onClick={() => handleDownload(diplome.code)}
          variant="outlined"
        >
          <PrintIcon />
        </IconButton>
        <IconButton onClick={goBack} variant="outlined">
          <ReplyIcon />
        </IconButton>
        <IconButton
          onClick={() => handleClickEditDip(diplome)}
          variant="outlined"
        >
          <BorderColorIcon />
        </IconButton>
      </div>

      <Box
        ref={pdfRef}
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.2)",
          backgroundImage:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0.05), transparent)",
          width: "1350px",
          margin: "0 auto",
        }}
      >
        <Box sx={{ p: 5, bgcolor: "white" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "2.5em",
              padding: "1em",
            }}
          >
            <div>
              <div
                style={{ display: "flex", gap: "2em", alignItems: "center" }}
              >
                <img
                  style={{
                    height: "300px",
                    width: "300px",
                    objectFit: "cover",
                  }}
                  src="/diplome/logo-ayechin.png"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.5em",
                      color: "rgb(6 124 140)",
                      fontWeight: "bolder",
                      textAlign: "center",
                    }}
                  >
                    CYVAN <br /> CERTIFICATION
                  </span>
                  <img
                    style={{
                      height: "150px",
                      width: "150px",
                      objectFit: "cover",
                    }}
                    src="/diplome/verif.png"
                  />
                </div>
              </div>
              <div
                style={{
                  boxShadow:
                    "12px 12px 8px 1px rgba(0, 0, 0, 0.205), inset 0 4px 6px rgba(88, 70, 2, 0.5)",
                  fontSize: "2em",
                  width: "calc(300px + 4em + 100px)",
                  textTransform: "uppercase",
                  textAlign: "center",
                  backgroundColor: "#bd8f00",
                  color: "white",
                  padding: "1.3em",
                }}
              >
                {certification.programme}
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <div
                style={{
                  fontSize: "1.5em",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "black",
                  marginBottom: 5,
                }}
              >
                {user.lastname + " " + user.firstname}
              </div>
              <p style={{ fontSize: "1.3em", color: "black" }}>
                Est certifié-e en{" "}
                <span style={{ fontWeight: "bold" }}>Matrice AYECHIN</span> pour
                avoir suivi-e avec succès ce programme de formation dans les
                conditions suivantes :
              </p>
              <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", color: "black", fontSize: 18 }}>
                    Nombre heures:
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "end",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {certification.number} Heures
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", color: "black", fontSize: 18 }}>
                    Date de début:
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "end",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {diplome.date_debut ? formatDate(diplome.date_debut) : "Néant"}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", color: "black", fontSize: 18 }}>
                    Date de fin:
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "end",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {diplome.date_fin ? formatDate(diplome.date_fin) : "Néant"}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", color: "black", fontSize: 18 }}>
                    Date de soutenance:
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "end",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {diplome.date_soutenance
                      ? formatDate(diplome.date_soutenance)
                      : "Néant"}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", color: "black", fontSize: 18 }}>
                    Mention:
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "end",
                      color: "black",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {diplome.mention?? "Non attribué"}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", color: "black", fontSize: 18 }}>
                    Qualité:
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "end",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {role.title}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", color: "black", fontSize: 18 }}>
                    Niveau:{" "}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "end",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {certification.niveau.match(/^(.+?) \(/)[1] ?? ""}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", color: "black", fontSize: 18 }}>
                    Domaine:{" "}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "end",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {certification.domaine}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1.5em",
                }}
              >
                <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                  <img
                    src="/diplome/signature-large.png"
                    style={{ height: 125, width: "auto" }}
                  />
                </div>
                <div style={{ padding: 8, border: "1px solid red" }}>
                  <QRCodeCanvas value={urlWithId} size={90} />
                </div>
              </div>
            </div>
          </div>
        </Box>

        <Box sx={{ bgcolor: "red" }}>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography sx={{ color: "black", fontWeight: "bold" }}>
                N°:
              </Typography>
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                {diplome.code}
              </Typography>
            </Box>
            <a
              style={{ color: "black" }}
              target="_blank"
              href="https://acis.ayechin.org/"
            >
              https://acis.ayechin.org/{" "}
            </a>
            <a
              style={{ color: "black" }}
              target="_blank"
              href="http://www.oapi.int/Ressources/memoire/19124.pdf"
            >
              http://www.oapi.int/Ressources/memoire/19124.pdf
            </a>
          </Box>

          <Box sx={{ color: "white", textAlign: "center" }}>
            L’authenticité du présent document peut être vérifiée en scannant le
            QR-Code
          </Box>
        </Box>
      </Box>

      {/* modal de generation de diplome */}
      <form method="post">
        <Dialog onClose={handleCloseDip} open={editDip}>
          <DialogTitle>Diplome</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    tabIndex={-1}
                    select
                    fullWidth
                    value={mention}
                    onChange={(e) => {
                      setMention(e.target.value);
                    }}
                    label="Mention"
                  >
                    <MenuItem value="">Non attribué</MenuItem>

                    <MenuItem value="Passable">Passable</MenuItem>
                    <MenuItem value="Assez bien">Assez bien</MenuItem>

                    <MenuItem value="Bien">Bien</MenuItem>
                    <MenuItem value="Très bien">Très bien</MenuItem>

                    <MenuItem value="Félicitation du jury">
                      Félicitation du jury
                    </MenuItem>

                    <MenuItem value="Mention spéciale">
                      Mention spéciale
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                >
                  <FormControl required fullWidth>
                    <DatePicker
                      value={date_debut ? dayjs(date_debut) : null} // Ensure value is a valid dayjs object or null
                      onChange={
                        (date) =>
                          setDebut(date ? dayjs(date).format("YYYY-MM-DD") : "") // Handle empty date
                      }
                      label="Date de debut de formation"
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                >
                  <FormControl required fullWidth>
                    <DatePicker
                      value={date_fin ? dayjs(date_fin) : null} // Ensure value is a valid dayjs object or null
                      onChange={
                        (date) =>
                          setFin(date ? dayjs(date).format("YYYY-MM-DD") : "") // Handle empty date
                      }
                      label="Date de fin de formation"
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                >
                  <FormControl required fullWidth>
                    <DatePicker
                      value={dayjs(date_soutenance)}
                      onChange={(date) =>
                        setSoutenance(dayjs(date).add(1, "day"))
                      }
                      label="Date de soutenance"
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDiploma} type="submit">
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </form>

      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        pauseOnHover={false}
      />
    </Box>
  ) : (
    <div
      className=" d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <div
        style={{ maxHeight: "100px", maxWidth: "100px" }}
        ref={loadingref}
      ></div>
    </div>
  );
};

export default Diplome;
