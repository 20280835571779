import React, { useState, useEffect, useRef, Fragment } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-web";
import loadingwait from "./components/animations/loading.json";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Chip,
  ImageList,
  useTheme,
  ImageListItem,
  ImageListItemBar,
  MobileStepper,
  Stack,
  Typography,
  CardHeader,
  CardContent,
  LinearProgress,
  FormControl,
  TextField,
  MenuItem,
  Autocomplete,
  Paper,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  CircularProgress,
  Badge,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import TransgenderIcon from "@mui/icons-material/Transgender";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";
import MailIcon from "@mui/icons-material/MailOutlined";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import FlagCircleIcon from "@mui/icons-material/FlagCircleOutlined";
import CakeIcon from "@mui/icons-material/CakeOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import { ToastContainer, toast } from "react-toastify";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfoIcon from "@mui/icons-material/Info";
import { AnimatePresence, motion } from "framer-motion";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import EventIcon from "@mui/icons-material/Event";
import SchoolIcon from "@mui/icons-material/School";
import NotationIcon from "@mui/icons-material/EditNote";
import BlindsIcon from "@mui/icons-material/Blinds";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import SettingsIcon from "@mui/icons-material/Settings";
import ReportIcon from "@mui/icons-material/Report";
import CloseIcon from "@mui/icons-material/Close";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

import { countries, diplomes, nationalites, naturesPiece, niveauxEtude } from "./components/liste";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import Dropzone from "react-dropzone";

const Profile = () => {
  const { id } = useParams();
  dayjs.locale("fr");
  const [user, setUser] = useState();
  const [preview, setPreview] = useState();
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPerso, setLoadingPerso] = useState(false);
  const [loadingLocate, setLoadingLocate] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  const [loadingOther, setLoadingOther] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const token = Cookies.get("authToken");
  const [imageClicked, setImageClicked] = useState(false);

  const reffirstname = useRef();
  const reflastname = useRef();
  const refemail = useRef();
  const refphone = useRef();
  const refpays = useRef();
  const refville = useRef();
  const refcommune = useRef();
  const refrue = useRef();
  const refnationalite = useRef();
  const refbest_study = useRef();
  const refbest_diplome = useRef();
  const refniveauFormation = useRef();
  const refgenre = useRef();
  const refbirthday = useRef();
  const refnumPiece = useRef();
  const refnaturePiece = useRef();
  const refcurrent = useRef();
  const [reloadData, setReloadData] = useState();

  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [indicator, setIndicator] = useState();
  const [phone, setPhone] = useState();
  const [pays, setPays] = useState();
  const [ville, setVille] = useState();
  const [commune, setCommune] = useState();
  const [rue, setRue] = useState();
  const [nationalite, setNationalite] = useState();
  const [best_study, setBestStudy] = useState();
  const [best_diplome, setBestDiplome] = useState();
  const [birthday, setBirthday] = useState();
  const [genre, setGenre] = useState();
  const [numPiece, setNumPiece] = useState();
  const [naturePiece, setNaturePiece] = useState();
  const [piece_recto, setPieceRecto] = useState();
  const [logoPreviewRecto, setLogoPreviewRecto] = useState();
  const [piece_verso, setPieceVerso] = useState();
  const [logoPreviewVerso, setLogoPreviewVerso] = useState();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [profil, setProfil] = useState();
  const [profilPreview, setProfilPreview] = useState();

  const handleClick = () => {
    setImageClicked(!imageClicked);
  };
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/users/get-profil/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data.user);
        setPays(response.data.user.pays && JSON.parse(response.data.user.pays));
        setIndicator(response.data.user.phone.split(" ")[0]);
        setPhone(response.data.user.phone.split(" ")[1]);
        setFirstname(response.data.user.firstname);
        setLastname(response.data.user.lastname);
        setEmail(response.data.user.email);
        setVille(response.data.user.ville);
        setCommune(response.data.user.commune);
        setRue(response.data.user.rue);
        setNumPiece(response.data.user.num_piece);
        setNaturePiece(response.data.user.nature_piece);
        setNationalite(response.data.user.nationalite);
        setBestStudy(response.data.user.best_study);
        setBestDiplome(response.data.user.best_diplome);
        setBirthday(response.data.user.birthday && dayjs(response.data.user.birthday));
        setProfil(response.data.user.image);
        setPieceRecto(response.data.user.piece_recto);
        setPieceVerso(response.data.user.piece_verso);
      } catch (error) {}
    };
    fetchData();
  }, [reloadData]);
  dayjs.locale("fr");
  const loadingref = useRef();
  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);
  const verifieAge = (birthday) => {
    if (birthday) {
      const dateNaissance = new Date(birthday);
      const dateActuelle = new Date();
      let age = dateActuelle.getFullYear() - dateNaissance.getFullYear();

      if (
        dateActuelle.getMonth() < dateNaissance.getMonth() ||
        (dateActuelle.getMonth() === dateNaissance.getMonth() && dateActuelle.getDate() < dateNaissance.getDate())
      ) {
        age--;
      }

      return age;
    }
  };
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };

  const changeUrl = (val) => {
    setPreview(val);
  };

  const previewImage = (url) => {
    return url ? (
      <motion.img
        key={url}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        srcSet={`${process.env.REACT_APP_BASE_URL}${url}`}
        src={`${process.env.REACT_APP_BASE_URL}${url}`}
        alt={user.firstname}
        loading="lazy"
        style={{ height: "auto", width: "60%" }}
      />
    ) : (
      ""
    );
  };

  const [editPerso, setEditPerso] = useState(false);
  const [editLocate, setEditLocate] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [editOther, setEditOther] = useState(false);

  const focusOnError = (errors, refs) => {
    for (const field in errors) {
      if (errors[field] && refs[field] && refs[field].current) {
        refs[field].current.focus({ behavior: "smooth" });
        break;
      }
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const action = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const handleUpdatePerso = async (id) => {
    setLoadingPerso(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/users/update-personnal/${id}`,
        {
          firstname,
          lastname,
          genre,
          birthday,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setEditPerso(false);
        setErrors();
        setSuccess(response.data.message);
        setOpenSnack(true);
      } else {
        setSuccess();
        focusOnError(response.data.errors, {
          firstname: reffirstname,
          lastname: reflastname,
          genre: refgenre,
          birthday: refbirthday,
        });
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPerso(false);
    }
  };

  const handleUpdateLocate = async (id) => {
    setLoadingLocate(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/users/update-locate/${id}`,
        {
          pays,
          ville,
          commune,
          rue,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setEditLocate(false);
        setErrors();
        setSuccess(response.data.message);
        setOpenSnack(true);
      } else {
        setSuccess();
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingLocate(false);
    }
  };

  const handleUpdateContact = async (id) => {
    setLoadingContact(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/users/update-contact/${id}`,
        {
          email,
          phone: `${indicator ? `${indicator}` : ""} ${phone}`,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setEditContact(false);
        setErrors();
        setSuccess(response.data.message);
        setOpenSnack(true);
      } else {
        setSuccess();
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingContact(false);
    }
  };

  const handleUpdateOther = async (id) => {
    setLoadingOther(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/users/update-other/${id}`,
        {
          piece_recto,
          logoPreviewRecto,
          piece_verso,
          logoPreviewVerso,
          numPiece,
          naturePiece,
          nationalite,
          best_study,
          best_diplome,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setEditOther(false);
        setErrors();
        setSuccess(response.data.message);
        setOpenSnack(true);
      } else {
        setSuccess();
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOther(false);
    }
  };

  const handleUpdateProfil = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/users/update-photo-profil/${id}`,
        {
          profil,
          profilPreview,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setProfilPreview()
        setErrors();
        setSuccess(response.data.message);
        setOpenSnack(true);
      } else {
        setSuccess();
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (newPassword == cpassword) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/users/update-password/${id}`,
          {
            currentPassword,
            newPassword,
            cpassword,
            _method: "PUT",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success == true) {
          setErrors();
          setSuccess(response.data.message);
          setOpen(false);
          setOpenSnack(true);
          setCurrentPassword("");
          setNewPassword("");
          setCpassword("");
        } else {
          setSuccess();
          setErrors(response.data.errors);
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDropProfil = (acceptedFiles) => {
    setProfil(acceptedFiles);
    const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
    setProfilPreview(logoPreviewURL);
  };
  const handleRemoveProfil = () => {
    setProfilPreview();
    setProfil(user.image);
  };

  const handleDropRecto = (acceptedFiles) => {
    setPieceRecto(acceptedFiles);
    const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
    setLogoPreviewRecto(logoPreviewURL);
  };
  const handleRemoveRecto = () => {
    setLogoPreviewRecto();
    setPieceRecto(user.piece_recto);
  };
  const handleDropVerso = (acceptedFiles) => {
    setPieceVerso(acceptedFiles);
    const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
    setLogoPreviewVerso(logoPreviewURL);
  };

  const handleRemoveVerso = () => {
    setLogoPreviewVerso();
    setPieceVerso(user.piece_verso);
  };
  return (
    <>
      {(success || errors) && (
        <Snackbar
          open={openSnack}
          autoHideDuration={success ? 5000 : null}
          onClose={handleCloseSnack}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleCloseSnack} severity={success ? "success" : "error"} sx={{ width: "100%" }}>
            {success ? success : errors}
          </Alert>
        </Snackbar>
      )}
      {user ? (
        <Box>
          <Paper>
            <Box
              sx={{
                position: "relative",
                height: "15rem",
                backgroundImage: "url('/images/bg_user_profil.jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Stack spacing={2} sx={{ position: "absolute", right: 10, top: 10 }}>
                <Chip
                  sx={{ color: "white", cursor: "pointer" }}
                  onClick={handleClickOpen}
                  icon={<SettingsIcon color="white" />}
                  label="Changer mon mot de passe"
                  variant="outlined"
                />
                <Chip sx={{ color: "white", cursor: "pointer" }} icon={<ReportIcon color="white" />} label="Signaler un problème" variant="outlined" />

                {/* modal mot de passe */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    component: "form",
                  }}
                  onSubmit={(e) => handleUpdatePassword(e)}
                >
                  <DialogTitle>Changement de mot de passe</DialogTitle>
                  <DialogContent>
                    <TextField
                      error={errors && errors.current}
                      helperText={errors && errors.current}
                      autoFocus
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      value={currentPassword}
                      required
                      margin="dense"
                      id="name"
                      name="email"
                      label="Mot de passe actuel"
                      type="text"
                      fullWidth
                      variant="standard"
                    />
                    <TextField
                      className="my-4"
                      autoFocus
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                      required
                      margin="dense"
                      id="name"
                      name="email"
                      label="Nouveau mot de passe"
                      type="password"
                      fullWidth
                      variant="standard"
                    />
                    <TextField
                      error={cpassword.length > 6 && cpassword && newPassword !== cpassword}
                      helperText={
                        cpassword.length > 6 && cpassword && newPassword !== cpassword && "Les mots de passe actuel et de confirmation ne correspondent pas"
                      }
                      autoFocus
                      onChange={(e) => setCpassword(e.target.value)}
                      value={cpassword}
                      required
                      margin="dense"
                      id="name"
                      name="email"
                      label="Confirmer le mot de passe"
                      type="password"
                      fullWidth
                      variant="standard"
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <LoadingButton type="submit" loading={loading} onSubmit={(e) => handleUpdatePassword(e)} variant="outlined">
                      Valider
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              </Stack>
              <Box sx={{ position: "absolute", left: "50%", top: "100%", transform: "translate(-50%, -50%)" }}>
                <Box sx={{ position: "relative" }}>
                  <Dropzone onDrop={handleDropProfil}>
                    {({ getRootProps, getInputProps }) => (
                      <section className="mb-2">
                        <div {...getRootProps()}>
                          {!profilPreview && <input {...getInputProps()} />}
                          <Box>
                            <Badge
                              role="button"
                              overlap="circular"
                              anchorOrigin={{ vertical: "top", horizontal: "right" }}
                              sx={{
                                borderRadius: "50%",
                              }}
                              className="d-flex justify-content-center align-items-center"
                              badgeContent={
                                <>
                                  {!profilPreview ? (
                                    <IconButton
                                      aria-label="share"
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                        "&:hover": {
                                          backgroundColor: "white",
                                        },
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      aria-label="share"
                                      onClick={()=>handleUpdateProfil(id)}
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                        "&:hover": {
                                          backgroundColor: "white",
                                        },
                                      }}
                                    >
                                      <SaveIcon />
                                    </IconButton>
                                  )}
                                </>
                              }
                            >
                              <Avatar
                                src={profilPreview ? profilPreview : `${process.env.REACT_APP_BASE_URL}${profil}`}
                                sx={{ backgroundColor: "#faf5ee", height: "8rem", width: "8rem" }}
                                alt={`${user.firstname}`}
                                role="button"
                                className="shadow-lg border border-white"
                              />
                            </Badge>
                          </Box>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Grid container spacing={2} sx={{ marginTop: "4.2rem" }}>
            <Grid item xs={12} md={4}>
              <Paper>
                <Box sx={{ padding: ".5rem 1.5rem", borderBottom: "1px solid #ccd4f9" }} className="d-flex justify-content-between align-items-center">
                  <Typography>Informations personnelles</Typography>
                  <Box sx={{ cursor: "pointer" }} onClick={editPerso ? () => handleUpdatePerso(id) : () => setEditPerso(true)}>
                    {loadingPerso ? (
                      <CircularProgress size={34} />
                    ) : (
                      <IconButton sx={{ cursor: "pointer" }}>{editPerso ? <SaveIcon size={35} /> : <EditIcon size={35} />}</IconButton>
                    )}
                  </Box>
                </Box>
                <Box sx={{ padding: 0.5 }}>
                  <Stack spacing={2} className="p-3">
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={reffirstname}
                          error={errors && errors.firstname}
                          helperText={errors && errors.firstname && errors.firstname.join(", ")}
                          fullWidth
                          value={firstname}
                          label="Nom"
                          onChange={(e) => setFirstname(e.target.value)}
                          disabled={!editPerso}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <TextField
                        tabIndex={-1}
                        ref={reflastname}
                        error={errors && errors.lastname}
                        helperText={errors && errors.lastname && errors.lastname.join(", ")}
                        fullWidth
                        value={lastname}
                        label="Prénoms"
                        onChange={(e) => setLastname(e.target.value)}
                        disabled={!editPerso}
                      />
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refgenre}
                          select
                          error={errors && errors.genre}
                          helperText={errors && errors.genre && errors.genre.join(", ")}
                          fullWidth
                          value={genre}
                          label="Genre"
                          onChange={(e) => setGenre(e.target.value)}
                          disabled={!editPerso}
                        >
                          <MenuItem value="homme">Homme</MenuItem>
                          <MenuItem value="femme">Femme</MenuItem>
                          <MenuItem value="n/a">Non defini</MenuItem>
                        </TextField>
                      </FormControl>
                    </Box>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <FormControl fullWidth>
                          <DatePicker
                            onChange={(date) => setBirthday(date)}
                            label="Date de naissance"
                            value={birthday ? dayjs(birthday) : null}
                            disabled={!editPerso}
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </Box>
                  </Stack>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper>
                <Box sx={{ padding: ".5rem 1.5rem", borderBottom: "1px solid #ccd4f9" }} className="d-flex justify-content-between align-items-center">
                  <Typography>Localisation</Typography>
                  <Box sx={{ cursor: "pointer" }} onClick={editLocate ? () => handleUpdateLocate(id) : () => setEditLocate(true)}>
                    {loadingLocate ? (
                      <CircularProgress size={34} />
                    ) : (
                      <IconButton sx={{ cursor: "pointer" }}>{editLocate ? <SaveIcon size={35} /> : <EditIcon size={35} />}</IconButton>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Stack spacing={2} className="p-3">
                    <Box>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo"
                          value={pays}
                          onChange={(event, newValue) => {
                            setPays(newValue ? newValue : null);
                          }}
                          disabled={!editLocate}
                          fullWidth
                          options={countries}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choisir le pays"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              ref={refpays}
                              error={errors && errors.pays}
                              helperText={errors && errors.pays && errors.pays.join(", ")}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refville}
                          error={errors && errors.ville}
                          helperText={errors && errors.ville && errors.ville.join(", ")}
                          fullWidth
                          value={ville}
                          label="Ville"
                          onChange={(e) => setVille(e.target.value)}
                          disabled={!editLocate}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refcommune}
                          error={errors && errors.commune}
                          helperText={errors && errors.commune && errors.commune.join(", ")}
                          fullWidth
                          value={commune}
                          label="Commune"
                          onChange={(e) => setCommune(e.target.value)}
                          disabled={!editLocate}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refrue}
                          error={errors && errors.rue}
                          helperText={errors && errors.rue && errors.rue.join(", ")}
                          fullWidth
                          value={rue}
                          label="Rue"
                          onChange={(e) => setRue(e.target.value)}
                          disabled={!editLocate}
                        />
                      </FormControl>
                    </Box>
                  </Stack>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper>
                <Box sx={{ padding: ".5rem 1.5rem", borderBottom: "1px solid #ccd4f9" }} className="d-flex justify-content-between align-items-center">
                  <Typography>Contacts</Typography>
                  <Box sx={{ cursor: "pointer" }} onClick={editContact ? () => handleUpdateContact(id) : () => setEditContact(true)}>
                    {loadingContact ? (
                      <CircularProgress size={34} />
                    ) : (
                      <IconButton sx={{ cursor: "pointer" }}>{editContact ? <SaveIcon size={35} /> : <EditIcon size={35} />}</IconButton>
                    )}
                  </Box>
                </Box>
                <Box sx={{ padding: 0.5 }}>
                  <Stack spacing={2} className="p-3">
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refemail}
                          error={errors && errors.email}
                          helperText={errors && errors.email && errors.email.join(", ")}
                          fullWidth
                          value={email}
                          label="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          disabled={!editContact}
                        />
                      </FormControl>
                    </Box>
                    <Box className="d-flex">
                      <FormControl fullWidth sx={{ maxWidth: "80px" }}>
                        <Autocomplete
                          sx={{ "& .MuiAutocomplete-inputRoot": { borderRadius: 0 } }}
                          disableClearable={true}
                          forcePopupIcon={false}
                          id="country-select-demo"
                          value={indicator}
                          onChange={(event, newValue) => {
                            setIndicator(newValue ? `+${newValue.phone}` : null);
                          }}
                          disabled={!editContact}
                          isOptionEqualToValue={(option) => option.phone == indicator}
                          fullWidth
                          options={countries}
                          autoHighlight
                          getOptionLabel={(option) => (option.phone ? `+${option.phone}` : indicator)}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ "& > img": { flexShrink: 0 }, "& > span": { display: "none" } }} {...props}>
                              {`+${option.phone}`}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Indice"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              ref={refpays}
                              error={errors && errors.pays}
                              helperText={errors && errors.pays && errors.pays.join(", ")}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ borderRadius: 0 }}>
                        <TextField
                          sx={{ "& .MuiInputBase-root": { borderRadius: 0 } }}
                          type="number"
                          tabIndex={-1}
                          ref={refphone}
                          error={errors && errors.phone}
                          helperText={errors && errors.phone && errors.phone.join(", ")}
                          fullWidth
                          value={phone}
                          label="Téléphone"
                          onChange={(e) => setPhone(e.target.value)}
                          disabled={!editContact}
                        />
                      </FormControl>
                    </Box>
                  </Stack>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Paper>
                <Box sx={{ padding: ".5rem 1.5rem", borderBottom: "1px solid #ccd4f9" }} className="d-flex justify-content-between align-items-center">
                  <Typography>Autres informations</Typography>
                  <Box sx={{ cursor: "pointer" }} onClick={editOther ? () => handleUpdateOther(id) : () => setEditOther(true)}>
                    {loadingOther ? (
                      <CircularProgress size={34} />
                    ) : (
                      <IconButton sx={{ cursor: "pointer" }}>{editOther ? <SaveIcon size={35} /> : <EditIcon size={35} />}</IconButton>
                    )}
                  </Box>
                </Box>
                <Grid container sx={{ padding: 0.5 }} spacing={1}>
                  <Grid item spacing={2} xs={12} sm={6}>
                    <Stack spacing={2} className="p-3">
                      <Box>
                        <Autocomplete
                          id="country-select-demo2"
                          onChange={(event, newValue) => {
                            setNationalite(newValue && newValue.label);
                          }}
                          disabled={!editOther}
                          value={nationalite}
                          options={nationalites}
                          autoHighlight
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Nationalité"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              ref={refnationalite}
                              error={errors && errors.nationalite}
                              helperText={errors && errors.nationalite && errors.nationalite.join(", ")}
                            />
                          )}
                        />
                      </Box>
                      <Box>
                        <FormControl fullWidth>
                          <TextField
                            tabIndex={-1}
                            ref={refbest_diplome}
                            select
                            error={errors && errors.best_diplome}
                            helperText={errors && errors.best_diplome && errors.best_diplome.join(", ")}
                            fullWidth
                            value={best_diplome}
                            label="Meilleur diplome"
                            onChange={(e) => setBestDiplome(e.target.value)}
                            disabled={!editOther}
                          >
                            <MenuItem value="">...</MenuItem>
                            {diplomes.map((diplome, index) => {
                              return (
                                <MenuItem key={index} value={diplome.value}>
                                  {diplome.label}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth>
                          <TextField
                            tabIndex={-1}
                            ref={refbest_study}
                            select
                            error={errors && errors.best_study}
                            helperText={errors && errors.best_study && errors.best_study.join(", ")}
                            fullWidth
                            value={best_study}
                            label="Meilleur niveau d'étude"
                            onChange={(e) => setBestStudy(e.target.value)}
                            disabled={!editOther}
                          >
                            <MenuItem value="">...</MenuItem>
                            {niveauxEtude.map((study, index) => {
                              return (
                                <MenuItem key={index} value={study.value}>
                                  {study.label}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </FormControl>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item spacing={2} xs={12} sm={6}>
                    <Box className="p-3">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              tabIndex={-1}
                              ref={refnaturePiece}
                              select
                              error={errors && errors.naturePiece}
                              helperText={errors && errors.naturePiece && errors.naturePiece.join(", ")}
                              fullWidth
                              value={naturePiece}
                              label="Nature de la pièce"
                              onChange={(e) => setNaturePiece(e.target.value)}
                              disabled={!editOther}
                            >
                              <MenuItem value="">...</MenuItem>
                              {naturesPiece.map((nature, index) => {
                                return (
                                  <MenuItem key={index} value={nature.value}>
                                    {nature.label}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              tabIndex={-1}
                              ref={refnumPiece}
                              error={errors && errors.num_piece}
                              helperText={errors && errors.num_piece && errors.num_piece.join(", ")}
                              fullWidth
                              value={numPiece}
                              label="Numero de la pièce"
                              onChange={(e) => setNumPiece(e.target.value)}
                              disabled={!editOther}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Box className="mt-2">
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Dropzone
                              disabled={!editOther}
                              accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              onDrop={handleDropRecto}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  {!logoPreviewRecto && <input {...getInputProps()} />}
                                  <Badge
                                    role="button"
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    sx={{
                                      border: "2px solid #f3f7fb",
                                    }}
                                    badgeContent={
                                      <>
                                        {!logoPreviewRecto ? (
                                          <IconButton
                                            aria-label="share"
                                            sx={{
                                              backgroundColor: "white",
                                              borderRadius: "50%",
                                              boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                              "&:hover": {
                                                backgroundColor: "white",
                                              },
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            aria-label="share"
                                            onClick={handleRemoveRecto}
                                            sx={{
                                              backgroundColor: "white",
                                              borderRadius: "50%",
                                              boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                              "&:hover": {
                                                backgroundColor: "white",
                                              },
                                            }}
                                          >
                                            <DeleteSweepIcon />
                                          </IconButton>
                                        )}
                                      </>
                                    }
                                  >
                                    <Box className="w-100">
                                      <img
                                        style={{ height: 300, maxWidth: "100%" }}
                                        src={logoPreviewRecto ? logoPreviewRecto : `${process.env.REACT_APP_BASE_URL}${piece_recto}`}
                                        loading="lazy"
                                      />
                                    </Box>
                                  </Badge>
                                </div>
                              )}
                            </Dropzone>
                          </Grid>

                          <Grid item xs={6}>
                            <Dropzone
                              disabled={!editOther}
                              accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              onDrop={handleDropVerso}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  {!logoPreviewVerso && <input {...getInputProps()} />}
                                  <Badge
                                    role="button"
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    sx={{
                                      border: "2px solid #f3f7fb",
                                    }}
                                    badgeContent={
                                      <>
                                        {!logoPreviewVerso ? (
                                          <IconButton
                                            aria-label="share"
                                            sx={{
                                              backgroundColor: "white",
                                              borderRadius: "50%",
                                              boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                              "&:hover": {
                                                backgroundColor: "white",
                                              },
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            aria-label="share"
                                            onClick={handleRemoveVerso}
                                            sx={{
                                              backgroundColor: "white",
                                              borderRadius: "50%",
                                              boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                              "&:hover": {
                                                backgroundColor: "white",
                                              },
                                            }}
                                          >
                                            <DeleteSweepIcon />
                                          </IconButton>
                                        )}
                                      </>
                                    }
                                  >
                                    <Box className="w-100">
                                      <img
                                        style={{ height: 300, maxWidth: "100%" }}
                                        src={logoPreviewVerso ? logoPreviewVerso : `${process.env.REACT_APP_BASE_URL}${piece_verso}`}
                                        loading="lazy"
                                      />
                                    </Box>
                                  </Badge>
                                </div>
                              )}
                            </Dropzone>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <div className=" d-flex justify-content-center align-items-center" style={{ minHeight: "65vh" }}>
          <div style={{ maxHeight: "100px", maxWidth: "100px" }} ref={loadingref}></div>
        </div>
      )}
      <ToastContainer autoClose={3000} hideProgressBar={true} pauseOnHover={false} />
    </>
  );
};

export default Profile;
