import React, { useRef, useState, useEffect, Fragment, useCallback } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Autocomplete,
  InputAdornment,
  Paper,
  CardHeader,
  Tooltip,
  IconButton,
  Divider,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Avatar,
  Badge,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Menu,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { countries, domaines, juridiques, regimes, secteurs } from "../components/liste";
import Dropzone from "react-dropzone";
import Cookies from "js-cookie";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { NumericFormat } from "react-number-format";
import { motion, AnimatePresence } from "framer-motion";
import ReplyIcon from "@mui/icons-material/Reply";
import { Link, useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import Lottie from "lottie-web";
import { ToastContainer, toast } from "react-toastify";
import loadingwait from "../components/animations/loading.json";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BadgeIcon from "@mui/icons-material/Badge";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
const ModifEntreprise = () => {
  const { id } = useParams();
  var cc = require("currency-codes");
  dayjs.locale("fr");
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [entreprise, setEntreprise] = useState();
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);

  const reffirstname = useRef();
  const reflastname = useRef();
  const refemail = useRef();
  const refphone = useRef();

  //Pour les amdins secondaires
  const [id_user, setIduser] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [indicator, setIndicator] = useState();

  const token = Cookies.get("authToken");
  const isAce = JSON.parse(Cookies.get("authUser")).isAce;
  const isAgent = JSON.parse(Cookies.get("authUser")).isAgent;
  const isAdmin = JSON.parse(Cookies.get("authUser")).isAdmin;
  const isCompany_chief = Cookies.get("company_admin");

  const isPrincipalAdmin = JSON.parse(Cookies.get("authUser")) && JSON.parse(Cookies.get("authUser")).type_admin == "principal";
  JSON.parse(Cookies.get("authUser")).entreprises && JSON.parse(Cookies.get("authUser")).entreprises.some((entreprise) => entreprise.pivot.entreprise_id == id);

  const id_ace = JSON.parse(Cookies.get("authUser")).id;
  const res = useRef();
  const refpa = useRef();
  const refmo = useRef();
  const refno = useRef();
  const reflo = useRef();
  const refty = useRef();
  const refvi = useRef();
  const refco = useRef();
  const refru = useRef();
  const refra = useRef();
  const refac = useRef();
  const refdo = useRef();
  const refsi = useRef();
  const refdac = useRef();
  const refdaa = useRef();
  const refdaf = useRef();
  const refdas = useRef();
  const refcot = useRef();
  const refcotcnps = useRef();
  const refcn = useRef();
  const refdf = useRef();
  const refrc = useRef();
  const refnba = useRef();
  const refnpb = useRef();
  const refvp = useRef();
  const refcap = useRef();
  const refinv = useRef();
  const refcre = useRef();
  const refdet = useRef();
  const refch = useRef();
  const refval = useRef();
  const refep = useRef();
  const refga = useRef();
  const reftit = useRef();
  const refdept = useRef();
  const refres = useRef();
  const reflas = useRef();
  const loadingref = useRef();
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  //sending
  const [pays, setPays] = useState();
  const [aces, setAces] = useState();
  const [agents, setAgents] = useState();
  const [admins, setAdmins] = useState();
  const [ace, setAce] = useState();
  const [agent, setAgent] = useState();
  const [admins_entreprise, setAdminEntreprise] = useState();
  const [acesend, setAceSend] = useState();
  const [agentsend, setAgentSend] = useState();
  const [adminPrincipal, setAdminPrincipal] = useState([]);
  const [monnaie, setMonnaie] = useState();
  const [taux, setTaux] = useState();
  const [tauxChargeSociale, setTauxChargeSociale] = useState();
  const [tauxParticipationTravailler, setTauxParticipationTravailler] = useState();
  const [adminSecondSend, setadminSecondSend] = useState([]);
  const [logo, setLogo] = useState();
  const [logoPreview, setLogoPreview] = useState();
  const [logoPreviewUrl, setLogoPreviewUrl] = useState();
  const [logoPreviewUrlParrain, setLogoPreviewUrlParrain] = useState();
  const [typeEntreprise, setTypeEntreprise] = useState();
  const [secteur, setSecteur] = useState();
  const [ville, setVille] = useState();
  const [commune, setCommune] = useState();
  const [rue, setRue] = useState();
  const [raisonSociale, setRaisonSociale] = useState();
  const [activitePrincipale, setActivitePrincipale] = useState();
  const [domaine, setDomaine] = useState();
  const [forme, setForme] = useState();
  const [regime, setRegime] = useState();
  const [siege, setSiege] = useState();
  const [dateCreation, setDateCreation] = useState();
  const [dateAdhesion, setDateAdhesion] = useState();
  const [dateFormation, setDateFormation] = useState();
  const [dateSelection, setDateSelection] = useState();
  const [cotisation, setCotisation] = useState();
  const [cnps, setCnps] = useState();
  const [cotisationCnps, setCotisationCnps] = useState();
  const [cotisationCmu, setCotisationCmu] = useState();
  const [dfe, setDfe] = useState();
  const [rccm, setRccm] = useState();
  const [nbAssocie, setNbAssocie] = useState(1);
  const [nbPart, setNbpart] = useState();
  const [valeurPart, setValeurPart] = useState();
  const [capital, setCapital] = useState();
  const [investissement, setInvestissement] = useState();
  const [creance, setCreance] = useState();
  const [dette, setDette] = useState();
  const [chiffreAffaire, setChiffreAffaire] = useState();
  const [valeurStock, setValeurStock] = useState();
  const [epargne, setEpargne] = useState();
  const [gage, setGage] = useState();
  const [titrePlacement, setTitrePlacement] = useState();
  const [depotTerme, setDepoTerme] = useState();
  const [reserve, setReserve] = useState();
  const [lastUpdate, setLastUpdate] = useState();
  const [logoPreviewParrain, setLogoPreviewParrain] = useState("");
  const [logoParrain, setLogoParrain] = useState();
  const [parrain, setParrain] = useState();
  //endsending
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/entreprises/edit/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const datas = response.data;
        setEntreprise(datas.entreprise);
        if (response.data) {
          setPays(response.data.entreprise.pays);
          setMonnaie(response.data.entreprise.monnaie);
          // setNote(response.data.entreprise.note)
          setLogoPreview(response.data.entreprise.logo);
          setLogoPreviewParrain(response.data.entreprise.logo_parrain);
          setParrain(response.data.entreprise.parrain);
          setTypeEntreprise(response.data.entreprise.type_entreprise);
          setVille(response.data.entreprise.ville);
          setCommune(response.data.entreprise.com_prov);
          setRue(response.data.entreprise.rue);
          setRaisonSociale(response.data.entreprise.raison_sociale);
          setActivitePrincipale(response.data.entreprise.activite_principale);
          setSiege(response.data.entreprise.siege);
          setDateCreation(dayjs(response.data.entreprise.date_creation));
          setDateAdhesion(dayjs(response.data.entreprise.date_adhesion_organisation));
          setDateFormation(dayjs(response.data.entreprise.date_formation_assistanat));
          setDateSelection(dayjs(response.data.entreprise.date_selection));
          setCotisation(response.data.entreprise.cotisation_mensuelle);
          setCnps(response.data.entreprise.cnps);
          setCotisationCnps(response.data.entreprise.cotisation_cnps);
          setCotisationCmu(response.data.entreprise.cotisation_cmu);
          setDfe(response.data.entreprise.dfe);
          setRccm(response.data.entreprise.rccm);
          setNbAssocie(response.data.entreprise.nb_associe);
          setNbpart(response.data.entreprise.nb_part);
          setValeurPart(response.data.entreprise.valeur_part);
          setCapital(response.data.entreprise.capital);
          setInvestissement(response.data.entreprise.investissement);
          setCreance(response.data.entreprise.creance);
          setDette(response.data.entreprise.dette);
          setChiffreAffaire(response.data.entreprise.chiffre_affaire);
          setValeurStock(response.data.entreprise.valeur_stock);
          setEpargne(response.data.entreprise.epargne);
          setGage(response.data.entreprise.gage);
          setTitrePlacement(response.data.entreprise.titre_placement);
          setDepoTerme(response.data.entreprise.depot_terme);
          setSecteur(response.data.entreprise.secteur_activite);
          setReserve(response.data.entreprise.reserve);
          setAces(response.data.aces);

          setAgents(response.data.agents);
          setAdmins(response.data.admins);
          setAdminEntreprise(response.data.admins_entreprise);
          setAgentSend(response.data.agent);
          setAceSend(response.data.ace);
          setAgent(response.data.agent);
          setAce(response.data.ace);
          setAdminPrincipal(response.data.adminPrincipal);
          setRegime(response.data.entreprise.regime_imposition);
          setForme(response.data.entreprise.forme_juridique);
          setTaux(response.data.taux);
          setTauxChargeSociale(response.data.ChargeSociale);
          setTauxParticipationTravailler(response.data.ParticipationTravailler);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reloadData]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  const focusOnError = (errors, refs) => {
    for (const field in errors) {
      if (errors[field] && refs[field] && refs[field].current) {
        refs[field].current.focus({ behavior: "smooth" });
        break;
      }
    }
  };

  const handleUpdateEntreprise = async (id) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/entreprises/update/${id}`,
        {
          pays,
          parrain,
          logoParrain,
          logo,
          typeEntreprise,
          ville,
          commune,
          rue,
          raisonSociale,
          activitePrincipale,
          siege,
          dateCreation,
          dateAdhesion,
          dateFormation,
          dateSelection,
          cotisation,
          cnps,
          cotisationCnps,
          cotisationCmu,
          dfe,
          rccm,
          nbAssocie,
          nbPart,
          monnaie,
          valeurPart,
          investissement,
          creance,
          dette,
          chiffreAffaire,
          valeurStock,
          epargne,
          gage,
          titrePlacement,
          depotTerme,
          reserve,
          secteur,
          regime,
          forme,
          taux,
          tauxChargeSociale,
          tauxParticipationTravailler,
          // lastUpdate
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setErrors();
        setSuccess(response.data.message);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
      } else {
        setSuccess();
        focusOnError(response.data.errors, {
          pays: refpa,
          monnaie: refmo,
          note: refno,
          logo: reflo,
          typeEntreprise: refty,
          ville: refvi,
          commune: refco,
          rue: refru,
          raisonSociale: refra,
          activitePrincipale: refac,
          domaine: refdo,
          siege: refsi,
          dateCreation: refdac,
          dateAdhesion: refdaa,
          dateFormation: refdaf,
          dateSelection: refdas,
          cotisation: refcot,
          cnps: refcn,
          cotisationCnps: refcotcnps,
          dfe: refdf,
          rccm: refrc,
          nbAssocie: refnba,
          nbPart: refnpb,
          valeurPart: refvp,
          capital: refcap,
          investissement: refinv,
          creance: refcre,
          dette: refdet,
          chiffreAffaire: refch,
          valeurStock: refval,
          epargne: refep,
          gage: refga,
          titrePlacement: reftit,
          depotTerme: refdept,
          reserve: refres,
          lastUpdate: reflas,
        });
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLogo();
    }
  };

  const action = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const handleDrop = (acceptedFiles) => {
    setLogo(acceptedFiles);

    const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
    setLogoPreviewUrl(logoPreviewURL);
  };

  const handleRemove = () => {
    setLogo();
    setLogoPreview(entreprise.logo);
  };

  const handleDropParrain = (acceptedFiles) => {
    setLogoParrain(acceptedFiles);

    const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
    setLogoPreviewUrlParrain(logoPreviewURL);
  };

  const handleRemoveParrain = () => {
    setLogoParrain();
    setLogoPreviewParrain(entreprise.logo);
  };

  const Reinitialiser = () => {
    setLoading(true);
    setReloadData((prev) => !prev);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  const [openAce, setOpenAce] = useState();
  const [openAgent, setOpenAgent] = useState();
  const [openAdmin, setOpenAdmin] = useState();
  const [openAdminPrincipal, setOpenAdminPrincipal] = useState();

  const [openAdminSecondUpdate, setOpenAdminSecondUpdate] = useState();

  const handleClickOpenAce = () => {
    setOpenAce(true);
  };

  const handleCloseAce = () => {
    setOpenAce(false);
  };

  const handleClickOpenAgent = () => {
    setOpenAgent(true);
  };

  const handleCloseAgent = () => {
    setOpenAgent(false);
  };

  const handleClickOpenAdmin = () => {
    setOpenAdmin(true);
  };

  const handleCloseAdmin = () => {
    setOpenAdmin(false);
    setFirstname("");
    setLastname("");
    setEmail("");
    setPhone("");
    setIndicator();
  };

  const handleClickOpenAdminPrincipal = () => {
    setOpenAdminPrincipal(true);
  };
  const handleCloseAdminPrincipal = () => {
    setOpenAdminPrincipal(false);
  };

  const handleClickOpenUpdate = async (admin) => {
    setIduser(admin.id);
    setIndicator(admin.phone.split(" ")[0]);
    setPhone(admin.phone.split(" ")[1]);
    setFirstname(admin.firstname);
    setLastname(admin.lastname);
    setEmail(admin.email);
    setOpenAdminSecondUpdate(true);
  };

  const handleCloseOpenUpdate = () => {
    setIndicator();
    setPhone("");
    setFirstname("");
    setLastname("");
    setEmail("");
    setOpenAdminSecondUpdate(false);
  };

  const updateAce = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/entreprises/store/ace`,
        { ace: acesend && acesend.id, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setErrors();
        setSuccess(response.data.message);
        setOpenAce(false);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
        setAceSend();
      } else {
        setSuccess(null);
        focusOnError(response.data.errors, {
          pays: refpa,
          monnaie: refmo,
        });
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du champ:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateAgent = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/entreprises/store/agent`,
        { agent: agentsend && agentsend.id, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setErrors();
        setSuccess(response.data.message);
        setOpenAgent(false);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
        setAgentSend();
      } else {
        setSuccess(null);
        focusOnError(response.data.errors, {
          pays: refpa,
          monnaie: refmo,
        });
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du champ:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateAdmin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/entreprises/store/admin`,
        { admin: adminPrincipal.id, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setErrors();
        setSuccess(response.data.message);
        setOpenAdminPrincipal(false);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
      } else {
        setSuccess();
        setErrors(response.data.errors);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du champ:", error);
    } finally {
      setLoading(false);
    }
  };

  const addAdminSecondary = async () => {
    if (firstname !== "" && lastname !== "" && email !== "" && phone !== "" && indicator) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/entreprises/store/admin-secondary`,
          {
            id,
            firstname,
            lastname,
            email,
            phone: `${indicator ? `+${indicator}` : ""} ${phone}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.success == true) {
          setReloadData((prev) => !prev);
          setErrors();
          setSuccess(response.data.message);
          setOpenAdmin(false);
          setFirstname("");
          setLastname("");
          setEmail("");
          setPhone("");
          setIndicator();
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setSuccess();
          focusOnError(response.data.errors, {
            email: refemail,
            phone: refphone,
          });
          // setErrors(response.data.errors);
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour du champ:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const updateAdminSecondary = async () => {
    if (firstname !== "" && lastname !== "" && email !== "" && phone !== "" && indicator) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/entreprises/update/admin-secondary`,
          {
            id_user,
            firstname,
            lastname,
            email,
            phone: `${indicator ? `${indicator}` : ""} ${phone}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.success == true) {
          setReloadData((prev) => !prev);
          setErrors();
          setSuccess(response.data.message);
          setOpenAdminSecondUpdate(false);
          setFirstname("");
          setLastname("");
          setEmail("");
          setPhone("");
          setIndicator();
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setSuccess();
          focusOnError(response.data.errors, {
            email: refemail,
            phone: refphone,
          });
          // setErrors(response.data.errors);
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour du champ:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteSecondary = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}admin/entreprises/delete/admin-secondary/${id_user}`,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSecAdmin = () => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cet administrateur ?");

    if (!confirmDelete) {
      return;
    }
    toast
      .promise(deleteSecondary(), {
        pending: "Suppression en cours...",
        success: "Suppression realisée avec succès!",
        error: "Il y'a eu une erreur lors de la suppression",
      })
      .then(() => {
        setReloadData((prev) => !prev);
        setIndicator();
        setPhone("");
        setFirstname("");
        setLastname("");
        setEmail("");
        setOpenAdminSecondUpdate(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression de l'administrateur", error);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDrop = Boolean(anchorEl);
  const handleClickDrop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDrop = () => {
    setAnchorEl(null);
  };

  return (
    <AnimatePresence>
      {(success || errors) && (
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseSnack} action={action} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleCloseSnack} severity={success ? "success" : "error"} sx={{ width: "100%" }}>
            {success ? success : errors}
          </Alert>
        </Snackbar>
      )}
      {entreprise ? (
        <motion.div className="mb-3" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }}>
          <Paper elevation={1} className="p-3">
            <CardHeader
              subheader={
                <div>
                  <span>Modification des informations de l'entreprise</span>
                  <br />
                  <span>{entreprise ? entreprise.raison_sociale : ""}</span>
                </div>
              }
              action={
                <div className="d-flex gap-2">
                  <Tooltip title="Retourner à la liste">
                    <Link to={!isAdmin == 1 ? `/dashboard/entreprises-ace/${id_ace}` : `/dashboard/gestion-entreprise`}>
                      <IconButton aria-label="settings">
                        <ReplyIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  {isAce != 1 && isAgent != 1 && (isPrincipalAdmin == true || isAdmin == 1) && (
                    <>
                      <IconButton
                        onClick={handleClickDrop}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={openDrop ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openDrop ? "true" : undefined}
                      >
                        <Button variant="outlined" startIcon={<MoreHorizIcon />}>
                          Actions
                        </Button>
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={openDrop}
                        onClose={handleCloseDrop}
                        onClick={handleCloseDrop}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      >
                        {(isAdmin == 1 || !isCompany_chief) && (
                          <>
                            <MenuItem onClick={handleClickOpenAce}>
                              <AssignmentIndIcon sx={{ color: "#909299" }} />
                              &nbsp; Ajouter/modifier l'agent ACE
                            </MenuItem>
                            <MenuItem onClick={handleClickOpenAgent}>
                              <BadgeIcon sx={{ color: "#909299" }} />
                              &nbsp; Ajouter/modifier l'agent de notation
                            </MenuItem>
                            <MenuItem onClick={handleClickOpenAdminPrincipal}>
                              <AdminPanelSettingsIcon sx={{ color: "#909299" }} />
                              &nbsp; Ajouter/modifier l'administrateur principal
                            </MenuItem>
                          </>
                        )}
                        {(isPrincipalAdmin || isAdmin == 1) && (
                          <MenuItem sx={{ whiteSpace: "wrap", textOverflow: "ellipsis" }} onClick={handleClickOpenAdmin}>
                            <AdminPanelSettingsIcon sx={{ color: "#909299" }} />
                            &nbsp; Ajouter un administrateur secondaire
                          </MenuItem>
                        )}
                      </Menu>
                    </>
                  )}
                  {/* dialog ace */}
                  <Dialog
                    open={openAce}
                    fullWidth
                    onClose={(event, reason) => {
                      if (reason != "backdropClick") {
                        handleCloseAce();
                      }
                    }}
                  >
                    <DialogTitle sx={{ fontSize: "1em" }}>Attribuez un ACE à l'entreprise</DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth className="mt-3">
                        <Autocomplete
                          id="country-select-demo2"
                          onChange={(event, newValue) => {
                            setAceSend(newValue);
                          }}
                          value={acesend}
                          options={aces ? aces : []}
                          autoHighlight
                          fullWidth
                          getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choisir l'ACE"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              // ref={refmo}
                              // error={errors && errors.monnaie}
                              // helperText={
                              //     errors && errors.monnaie && errors.monnaie.join(', ')
                              // }
                            />
                          )}
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseAce}>annuler</Button>
                      <LoadingButton onClick={() => updateAce(id)} loading={loading} variant="outlined">
                        Valider
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                  {/*fin dialog ace */}

                  {/* dialog agent notation */}
                  <Dialog
                    open={openAgent}
                    fullWidth
                    onClose={(event, reason) => {
                      if (reason != "backdropClick") {
                        handleCloseAgent();
                      }
                    }}
                  >
                    <DialogTitle sx={{ fontSize: "1em" }}>Attribuez un agent de notation à l'entreprise</DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth className="mt-3">
                        <Autocomplete
                          id="country-select-demo2"
                          onChange={(event, newValue) => {
                            setAgentSend(newValue);
                          }}
                          value={agentsend}
                          options={agents ? agents : []}
                          autoHighlight
                          fullWidth
                          getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choisir l'agent de notation"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              // ref={refmo}
                              // error={errors && errors.monnaie}
                              // helperText={
                              //     errors && errors.monnaie && errors.monnaie.join(', ')
                              // }
                            />
                          )}
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseAgent}>annuler</Button>
                      <LoadingButton onClick={() => updateAgent(id)} loading={loading} variant="outlined">
                        Valider
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                  {/*fin dialog agent notation */}

                  {/* dialog admin principal */}
                  <Dialog
                    open={openAdminPrincipal}
                    fullWidth
                    onClose={(event, reason) => {
                      if (reason != "backdropClick") {
                        handleCloseAdminPrincipal();
                      }
                    }}
                  >
                    <DialogTitle sx={{ fontSize: "1em" }}>Désignez l'administrateur principal de l'entreprise</DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth className="mt-3">
                        <Autocomplete
                          id="country-select-demo2"
                          onChange={(event, newValue) => {
                            setAdminPrincipal(newValue);
                          }}
                          value={adminPrincipal}
                          options={admins ? admins : []}
                          autoHighlight
                          fullWidth
                          getOptionLabel={(option) => {
                            const entreprisePivot = option.entreprises && option.entreprises.entreprise_id === id && option.entreprises.type_admin !== "principal";
                            return `${option.firstname} ${option.lastname} ${entreprisePivot ? `(${entreprisePivot.pivot.type_admin})` : ""}`;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choisir l'Administrateur principal"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              // ref={refmo}
                              // error={errors && errors.monnaie}
                              // helperText={
                              //     errors && errors.monnaie && errors.monnaie.join(', ')
                              // }
                            />
                          )}
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseAdminPrincipal}>annuler</Button>
                      <LoadingButton onClick={() => updateAdmin(id)} loading={loading} variant="outlined">
                        Valider
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                  {/*fin dialog admin */}

                  {/* dialog admin secondaire */}
                  <Dialog
                    open={openAdmin}
                    fullWidth
                    onClose={(event, reason) => {
                      if (reason != "backdropClick") {
                        handleCloseAdmin();
                      }
                    }}
                  >
                    <DialogTitle sx={{ fontSize: "1em" }}>Attribuez un administrateur secondaire à l'entreprise</DialogTitle>
                    <DialogContent className="py-2">
                      <FormGroup>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                tabIndex={-1}
                                ref={reffirstname}
                                error={!!(errors && errors.firstname)}
                                helperText={errors?.firstname?.join(", ")}
                                fullWidth
                                value={firstname}
                                label="Nom"
                                onChange={(e) => setFirstname(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                tabIndex={-1}
                                ref={reflastname}
                                error={!!(errors && errors.lastname)}
                                helperText={errors?.lastname?.join(", ")}
                                fullWidth
                                value={lastname}
                                label="Prénoms"
                                onChange={(e) => setLastname(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                tabIndex={-1}
                                ref={refemail}
                                error={!!(errors && errors.email)}
                                helperText={errors?.email?.join(", ")}
                                fullWidth
                                value={email}
                                label="Email"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className="d-flex">
                            <FormControl fullWidth sx={{ maxWidth: "80px" }}>
                              <Autocomplete
                                sx={{ "& .MuiAutocomplete-inputRoot": { borderRadius: 0 } }}
                                disableClearable={true}
                                forcePopupIcon={false}
                                id="country-select-demo"
                                value={indicator}
                                onChange={(event, newValue) => {
                                  setIndicator(newValue && newValue.phone);
                                }}
                                fullWidth
                                options={countries}
                                autoHighlight
                                getOptionLabel={(option) => (option ? `+${option.phone}` : "")}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { flexShrink: 0 }, "& > span": { display: "none" } }} {...props}>
                                    {`+${option.phone}`}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Indice"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password",
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ "& .MuiInputBase-root": { borderRadius: 0 } }}
                                type="number"
                                tabIndex={-1}
                                ref={refphone}
                                error={!!(errors && errors.phone)}
                                helperText={errors?.phone?.join(", ")}
                                fullWidth
                                value={phone}
                                label="Téléphone"
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseAdmin}>annuler</Button>
                      <LoadingButton onClick={addAdminSecondary} loading={loading} variant="outlined">
                        Valider
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                  {/*fin dialog admin */}

                  {/* dialog edit admin secondaire */}
                  <Dialog
                    open={openAdminSecondUpdate}
                    fullWidth
                    onClose={(event, reason) => {
                      if (reason != "backdropClick") {
                        handleCloseOpenUpdate();
                      }
                    }}
                  >
                    <DialogTitle sx={{ fontSize: "1em", position: "relative" }}>
                      Modification d'un admin secondaire
                      <IconButton
                        onClick={handleDeleteSecAdmin}
                        size="small"
                        sx={{ ml: 2, position: "absolute", top: 5, right: 5 }}
                        aria-controls={openDrop ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openDrop ? "true" : undefined}
                      >
                        <DeleteSweepIcon color="error" />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent className="py-2">
                      <FormGroup>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                tabIndex={-1}
                                ref={reffirstname}
                                error={!!(errors && errors.firstname)}
                                helperText={errors?.firstname?.join(", ")}
                                fullWidth
                                value={firstname ?? ""}
                                label="Nom"
                                onChange={(e) => setFirstname(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                tabIndex={-1}
                                ref={reflastname}
                                error={!!(errors && errors.lastname)}
                                helperText={errors?.lastname?.join(", ")}
                                fullWidth
                                value={lastname ?? ""}
                                label="Prénoms"
                                onChange={(e) => setLastname(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                tabIndex={-1}
                                ref={refemail}
                                error={!!(errors && errors.email)}
                                helperText={errors?.email?.join(", ")}
                                fullWidth
                                value={email ?? ""}
                                label="Email"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className="d-flex">
                            <FormControl fullWidth sx={{ maxWidth: "80px" }}>
                              <Autocomplete
                                sx={{ "& .MuiAutocomplete-inputRoot": { borderRadius: 0 } }}
                                disableClearable={true}
                                forcePopupIcon={false}
                                id="country-select-demo"
                                value={indicator}
                                onChange={(event, newValue) => {
                                  setIndicator(newValue ? `+${newValue.phone}` : null);
                                }}
                                isOptionEqualToValue={(option) => option.phone == indicator}
                                fullWidth
                                options={countries}
                                autoHighlight
                                getOptionLabel={(option) => (option.phone ? `+${option.phone}` : indicator)}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ "& > img": { flexShrink: 0 }, "& > span": { display: "none" } }} {...props}>
                                    {`+${option.phone}`}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Indice"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password",
                                    }}
                                    error={errors && errors.pays}
                                    helperText={errors && errors.pays && errors.pays.join(", ")}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ "& .MuiInputBase-root": { borderRadius: 0 } }}
                                type="number"
                                tabIndex={-1}
                                ref={refphone}
                                error={!!(errors && errors.phone)}
                                helperText={errors?.phone?.join(", ")}
                                fullWidth
                                value={phone ?? ""}
                                label="Téléphone"
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseOpenUpdate}>annuler</Button>
                      <LoadingButton onClick={updateAdminSecondary} loading={loading} variant="outlined">
                        Valider
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                  {/*fin dialog admin edit */}
                </div>
              }
            />
            <Divider />
            <br />
            <Box>
              <Box className="mb-3">
                <Stack
                  spacing={2}
                  style={{
                    fontSize: ".9em",
                    fontWeight: "lighter",
                    color: "#4E5053",
                  }}
                >
                  {ace && (
                    <Box>
                      <small className="h6">Agent ACE: </small>
                      <span className="text-capitalize ">
                        {ace.firstname + " " + ace.lastname} ({ace.role && ace.role.title})
                      </span>
                    </Box>
                  )}
                  {agent && (
                    <Box>
                      <small className="h6">Agent de notation: </small>
                      <span className="text-capitalize ">
                        {agent.firstname + " " + agent.lastname} ({agent.role && agent.role.title})
                      </span>
                    </Box>
                  )}
                  {admins_entreprise && (
                    <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                      <Typography sx={{ fontWeight: "bold" }}>Administrateur(s)</Typography>
                      <Grid container spacing={2} className="py-3">
                        {admins_entreprise.map((admin) =>
                          admin.type_admin == "principal" ? (
                            <Grid item xs={12} sm={3} className="d-flex gap-2 align-items-center">
                              <Avatar src={`${process.env.REACT_APP_BASE_URL}${admin.image}`} alt={admin.firstname} />
                              <Typography noWrap>{admin.firstname + " " + admin.lastname + " (" + admin.type_admin + ")"}</Typography>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              className="d-flex gap-2 align-items-center"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleClickOpenUpdate(admin)}
                            >
                              <Avatar src={`${process.env.REACT_APP_BASE_URL}${admin.image}`} alt={admin.firstname} />
                              <Typography noWrap>{admin.firstname + " " + admin.lastname + " (" + admin.type_admin + ")"}</Typography>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  )}
                </Stack>
                <Divider />
              </Box>

              <form
                ref={res}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleUpdateEntreprise(id);
                }}
              >
                <Stack>
                  <span
                    style={{
                      fontSize: ".85em",
                      fontWeight: "lighter",
                      color: "#4E5053",
                      marginBottom: ".95em",
                    }}
                  >
                    Informations générales de l'entreprise
                  </span>
                  <Grid container spacing={2}>
                    <Grid container xs={12} className="d-flex justify-content-center align-items-center ">
                      <Grid container spacing={4} className="d-flex justify-content-center align-items-center my-1">
                        <Grid item xs={6} sm={3}>
                          <Dropzone onDrop={handleDrop}>
                            {({ getRootProps, getInputProps }) => (
                              <section className="mb-2">
                                <div {...getRootProps()}>
                                  {!logo && <input {...getInputProps()} />}
                                  <Box>
                                    <Badge
                                      role="button"
                                      overlap="circular"
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      sx={{
                                        borderRadius: "50%",
                                        boxShadow: "22px 22px 58px #d0d0d0,-22px -22px 58px #f0f0f0",
                                      }}
                                      badgeContent={
                                        <>
                                          {!logo ? (
                                            <IconButton
                                              aria-label="share"
                                              sx={{
                                                backgroundColor: "white",
                                                borderRadius: "50%",
                                                boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                "&:hover": {
                                                  backgroundColor: "white",
                                                },
                                              }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              aria-label="share"
                                              onClick={handleRemove}
                                              sx={{
                                                backgroundColor: "white",
                                                borderRadius: "50%",
                                                boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                "&:hover": {
                                                  backgroundColor: "white",
                                                },
                                              }}
                                            >
                                              <DeleteSweepIcon />
                                            </IconButton>
                                          )}
                                        </>
                                      }
                                    >
                                      <Avatar
                                        alt={raisonSociale}
                                        src={logo ? logoPreviewUrl : `${process.env.REACT_APP_BASE_URL}${logoPreview}`}
                                        role="button"
                                        loading="lazy"
                                        sx={{ width: 120, height: 120 }}
                                      />
                                    </Badge>
                                  </Box>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <FormControl fullWidth>
                            <TextField ref={refvi} variant="outlined" onChange={(e) => setParrain(e.target.value)} label="Parrain" value={parrain} />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} className="d-flex justify-content-center align-items-center">
                          <Dropzone onDrop={handleDropParrain}>
                            {({ getRootProps, getInputProps }) => (
                              <Tooltip title="Logo du parrain">
                                <section className="mb-2">
                                  <div {...getRootProps()}>
                                    {!logoParrain && <input {...getInputProps()} />}
                                    <Box>
                                      <Badge
                                        role="button"
                                        overlap="circular"
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                        sx={{
                                          borderRadius: "50%",
                                          boxShadow: "22px 22px 58px #d0d0d0,-22px -22px 58px #f0f0f0",
                                        }}
                                        badgeContent={
                                          <>
                                            {!logoParrain ? (
                                              <IconButton
                                                aria-label="share"
                                                sx={{
                                                  backgroundColor: "white",
                                                  borderRadius: "50%",
                                                  boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                  "&:hover": {
                                                    backgroundColor: "white",
                                                  },
                                                }}
                                              >
                                                <EditIcon sx={{ width: 10, height: 10 }} />
                                              </IconButton>
                                            ) : (
                                              <IconButton
                                                aria-label="share"
                                                onClick={handleRemoveParrain}
                                                sx={{
                                                  backgroundColor: "white",
                                                  borderRadius: "50%",
                                                  boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                  "&:hover": {
                                                    backgroundColor: "white",
                                                  },
                                                }}
                                              >
                                                <DeleteSweepIcon sx={{ width: 10, height: 10 }} />
                                              </IconButton>
                                            )}
                                          </>
                                        }
                                      >
                                        <Avatar
                                          alt={raisonSociale}
                                          src={logoParrain ? logoPreviewUrlParrain : `${process.env.REACT_APP_BASE_URL}${logoPreviewParrain}`}
                                          role="button"
                                          loading="lazy"
                                          sx={{ width: 50, height: 50 }}
                                        />
                                      </Badge>
                                    </Box>
                                  </div>
                                </section>
                              </Tooltip>
                            )}
                          </Dropzone>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo"
                          value={pays && JSON.parse(pays)}
                          onChange={(event, newValue) => {
                            setPays(JSON.stringify(newValue));
                          }}
                          fullWidth
                          options={countries}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choisir le pays"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              ref={refpa}
                              error={errors && errors.pays}
                              helperText={errors && errors.pays && errors.pays.join(", ")}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <TextField
                          ref={refvi}
                          variant="outlined"
                          onChange={(e) => setVille(e.target.value)}
                          label="Ville"
                          error={errors && errors.ville}
                          helperText={errors && errors.ville && errors.ville.join(", ")}
                          value={ville}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <TextField
                          ref={refco}
                          variant="outlined"
                          onChange={(e) => setCommune(e.target.value)}
                          label="Commune"
                          error={errors && errors.commune}
                          helperText={errors && errors.commune && errors.commune.join(", ")}
                          value={commune}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <TextField
                          ref={refru}
                          variant="outlined"
                          onChange={(e) => setRue(e.target.value)}
                          label="Rue"
                          error={errors && errors.rue}
                          helperText={errors && errors.rue && errors.rue.join(", ")}
                          value={rue}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          tabIndex={-1}
                          ref={refra}
                          error={errors && errors.raisonSociale}
                          onChange={(e) => setRaisonSociale(e.target.value)}
                          label="Raison sociale"
                          helperText={errors && errors.raisonSociale && errors.raisonSociale.join(", ")}
                          value={raisonSociale}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refac}
                          variant="outlined"
                          onChange={(e) => setActivitePrincipale(e.target.value)}
                          label="Activité principale"
                          error={errors && errors.activitePrincipale}
                          helperText={errors && errors.activitePrincipale && errors.activitePrincipale.join(", ")}
                          value={activitePrincipale}
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        tabIndex={-1}
                                                        ref={refdo}
                                                        variant='outlined'
                                                        onChange={(e) => setDomaine(e.target.value)}
                                                        label="Domaine d'activité"
                                                        error={errors && errors.domaine}
                                                        helperText={
                                                            errors && errors.domaine && errors.domaine.join(', ')
                                                        }
                                                        value={domaine}
                                                    />
                                                </FormControl>
                                            </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo2"
                          onChange={(event, newValue) => {
                            setSecteur(newValue);
                          }}
                          value={secteur}
                          options={secteurs}
                          autoHighlight
                          fullWidth
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Secteur d'activité"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo2"
                          onChange={(event, newValue) => {
                            setTypeEntreprise(newValue);
                          }}
                          value={typeEntreprise}
                          options={domaines}
                          autoHighlight
                          fullWidth
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Type d'entreprise"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo2"
                          onChange={(event, newValue) => {
                            setRegime(newValue);
                          }}
                          value={regime}
                          options={regimes}
                          autoHighlight
                          fullWidth
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Régime d'imposition"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refsi}
                          variant="outlined"
                          onChange={(e) => setSiege(e.target.value)}
                          label="Siege"
                          error={errors && errors.siege}
                          helperText={errors && errors.siege && errors.siege.join(", ")}
                          value={siege}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo2"
                          onChange={(event, newValue) => {
                            setForme(newValue);
                          }}
                          value={forme}
                          options={juridiques}
                          autoHighlight
                          fullWidth
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Forme juridique"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo2"
                          onChange={(event, newValue) => {
                            setMonnaie(JSON.stringify(newValue));
                          }}
                          value={monnaie && JSON.parse(monnaie)}
                          options={cc.data}
                          autoHighlight
                          fullWidth
                          getOptionLabel={(option) => option.currency}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choisir la monnaie"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              ref={refmo}
                              error={errors && errors.monnaie}
                              helperText={errors && errors.monnaie && errors.monnaie.join(", ")}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <FormControl fullWidth>
                          <DatePicker onChange={(date) => setDateCreation(date)} label="Date de création" value={dateCreation} />
                        </FormControl>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <FormControl fullWidth>
                          <DatePicker onChange={(date) => setDateAdhesion(date)} fullWidth label="Date d'adhésion" value={dateAdhesion} />
                        </FormControl>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <FormControl fullWidth>
                          <DatePicker onChange={(date) => setDateSelection(date)} fullWidth label="Date de selection" value={dateSelection} />
                        </FormControl>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <FormControl fullWidth>
                          <DatePicker onChange={(date) => setDateFormation(date)} fullWidth label="Date de formation" value={dateFormation} />
                        </FormControl>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <br />
                  <span
                    style={{
                      fontSize: ".85em",
                      fontWeight: "lighter",
                      color: "#4E5053",
                      margin: ".95em 0 .95em 0",
                    }}
                  >
                    Informations financières de l'entreprise
                  </span>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refnba}
                          variant="outlined"
                          onValueChange={(value) => setNbAssocie(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Nombre d'associés"
                          error={errors && errors.nbAssocie}
                          helperText={errors && errors.nbAssocie && errors.nbAssocie.join(", ")}
                          value={nbAssocie}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refnpb}
                          variant="outlined"
                          onValueChange={(value) => setNbpart(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Nombre de parts"
                          error={errors && errors.nbPart}
                          helperText={errors && errors.nbPart && errors.nbPart.join(", ")}
                          value={nbPart}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refval}
                          variant="outlined"
                          onValueChange={(value) => setValeurPart(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Valeur de la part"
                          error={errors && errors.valeurPart}
                          helperText={errors && errors.valeurPart && errors.valeurPart.join(", ")}
                          value={valeurPart}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refcap}
                          variant="outlined"
                          label="Capital"
                          error={errors && errors.capital}
                          helperText={errors && errors.capital && errors.capital.join(", ")}
                          value={nbPart * valeurPart}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refinv}
                          variant="outlined"
                          onValueChange={(value) => setInvestissement(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Investissement"
                          error={errors && errors.investissement}
                          helperText={errors && errors.investissement && errors.investissement.join(", ")}
                          value={investissement}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refcre}
                          variant="outlined"
                          onValueChange={(value) => setCreance(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Créance"
                          error={errors && errors.creance}
                          helperText={errors && errors.creance && errors.creance.join(", ")}
                          value={creance}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refdet}
                          variant="outlined"
                          onValueChange={(value) => setDette(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Dette"
                          error={errors && errors.dette}
                          helperText={errors && errors.dette && errors.dette.join(", ")}
                          value={dette}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refch}
                          variant="outlined"
                          onValueChange={(value) => setChiffreAffaire(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Chiffre d'affaire"
                          error={errors && errors.chiffreAffaire}
                          helperText={errors && errors.chiffreAffaire && errors.chiffreAffaire.join(", ")}
                          value={chiffreAffaire}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refval}
                          variant="outlined"
                          onValueChange={(value) => setValeurStock(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Valeur du stock"
                          error={errors && errors.valeurStock}
                          helperText={errors && errors.valeurStock && errors.valeurStock.join(", ")}
                          value={valeurStock}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refep}
                          variant="outlined"
                          onValueChange={(value) => setEpargne(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Epargne"
                          error={errors && errors.epargne}
                          helperText={errors && errors.epargne && errors.epargne.join(", ")}
                          value={epargne}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refga}
                          variant="outlined"
                          onValueChange={(value) => setGage(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Gage"
                          error={errors && errors.gage}
                          helperText={errors && errors.gage && errors.gage.join(", ")}
                          value={gage}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={reftit}
                          variant="outlined"
                          onValueChange={(value) => setTitrePlacement(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Titre du placement"
                          error={errors && errors.titrePlacement}
                          helperText={errors && errors.titrePlacement && errors.titrePlacement.join(", ")}
                          value={titrePlacement}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refdept}
                          variant="outlined"
                          onValueChange={(value) => setDepoTerme(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Dépot à terme"
                          error={errors && errors.depotTerme}
                          helperText={errors && errors.depotTerme && errors.depotTerme.join(", ")}
                          value={depotTerme}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          ref={refres}
                          variant="outlined"
                          onValueChange={(value) => setReserve(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Réserve"
                          error={errors && errors.reserve}
                          helperText={errors && errors.reserve && errors.reserve.join(", ")}
                          value={reserve}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <NumericFormat
                          tabIndex={-1}
                          label="Cotisation mensuelle"
                          error={errors && errors.cotisation}
                          helperText={errors && errors.cotisation && errors.cotisation.join(", ")}
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          value={cotisation}
                          onValueChange={(value) => setCotisation(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          allowNegative={false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          variant="outlined"
                          onValueChange={(value) => setCotisationCnps(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Cotisation CNPS"
                          error={errors && errors.cotisationCnps}
                          helperText={errors && errors.cotisationCnps && errors.cotisationCnps.join(", ")}
                          value={cotisationCnps}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          variant="outlined"
                          onValueChange={(value) => setCotisationCmu(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Cotisation CMU"
                          value={cotisationCmu}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{monnaie && JSON.parse(monnaie).code}</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br />
                  <span
                    style={{
                      fontSize: ".85em",
                      fontWeight: "lighter",
                      color: "#4E5053",
                      margin: ".95em 0 .95em 0",
                    }}
                  >
                    Informations administratives de l'entreprise
                  </span>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refrc}
                          variant="outlined"
                          onChange={(e) => setRccm(e.target.value)}
                          label="Numero RCCM"
                          error={errors && errors.rccm}
                          helperText={errors && errors.rccm && errors.rccm.join(", ")}
                          value={rccm}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refdf}
                          variant="outlined"
                          onChange={(e) => setDfe(e.target.value)}
                          label="Numero DFE"
                          error={errors && errors.dfe}
                          helperText={errors && errors.dfe && errors.dfe.join(", ")}
                          value={dfe}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <TextField
                          tabIndex={-1}
                          ref={refcn}
                          variant="outlined"
                          onChange={(e) => setCnps(e.target.value)}
                          label="Numero CNPS"
                          error={errors && errors.cnps}
                          helperText={errors && errors.cnps && errors.cnps.join(", ")}
                          value={cnps}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br />
                  <span
                    style={{
                      fontSize: ".85em",
                      fontWeight: "lighter",
                      color: "#4E5053",
                      margin: ".95em 0 .95em 0",
                    }}
                  >
                    Informations supplémentaires
                  </span>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <NumericFormat
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          variant="outlined"
                          onValueChange={(value) => setTaux(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Taux d'impot sur le bénéfice"
                          allowNegative={false}
                          value={taux}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <NumericFormat
                          value={tauxChargeSociale}
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          variant="outlined"
                          onValueChange={(value) => setTauxChargeSociale(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                          label="Taux de charge de sécurité sociale"
                          allowNegative={false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <NumericFormat
                          value={tauxParticipationTravailler}
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          tabIndex={-1}
                          variant="outlined"
                          onValueChange={(value) =>
                            setTauxParticipationTravailler(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")
                          }
                          label="Taux participation des travailleurs"
                          allowNegative={false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Stack>
                <br />
                <Stack direction="row" spacing={2} className="d-flex justify-content-end">
                  <LoadingButton loading={loading} variant="outlined" type="submit">
                    Enregistrer
                  </LoadingButton>
                  {!loading && (
                    <Button onClick={Reinitialiser} variant="outlined">
                      Reinitialiser
                    </Button>
                  )}
                </Stack>
              </form>
            </Box>
          </Paper>
          <ToastContainer autoClose={3000} hideProgressBar={true} pauseOnHover={false} />
        </motion.div>
      ) : (
        <div className="text-center d-flex justify-content-center align-items-center" style={{ minHeight: "65vh" }}>
          <div style={{ maxHeight: "100px", maxWidth: "100px" }} ref={loadingref}></div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ModifEntreprise;
