import { Box, CardHeader, IconButton, Tooltip, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Lottie from 'lottie-web';
import loadingwait from "../components/animations/loading.json"
import Cookies from 'js-cookie'
import axios from 'axios';
import ReplyIcon from '@mui/icons-material/Reply';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as dayjs from 'dayjs';
import 'dayjs/locale/fr';

const ShowEnregDetails = () => {
    const theme = useTheme();
    const { id } = useParams();
    const loadingref = useRef();
    const token = Cookies.get('authToken');

    const [year, setYear] = useState();
    const [mat, setMat] = useState();
    const [months, setMonths] = useState([]);
    const [entreprise, setEntreprise] = useState();

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: loadingref.current,
            animationData: loadingwait,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        return () => {
            animation.destroy();
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/selection-detail/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setYear(response.data.year);
                setEntreprise(response.data.entreprise);
                setMonths(response.data.months);
                setMat(response.data.mat); // Assurez-vous de définir correctement mat
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [id, token]);

    const container = {
        hidden: {
            opacity: 1
        },
        visible: {
            transition: {
                delayChildren: 0.05,
                staggerChildren: 0.05
            }
        }
    };
    
    const item = {
        hidden: {
            y: -20,
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    const Card = (props) => {
        const { month} = props;
        return (
            <Box sx={{ padding: "0.5em", fontSize: "20px", border: `1px solid ${theme.palette.primary.main}`, borderRadius: ".5em" }} className='d-flex justify-content-center align-items-center '>
                <Chip label={`${month}`} icon={<CalendarMonthIcon />} color="primary" variant="outlined" sx={{ borderRadius: ".5em", fontSize: "20px", border: 0 }} />
            </Box>
        );
    };

    return (
        (year && entreprise) ? (
            <Box>
                <Box sx={{ minHeight: "100vh" }} className="p-3 d-flex flex-column  justify-content-center">
                    <CardHeader
                        subheader={
                            <div>
                                <span className='h5'>Année d'exercice {year ? year : '...'}</span><br />
                                <Box className="d-flex gap-3 p-3 align-items-center">
                                    <img src={`${process.env.REACT_APP_BASE_URL}${entreprise.logo}`} style={{ width: "50px", height: "50px", objectFit: "cover", aspectRatio: 1 }} />
                                    <span className='h4'>{entreprise ? entreprise.raison_sociale : '...'}</span>
                                </Box>
                            </div>
                        }
                        action={
                            <div className='d-flex gap-2'>
                                <Tooltip title="Retourner à la liste">
                                    <Link to={`/dashboard/entreprise-comptes/${entreprise.id}`}>
                                        <IconButton aria-label="settings">
                                            <ReplyIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </div>
                        }
                    />
                    <Box className="p-3">
                        <Typography component="h5" className='my-4'>Selectionnez le mois du compte de resultat</Typography>
                        <Stack
                            component={motion.div}
                            useFlexGap flexWrap="wrap"
                            direction="row"
                            spacing={{ xs: 2, sm: 3, md: 4 }}
                            variants={container}
                            initial="hidden"
                            animate="visible"
                        >
                            <Grid container spacing={1}>
                                {
                                    months.map((month, index) => {
                                        return (
                                            <Grid xs={12} sm={6} md={4} lg={3} item key={index} component={motion.div} variants={item}>
                                                <Link to={`/dashboard/cr/${mat}/month-details/${month.id}`} className='p-2'>
                                                    <Card month={month.mois} />
                                                </Link>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </Stack>
                        <Typography component="h5" className='my-5'>Cliquez sur le bouton ci-dessous pour voir le compte de resultat de l'année d'exercice</Typography>
                        <Box className="d-flex justify-content-center align-items-center" component={motion.div} initial={{ y: 20, opacity: 0 }} transition={{ delay: 0.6 }} animate={{ y: 0, opacity: 1 }}>
                            <Link to={`/dashboard/cr/${id}/year-details/${year}`}>
                                <Chip clickable sx={{ fontSize: "18px" }} color='primary' label={`Compte de resultat exercice ${year}`} variant="outlined" />
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box sx={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                <div ref={loadingref} style={{ height: 85, width: 85 }} />
            </Box>
        )
    );
};

export default ShowEnregDetails;
