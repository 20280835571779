import React, { useEffect, useState, useRef, Fragment } from 'react';
import PageHeader from '../components/pageHeader';
import Cookies from "js-cookie"
import MyDataTable from '../components/MyDataTable';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Alert,
    Autocomplete,
    Box, FormControl, Grid, IconButton, InputAdornment, ListSubheader, MenuItem, Paper, Select, Snackbar, TextField, Typography
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { domaines } from '../components/liste';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from 'react-toastify';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Importer les locales nécessaires
import 'dayjs/locale/en'; // Exemple pour l'anglais
import LoadingButton from '@mui/lab/LoadingButton';
import SnackbarContent from '@mui/material/SnackbarContent';

// Détecter et définir la locale
const userLanguage = navigator.language || navigator.userLanguage;
dayjs.locale(userLanguage);

const Enregistrement = () => {
    const { id } = useParams()
    const token = Cookies.get('authToken')
    const [success, setSuccess] = useState()
    const [openSnack, setOpenSnack] = useState(false)
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState()
    const [entreprise, setEntreprise] = useState()
    const [bilanMonth, setBilanMonth] = useState()
    const navigate = useNavigate();

    ///affichage
    const [compte, setCompte] = useState()


    //sending
    const [venteMarchandise, setVenteMarchandise] = useState(0.00);
    const [achatMarchandise, setAchatMarchandise] = useState(0.00);
    const [variationStock, setVariationStock] = useState(0.00);
    const [venteProduitsFabriques, setVenteProduitsFabriques] = useState(0.00);
    const [travauxServiceVendus, setTravauxServiceVendus] = useState(0.00);
    const [produitsAccessoires, setProduitsAccessoires] = useState(0.00);
    const [produitStockee, setProduitStockee] = useState(0.00);
    const [productionImmobilisee, setProductionImmobilisee] = useState(0.00);
    const [subventionsExploitation, setSubventionsExploitation] = useState(0.00);
    const [autresProduitsVa, setAutresProduitsVa] = useState(0.00);
    const [transfertChargeExploitation, setTransfertChargeExploitation] = useState(0.00);
    const [achatsMatieresPremierFliees, setAchatsMatieresPremierFliees] = useState(0.00);
    const [variationStockMatieresPremiereFliees, setVariationStockMatieresPremiereFliees] = useState(0.00);
    const [autreAchatVa, setAutreAchatVa] = useState(0.00);
    const [variationStockAutreApprovisionnement, setVariationStockAutreApprovisionnement] = useState(0.00);
    const [transports, setTransports] = useState(0.00);
    const [valeurDataOne, setValeurDataOne] = useState(0.00);
    const [valeurDataTwo, setValeurDataTwo] = useState(0.00);
    const [valeurDataThree, setValeurDataThree] = useState(0.00);
    const [valeurDataFour, setValeurDataFour] = useState(0.00);
    const [valeurDataFive, setValeurDataFive] = useState(0.00);
    const [valeurDataSix, setValeurDataSix] = useState(0.00);
    const [valeurDataSeven, setValeurDataSeven] = useState(0.00);
    const [valeurDataEight, setValeurDataEight] = useState(0.00);
    const [valeurDataNine, setValeurDataNine] = useState(0.00);
    const [valeurDataTen, setValeurDataTen] = useState(0.00);
    const [valeurDataEleven, setValeurDataEleven] = useState(0.00);
    const [valeurDataTwelve, setValeurDataTwelve] = useState(0.00);
    const [valeurDataThirteen, setValeurDataThirteen] = useState(0.00);
    const [valeurDataFourteen, setValeurDataFourteen] = useState(0.00);
    const [valeurDataFifteen, setValeurDataFifteen] = useState(0.00);
    const [valeurDataSixteen, setValeurDataSixteen] = useState(0.00);
    const [impotTaxe, setImpotTaxe] = useState(0.00);
    const [autreCharge, setAutreCharge] = useState(0.00);
    const [masseSalariale, setMasseSalariale] = useState(0.00);
    const [repriseAmmortissementProvisionDepreciation, setRepriseAmmortissementProvisionDepreciation] = useState(0.00);
    const [dotationsAmortissementProvisionDepreciation, setDotationsAmortissementProvisionDepreciation] = useState(0.00);
    const [revenusFinanciersAssimiles, setRevenusFinanciersAssimiles] = useState(0.00);
    const [reprisesProvisionsDepreciationFinanciere, setReprisesProvisionsDepreciationFinanciere] = useState(0.00);
    const [transfertsChargeFinanciere, setTransfertsChargeFinanciere] = useState(0.00);
    const [fraisFinanciersChargeAssimiles, setFraisFinanciersChargeAssimiles] = useState(0.00);
    const [dotationsProvisionsDepreciationsFinancieres, setDotationsProvisionsDepreciationsFinancieres] = useState(0.00);
    const [produitsCessionsImmobilisations, setProduitsCessionsImmobilisations] = useState(0.00);
    const [autreProduitHao, setAutreProduitHao] = useState(0.00);
    const [valeurComptableCessionImmobilisation, setValeurComptableCessionImmobilisation] = useState(0.00);
    const [autresChargesHao, setAutresChargesHao] = useState(0.00);
    // const [participationDesTravailleurs, setParticipationDesTravailleurs] = useState(0.00);
    // const [impotsSurLeResultat, setImpotsSurLeResultat] = useState(0.00);
    const [month, setMonth] = useState((dayjs().month() + 1).toString().padStart(2, '0'))
    const [day, setDay] = useState(dayjs());
    const [selectedDate, setSelectedDate] = useState(dayjs());


    //sending

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const action = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnack}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}admin/infos-enregistrement/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }
                );

                const data = response.data;
                setCompte(data.compte)
                setEntreprise(data.entreprise)
                setBilanMonth(data.bilanMonth)

            } catch (error) {
                console.error(error);
            }
        };
        fetchData()
    }, []);


    const handleSendData = async () => {
        setLoading(true)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}admin/enregistrement/store/${id}`,
                {
                    vente_marchandise: venteMarchandise,
                    achat_marchandise: achatMarchandise,
                    variation_stock: variationStock,
                    vente_produits_fabriques: venteProduitsFabriques,
                    travaux_service_vendus: travauxServiceVendus,
                    produits_accessoires: produitsAccessoires,
                    produit_stockee: produitStockee,
                    production_immobilisee: productionImmobilisee,
                    subventions_exploitation: subventionsExploitation,
                    autres_produits_va: autresProduitsVa,
                    transfert_charge_exploitation: transfertChargeExploitation,
                    achats_matieres_premieres_fliees: achatsMatieresPremierFliees,
                    variation_stock_matieres_premiere_fliees: variationStockMatieresPremiereFliees,
                    autre_achat_va: autreAchatVa,
                    variation_stock_autre_approvisionnement: variationStockAutreApprovisionnement,
                    transports: transports,
                    valeur_data_one: valeurDataOne,
                    valeur_data_two: valeurDataTwo,
                    valeur_data_three: valeurDataThree,
                    valeur_data_four: valeurDataFour,
                    valeur_data_five: valeurDataFive,
                    valeur_data_six: valeurDataSix,
                    valeur_data_seven: valeurDataSeven,
                    valeur_data_eight: valeurDataEight,
                    valeur_data_nine: valeurDataNine,
                    valeur_data_ten: valeurDataTen,
                    valeur_data_eleven: valeurDataEleven,
                    valeur_data_twelve: valeurDataTwelve,
                    valeur_data_thirteen: valeurDataThirteen,
                    valeur_data_fourteen: valeurDataFourteen,
                    valeur_data_fifteen: valeurDataFifteen,
                    valeur_data_sixteen: valeurDataSixteen,
                    impot_taxe: impotTaxe,
                    autre_charge: autreCharge,
                    masse_salariale: masseSalariale,
                    reprise_ammortissement_provision_depreciation: repriseAmmortissementProvisionDepreciation,
                    dotations_amortissement_provision_depreciation: dotationsAmortissementProvisionDepreciation,
                    revenus_financiers_assimiles: revenusFinanciersAssimiles,
                    reprises_provisions_depreciation_financiere: reprisesProvisionsDepreciationFinanciere,
                    transferts_charge_financiere: transfertsChargeFinanciere,
                    frais_financiers_charge_assimiles: fraisFinanciersChargeAssimiles,
                    dotations_provisions_depreciations_financieres: dotationsProvisionsDepreciationsFinancieres,
                    produits_cessions_immobilisations: produitsCessionsImmobilisations,
                    autre_produit_hao: autreProduitHao,
                    valeur_comptable_cession_immobilisation: valeurComptableCessionImmobilisation,
                    autres_charges_hao: autresChargesHao,
                    // participation_des_travailleurs: participationDesTravailleurs,
                    // impots_sur_le_resultat: impotsSurLeResultat,
                    day,
                    month: bilanMonth ? bilanMonth.month : '',
                    id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
            if (response.data.success == true) {

                setErrors()
                setSuccess(response.data.message)
                setTimeout(() => {
                    setOpenSnack(true)
                }, 50);
                setTimeout(() => {
                    // navigate(`/dashboard/entreprise-comptes/${entreprise}`);
                    navigate(`/dashboard/cr/${bilanMonth.compte_resultat_id}/month-details/${id}`);
                }, 2000);
            } else {
                setSuccess()
                setErrors(response.data.errors)
                setTimeout(() => {
                    setOpenSnack(true)
                }, 50);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }
    

    return (
        <>
            {
                success ? (
                    <Snackbar
                        open={openSnack}
                        autoHideDuration={5000}
                        onClose={handleCloseSnack}
                        action={action}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                            {success}
                        </Alert>
                    </Snackbar>
                ) : errors && (
                    <Snackbar
                        open={openSnack}
                        onClose={handleCloseSnack}
                        action={action}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
                            {errors}
                        </Alert>
                    </Snackbar>
                )
            }

            <Paper elevation={1} className='p-2'>
                <Box className="d-flex justify-content-between align-items-center mb-4">
                    <Typography variant='caption' className='fw-bold' style={{fontSize:'1.1em'}}> {compte && compte.libelle} ({compte ? compte.annee : '...'})  Mois de {dayjs(bilanMonth ? bilanMonth.month : '...').format('MMMM YYYY')}</Typography>
                    <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ maxWidth: 150 }}
                            label="Sélectionner un jour"
                            value={day}
                            onChange={(newValue) => setDay(newValue)}
                        />
                    </LocalizationProvider>
                        
                    </Box>
                </Box>
                <Box className="p-3" style={{ backgroundColor:'#E0E0E0' }}>
                    {/* marge commerciale */}
                    <Box className="text-center fw-bold mt-1 p-2 rounded" style={{ backgroundColor:'#03A9F4' }}> MARGE COMMERCIALE (XA=TA-RA-RB) </Box>

                    <Grid spacing={2} container className='mt-1'>
                        {/* vente de marchandises */}
                        <Grid item xs={12} sm={6} md={4} >
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TA) Ventes de marchandises"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={venteMarchandise}
                                    variant='outlined'
                                    onValueChange={(value) => setVenteMarchandise((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>
                        {/* achats de marchandises */}
                        <Grid item xs={12} sm={6} md={4} >
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RA) Achats de marchandises"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={achatMarchandise}
                                    variant='outlined'
                                    onValueChange={(value) => setAchatMarchandise((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>
                        {/* variation de stocks */}
                        <Grid item xs={12} sm={6} md={4} >
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RB) Variation de stocks de marchandises"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={variationStock}
                                    variant='outlined'
                                    onValueChange={(value) => setVariationStock((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                </Box>


                <Box className="p-3 mt-2" style={{ backgroundColor:'#E0E0E0' }}>
                    {/* chiffre d'affaire */}
                    <Box className="text-center fw-bold mt-1 p-2 rounded" style={{ backgroundColor:'#F44336' }}> CHIFFRE D'AFFAIRES (XB) </Box>
                    <Grid spacing={2} container className='mt-1'>
                        {/* vente de marchandises */}
                        <Grid item xs={12} sm={6} md={4} >
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TB) Ventes de produits fabriqués"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={venteProduitsFabriques}
                                    variant='outlined'
                                    onValueChange={(value) => setVenteProduitsFabriques((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>
                        {/* achats de marchandises */}
                        <Grid item xs={12} sm={6} md={4} >
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TC) Travaux, services vendus"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={travauxServiceVendus}
                                    variant='outlined'
                                    onValueChange={(value) => setTravauxServiceVendus((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>
                        {/* variation de stocks */}
                        <Grid item xs={12} sm={6} md={4} >
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TD) Produits accessoires"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={produitsAccessoires}
                                    variant='outlined'
                                    onValueChange={(value) => setProduitsAccessoires((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                </Box>


                <Box className="p-3 mt-2" style={{ backgroundColor:'#E0E0E0' }}>
                    {/* valeur ajoutée */}
                    <Box className="text-center fw-bold mt-1 p-2 rounded" style={{ backgroundColor:'#CDDC39' }}> VALEUR AJOUTEE (XC) </Box>
                    <Grid spacing={2} container className='mt-1'>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TE) Production stockée (ou destockage)"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={produitStockee}
                                    variant='outlined'
                                    onValueChange={(value) => setProduitStockee((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TF) Production immobilisée"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={productionImmobilisee}
                                    variant='outlined'
                                    onValueChange={(value) => setProductionImmobilisee((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TG) Subventions d'exploitation"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={subventionsExploitation}
                                    variant='outlined'
                                    onValueChange={(value) => setSubventionsExploitation((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TH) Autres produits "
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={autresProduitsVa}
                                    variant='outlined'
                                    onValueChange={(value) => setAutresProduitsVa((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TI) Transferts de charges d'exploitation"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={transfertChargeExploitation}
                                    variant='outlined'
                                    onValueChange={(value) => setTransfertChargeExploitation((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RC) Achats de matières premières et fournitures liées"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={achatsMatieresPremierFliees}
                                    variant='outlined'
                                    onValueChange={(value) => setAchatsMatieresPremierFliees((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RD) Variation de stock de matières premières et fournitures lées"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={variationStockMatieresPremiereFliees}
                                    variant='outlined'
                                    onValueChange={(value) => setVariationStockMatieresPremiereFliees((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RE) Autres achats "
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={autreAchatVa}
                                    variant='outlined'
                                    onValueChange={(value) => setAutreAchatVa((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RF) Variation de stocks d'autres approvisionnements"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={variationStockAutreApprovisionnement}
                                    variant='outlined'
                                    onValueChange={(value) => setVariationStockAutreApprovisionnement((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RG) Transports"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={transports}
                                    variant='outlined'
                                    onValueChange={(value) => setTransports((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RI) Impôt et Taxes"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={impotTaxe}
                                    variant='outlined'
                                    onValueChange={(value) => setImpotTaxe((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RJ) Autres Charges"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={autreCharge}
                                    variant='outlined'
                                    onValueChange={(value) => setAutreCharge((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} xl={12} xxl={12}>
                            <Box className="mt-1 p-1 bg-white rounded">
                                <Grid xs={12} lg={12} md={12} xl={12} xxl={12} className='mb-2 fw-bold'>
                                    services extérieurs
                                </Grid>
                                <Grid spacing={2} container>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-1) Eau et électricité"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataOne}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataOne((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-2) Sous-traitance"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataTwo}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataTwo((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-3) Crédit-bail"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataThree}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataThree((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-4) Locations immobilières (immeubles) et mobilières (machines, voitures, ...)"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataFour}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataFour((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-5) Charges locatives"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataFive}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataFive((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-6) Travaux d'entretien et de réparation"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataSix}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataSix((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-7) Primes d'assurance"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataSeven}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataSeven((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-8) Études et recherches"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataEight}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataEight((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-9) Personnel intérimaire"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataNine}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataNine((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-10) Honoraires d’expert-comptable"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataTen}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataTen((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-11) Honoraires d’avocat"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataEleven}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataEleven((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-12) Autres honoraires"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataTwelve}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataTwelve((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-13) Publicité"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataThirteen}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataThirteen((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-14) Frais postaux et de télécommunications"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataFourteen}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataFourteen((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-15) Frais de représentation"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataFifteen}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataFifteen((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <NumericFormat
                                                tabIndex={-1}
                                                label="(RH-16) Cadeaux d’entreprise"
                                                fixedDecimalScale
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                customInput={TextField}
                                                value={valeurDataSixteen}
                                                variant='outlined'
                                                onValueChange={(value) => setValeurDataSixteen((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                                allowNegative={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>


                <Box className="p-3 mt-2" style={{ backgroundColor:'#E0E0E0' }}>
                    {/* execdent bruut exploitation */}
                    <Box className="text-center fw-bold mt-1 p-2 rounded" style={{ backgroundColor:'#FF5722' }}> EXCEDENT BRUT D'EXPLOITATION (XD) </Box>
                    <Grid spacing={2} container className='mt-1'>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RK_1) Charges de personnel: Masse Salariale"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={masseSalariale}
                                    variant='outlined'
                                    onValueChange={(value) => setMasseSalariale((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>


                    </Grid>
                </Box>

                <Box className="p-3 mt-2" style={{ backgroundColor:'#E0E0E0' }}>
                    {/*resultat d'exploitation */}
                    <Box className="text-center fw-bold mt-1 p-2 rounded" style={{ backgroundColor:'#9C27B0' }}> RESULTAT D'EXPLOITATION (XE) </Box>
                    <Grid spacing={2} container className='mt-1'>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TJ) Reprises d'Amortissements, Provisions et Dépréciations"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={repriseAmmortissementProvisionDepreciation}
                                    variant='outlined'
                                    onValueChange={(value) => setRepriseAmmortissementProvisionDepreciation((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RL) Dotations aux Amortissements,aux Provisions et Dépréciations"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={dotationsAmortissementProvisionDepreciation}
                                    variant='outlined'
                                    onValueChange={(value) => setDotationsAmortissementProvisionDepreciation((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>



                    </Grid>
                </Box>

                <Box className="p-3 mt-2" style={{ backgroundColor:'#E0E0E0' }}>
                    {/*resultat financier */}
                    <Box className="text-center fw-bold mt-1 p-2 rounded" style={{ backgroundColor:'#E91E63' }}> RESULTAT FINANCIER (XF) </Box>
                    <Grid spacing={2} container className='mt-1'>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TK) Revenus Financierset Assimilés"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={revenusFinanciersAssimiles}
                                    variant='outlined'
                                    onValueChange={(value) => setRevenusFinanciersAssimiles((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TL) Reprises de  Provisions et aux  Dépréciations Financières"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={reprisesProvisionsDepreciationFinanciere}
                                    variant='outlined'
                                    onValueChange={(value) => setReprisesProvisionsDepreciationFinanciere((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TM) Transferts de Charges Financières"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={transfertsChargeFinanciere}
                                    variant='outlined'
                                    onValueChange={(value) => setTransfertsChargeFinanciere((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RM) Frais Financiers et Charges Assimilées"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={fraisFinanciersChargeAssimiles}
                                    variant='outlined'
                                    onValueChange={(value) => setFraisFinanciersChargeAssimiles((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RN) Dotations aux  Provisions et aux Dépréciations Financières"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={dotationsProvisionsDepreciationsFinancieres}
                                    variant='outlined'
                                    onValueChange={(value) => setDotationsProvisionsDepreciationsFinancieres((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>



                    </Grid>
                </Box>

                <Box className="p-3 mt-2" style={{ backgroundColor:'#E0E0E0' }}>
                    {/* resultat hors activite ordinaire */}
                    <Box className="text-center fw-bold mt-1 p-2 rounded" style={{ backgroundColor:'#00897B' }}> RESULTAT HORS ACTIVITES ORDINAIRES (XH) </Box>
                    <Grid spacing={2} container className='mt-1'>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TN) Produits des Cessions d'Immobilisations"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={produitsCessionsImmobilisations}
                                    variant='outlined'
                                    onValueChange={(value) => setProduitsCessionsImmobilisations((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(TO) Autres Produits HAO"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={autreProduitHao}
                                    variant='outlined'
                                    onValueChange={(value) => setAutreProduitHao((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RO) Valeurs Comptables des Cessions d'Immobilisations"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={valeurComptableCessionImmobilisation}
                                    variant='outlined'
                                    onValueChange={(value) => setValeurComptableCessionImmobilisation((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RP) Autres Charges HAO"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={autresChargesHao}
                                    variant='outlined'
                                    onValueChange={(value) => setAutresChargesHao((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl>
                        </Grid>



                    </Grid>
                </Box>

                <Box className="p-3 mt-2" style={{ backgroundColor:'#E0E0E0' }}>
                    {/* resultat net */}
                    <Box className="text-center fw-bold mt-1 p-2 rounded" style={{ backgroundColor:'#4CAF50' }}> RESULTAT NET (XI) </Box>
                    <Grid spacing={2} container className='mt-1'>

                        <Grid item xs={12} sm={12} md={12}>
                            {/* <FormControl fullWidth>
                                <NumericFormat
                                    tabIndex={-1}
                                    label="(RK) Charges de personnel: Masse Salariale"
                                    fixedDecimalScale
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    customInput={TextField}
                                    value={masseSalariale}
                                    variant='outlined'
                                    onValueChange={(value) => setMasseSalariale((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : 0.00)}
                                    allowNegative={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                />
                            </FormControl> */}
                            <SnackbarContent
                                message={
                                    <>
                                    Le résultat net sera déterminé automatiquement en fonction du résultat des activités ordinaires, du résultat hors activités ordinaires, de la participation des travailleurs et de l'impôt sur le résultat, selon la formule suivante : <strong>(XI = XG + XH + RQ + RS)</strong>.
                                    </>
                                }
                            />
                        </Grid>


                    </Grid>
                </Box>

                <Box className="d-flex justify-content-end mt-2">
                    <LoadingButton loading={loading} onClick={handleSendData} variant="outlined"  >
                        Enregistrer
                    </LoadingButton>
                </Box>
            </Paper>

        </>
    );
}

export default Enregistrement;