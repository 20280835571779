import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Lottie from "lottie-web";
import loadingwait from "../components/animations/loading.json";

const Verify = () => {
  const [certified, setCertified] = useState(false);
  const [infos, setInfos] = useState();

  const token = Cookies.get("authToken");
  const { id } = useParams();
  const loadingref = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}verify/diplome/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCertified(response.data.statut);
        setInfos(response.data.infos);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id, token]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  const styles = {
    pageContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 20,
      borderRadius: 15,
      background: "linear-gradient(145deg, #ffffff, #f7f7f7)",
      boxShadow: "10px 10px 30px rgba(0,0,0,0.1), -10px -10px 30px rgba(255,255,255,0.7)",
      maxWidth: "800px",
      width: "100%",
      margin: "20px",
      textAlign: "center",
    },
    headerText: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#3a7d44",
      marginBottom: "20px",
    },
    certificatContainer: {
      textAlign: "center",
      marginTop: "20px",
    },
    diplomeImage: {
      width: "200px",
      height: "auto",
      borderRadius: "10px",
    },
    validityText: {
      marginTop: "15px",
      fontSize: "16px",
      fontWeight: "normal",
      color: "#555",
    },
    infoContainer: {
      width: "100%",
      marginTop: "20px",
      padding: "15px",
      background: "#f9f9f9",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    },
    infoRow: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 0",
      borderBottom: "1px solid #eee",
    },
    infoLabel: {
      fontSize: "16px",
      color: "#3a3a3a",
      fontWeight: "600",
    },
    infoValue: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#2c2c2c",
    },
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        background: "#f4f4f9",
      }}
    >
      {/* Watermark background */}
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: 0.05,
          zIndex: -1,
        }}
      >
        <img src="/diplome/logo-ayechin.png" alt="Logo" style={{ width: "50%", height: "auto" }} />
      </div>

      {!certified ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div style={{ maxHeight: "120px", maxWidth: "120px" }} ref={loadingref}></div>
        </div>
      ) : certified === "ok" && infos ? (
        <div style={styles.pageContainer}>
          <h1 style={styles.headerText}>Diplôme Certifié</h1>
          <div style={styles.certificatContainer}>
            <img
              src="/diplome/validate.png"
              alt="Diplôme Certifié"
              style={styles.diplomeImage}
            />
            <p style={styles.validityText}>
              Ce diplôme est validé et certifié par AYECHIN ORGANIZATION. Le titulaire a suivi avec succès le programme requis et obtenu la certification. Félicitations !
            </p>
          </div>
          <div style={styles.infoContainer}>
            {Object.entries(infos).map(([key, value]) => (
              <div key={key} style={styles.infoRow}>
                <span style={styles.infoLabel}>{key.replace(/_/g, " ").toUpperCase()}:</span>
                <span style={styles.infoValue}>{value}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div style={styles.pageContainer}>
          <h1 style={{ ...styles.headerText, color: "#d9534f" }}>Diplôme Non Certifié</h1>
          <div style={styles.certificatContainer}>
            <img
              src="/diplome/novalid.png"
              alt="Diplôme Invalide"
              style={styles.diplomeImage}
            />
            <p style={styles.validityText}>
              Ce diplôme n'est pas validé par AYECHIN ORGANIZATION. Veuillez vérifier vos informations ou contacter notre support pour plus de détails.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Verify;
