import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Card, CircularProgress, Box, Grid, CardHeader, Stack, Typography, IconButton, Tooltip, CardContent } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import ReplyIcon from "@mui/icons-material/Reply";
import EventIcon from "@mui/icons-material/Event";
import PrintIcon from "@mui/icons-material/Print";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "dayjs/locale/fr";
import Lottie from "lottie-web";
import loadingwait from "../components/animations/loading.json";

const ShowEntreprise = () => {
  dayjs.locale("fr");
  const { id } = useParams();
  const token = Cookies.get("authToken");
  const isAce = JSON.parse(Cookies.get("authUser")).isAce;
  const isAdmin = JSON.parse(Cookies.get("authUser")).isAdmin;
  const id_ace = JSON.parse(Cookies.get("authUser")).id;

  const [reloadData, setReloadData] = useState(false);
  const [entreprise, setEntreprise] = useState();
  const [admins, setAdmins] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/entreprises/show/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.entreprise) {
          setEntreprise(response.data.entreprise);
          setAdmins(response.data.admins);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [reloadData]);
  const loadingref = useRef();

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  const exportInfos = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/generate-company-infos-pdf/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob", // Important pour télécharger le PDF correctement
      });

      // Créer une URL pour le blob
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));

      // Créer un lien et cliquer dessus pour télécharger le fichier
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `FICHE D'INFORMATION DE L'ENTREPRISE ${entreprise.raison_sociale}.pdf`);
      document.body.appendChild(link);
      link.click();
      // Nettoyer l'URL de l'objet après le téléchargement
      window.URL.revokeObjectURL(url);
      toast.success("Exportation realisée avec succès!");
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la génération du PDF");
    }
  };

  const handleGeneratePDF = async () => {
    toast
      .promise(exportInfos(id), {
        pending: "Exportation en cours...",
      })
      .then(() => {})
      .catch((error) => {
        console.error("Erreur lors de l'exportation des informations", error);
      });
  };

  return (
    <>
      
      {entreprise ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <Card className="my-3">
            <CardHeader
              action={
                <>
                  <Tooltip title="Retourner à la liste">
                    <Link to={!isAdmin == 1 ? `/dashboard/entreprises-ace/${id_ace}` : `/dashboard/gestion-entreprise`}>
                      <IconButton aria-label="settings">
                        <ReplyIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Modifier">
                    <Link to={`/dashboard/gestion-entreprise/edit/${entreprise.id}`}>
                      <IconButton aria-label="share">
                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                            stroke="green"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                            stroke="green"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path d="M15.1655 4.60254L19.7315 9.16854" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Exporter au format pdf">
                    <IconButton aria-label="print" onClick={handleGeneratePDF}>
                      <PrintIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </>
              }
              subheader={`Informations sur l'entreprise (${entreprise.raison_sociale})`}
            />
            <Box className="d-flex justify-content-center align-items-center gap-3">
              <Avatar
                alt={`${entreprise.raison_sociale}`}
                src={`${process.env.REACT_APP_BASE_URL}${entreprise.logo}`}
                role="button"
                loading="lazy"
                sx={{ width: 100, height: 100 }}
              />
              <Box>
                <span>{`${entreprise.raison_sociale} (${
                  entreprise.activite_principale
                    ? `${entreprise.activite_principale}, ${entreprise.forme_juridique ? `${entreprise.forme_juridique} ` : ""},${
                        entreprise.regime_imposition ? `${entreprise.regime_imposition}` : ""
                      }`
                    : ""
                })`}</span>
                <span>
                  {`${entreprise.forme_juridique ? `${entreprise.forme_juridique}, ` : ""} 
                                         ${entreprise.siege ? `${entreprise.siege}` : ""} 
                                            ${JSON.parse(entreprise.pays) !== null ? `${JSON.parse(entreprise.pays).label}` : ""}${
                    entreprise.rue ? `, ${entreprise.rue}` : ""
                  }`}
                </span>
              </Box>
            </Box>
            <CardContent>
              <div className="small my-3">
                {" "}
                <span className="fw-bold">Agent ACE:</span> {entreprise.nom_ace ? entreprise.nom_ace + " " + entreprise.prenoms_ace : "Non attribué"}{" "}
              </div>
              <div className="small my-3">
                {" "}
                <span className="fw-bold">Agent de notation:</span> {entreprise.nom_agent ? entreprise.nom_agent + " " + entreprise.prenoms_agent : "Non attribué"}{" "}
              </div>
              {admins.length > 0 && (
                <div className="small">
                  <span className="fw-bold">Administrateur(s):</span>
                  <Grid container spacing={2} className="py-3">
                    {admins.map((admin) => (
                      <Grid item xs={12} sm={4} className="d-flex gap-2 align-items-center">
                        <Avatar src={`${process.env.REACT_APP_BASE_URL}${admin.image}`} alt={admin.firstname} />
                        <Typography noWrap>{admin.firstname + " " + admin.lastname} ({admin.type_admin})</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
              <div className="small my-3 d-flex gap-3 align-items-center ">
                <div>
                  <span className="fw-bold">Parrain:</span>
                  {entreprise.parrain ? entreprise.parrain : "Pas de parrain"}
                </div>
                {entreprise.logo_parrain && (
                  <Avatar
                    alt={`${entreprise.raison_sociale}`}
                    src={`${process.env.REACT_APP_BASE_URL}${entreprise.logo_parrain}`}
                    role="button"
                    loading="lazy"
                    sx={{ width: 50, height: 50 }}
                  />
                )}
              </div>{" "}
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <Card
                      sx={{
                        backgroundColor: "rgb(58,87,232,0.15)",
                        boxShadow: "none",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <CardHeader
                        avatar={
                          <IconButton
                            aria-label="settings"
                            sx={{
                              backgroundColor: "rgb(58,87,232,0.3)",
                              boxShadow: "0 0 4px 0 rgba(58,87,232, 0.3)",
                            }}
                          >
                            <EventIcon sx={{ color: "rgb(58,87,232,0.8)" }} />
                          </IconButton>
                        }
                        title="Date de création"
                        subheader={dayjs(entreprise.date_creation).format("DD/MM/YYYY")}
                      />
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <Card
                      sx={{
                        backgroundColor: "rgb(58,87,232,0.15)",
                        boxShadow: "none",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <CardHeader
                        avatar={
                          <IconButton
                            aria-label="settings"
                            sx={{
                              backgroundColor: "rgb(58,87,232,0.3)",
                              boxShadow: "0 0 4px 0 rgba(58,87,232, 0.3)",
                            }}
                          >
                            <DocumentScannerIcon sx={{ color: "rgb(58,87,232,0.8)" }} />
                          </IconButton>
                        }
                        title="Numero CNPS"
                        subheader={entreprise.cnps}
                      />
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <Card
                      sx={{
                        backgroundColor: "rgb(58,87,232,0.15)",
                        boxShadow: "none",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <CardHeader
                        avatar={
                          <IconButton
                            aria-label="settings"
                            sx={{
                              backgroundColor: "rgb(58,87,232,0.3)",
                              boxShadow: "0 0 4px 0 rgba(58,87,232, 0.3)",
                            }}
                          >
                            <DocumentScannerIcon sx={{ color: "rgb(58,87,232,0.8)" }} />
                          </IconButton>
                        }
                        title="Numero DFE"
                        subheader={entreprise.dfe}
                      />
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <Card
                      sx={{
                        backgroundColor: "rgb(58,87,232,0.15)",
                        boxShadow: "none",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <CardHeader
                        avatar={
                          <IconButton
                            aria-label="settings"
                            sx={{
                              backgroundColor: "rgb(58,87,232,0.3)",
                              boxShadow: "0 0 4px 0 rgba(58,87,232, 0.3)",
                            }}
                          >
                            <DocumentScannerIcon sx={{ color: "rgb(58,87,232,0.8)" }} />
                          </IconButton>
                        }
                        title="Numero RCCM"
                        subheader={entreprise.rccm}
                      />
                    </Card>
                  </Box>
                </Grid>
              </Grid>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="fw-bold">Associé(s)</TableCell>
                      <TableCell className="fw-bold">Part(s)</TableCell>
                      <TableCell className="fw-bold">Valeur part(s)</TableCell>
                      <TableCell className="fw-bold">Capital</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{entreprise.nb_associe && entreprise.nb_associe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.nb_part && entreprise.nb_part.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.valeur_part && entreprise.valeur_part.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.capital && entreprise.capital.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="fw-bold">Investissement</TableCell>
                      <TableCell className="fw-bold">Créance</TableCell>
                      <TableCell className="fw-bold">Dette</TableCell>
                      <TableCell className="fw-bold">Chiffre d'affaire</TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{entreprise.investissement && entreprise.investissement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.creance && entreprise.creance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.dette && entreprise.dette.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.chiffre_affaire && entreprise.chiffre_affaire.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="fw-bold">Valeur stock</TableCell>
                      <TableCell className="fw-bold">Epargne</TableCell>
                      <TableCell className="fw-bold">Gage</TableCell>
                      <TableCell className="fw-bold">Titre placement</TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{entreprise.valeur_stock && entreprise.valeur_stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.epargne && entreprise.epargne.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.gage && entreprise.gage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.titre_placement && entreprise.titre_placement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="fw-bold">Dépot à terme</TableCell>
                      <TableCell className="fw-bold">Reserve</TableCell>
                      <TableCell className="fw-bold">Cotisation mensuelle / CNPS / CMU</TableCell>
                      {/* <TableCell className='fw-bold'>Cotisation CNPS</TableCell> */}
                      <TableCell className="fw-bold text-end">Monnaie</TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{entreprise.depot_terme && entreprise.depot_terme.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>{entreprise.reserve && entreprise.reserve.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell>
                        {entreprise.cotisation_mensuelle && entreprise.cotisation_mensuelle.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /{" "}
                        {entreprise.cotisation_cnps && entreprise.cotisation_cnps.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /{" "}
                        {entreprise.cotisation_cmu && entreprise.cotisation_cmu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      {/* <TableCell>{entreprise.cotisation_cnps && entreprise.cotisation_cnps.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell> */}
                      <TableCell className="text-end">{JSON.parse(entreprise.monnaie).currency}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <ToastContainer autoClose={5000} hideProgressBar={true} pauseOnHover={false} />
        </motion.div>
      ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
          <div style={{ maxHeight: "100px", maxWidth: "100px" }} ref={loadingref}></div>
        </div>
      )}
    </>
  );
};

export default ShowEntreprise;
