import * as React from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { CardContent } from '@mui/material';
export default function StatCard({
  title,
  value,
  interval,
  trend,
  route,
  visible
}) {



  return (
    <Link to={ visible ?"" : route}>
    <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction="column"
          sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
        >
          <Stack sx={{ justifyContent: 'space-between' }}>
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="h4" component="p">
                {value}
              </Typography>
              <AccountCircleOutlinedIcon fontSize='large' />
            </Stack>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {visible ? " ":"Cliquer sur le cadre pour la liste complète"} 
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
    </Link>
  );
}
