import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'
import { CloseButton } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from '../../../components/Card';
import { ToastContainer, toast } from 'react-toastify';
import { Alert, AlertTitle, Autocomplete, Collapse, IconButton, MenuItem, TextField } from '@mui/material';
import { detaildomaines, domaines } from '../components/liste';
const ModifObjectif = () => {
    var cc = require('currency-codes');
    const { id } = useParams()
    const token = Cookies.get('authToken');
    const [devise, setDevise] = useState();
    const [domaine, setDomaine] = useState();
    const [prixFormation, setPrixFormation] = useState();
    const [lvlAce, setLvlAce] = useState();
    const [nbCible, setNbcible] = useState()
    const [objectif, setObjectif] = useState()
    const [success, setSuccess] = useState()
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const err = useRef();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/objectifs-ace/edit/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setObjectif(response.data.objectif);

                if (response.data.objectif) {
                    setDevise(JSON.parse(response.data.objectif.devise_paiement))
                    setDomaine(response.data.objectif.designation)
                    setPrixFormation(response.data.objectif.cout_formation)
                    setLvlAce(response.data.objectif.niveau_objectif)
                    setNbcible(response.data.objectif.nb_entreprise_target)
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const globafetch = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/objectifs-ace/edit/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setObjectif(response.data.objectif);
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpdateObjectif = async (id) => {
        setLoading(true);
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}admin/objectifs/update/${id}`,
                {
                    devise,
                    domaine,
                    prixFormation,
                    lvlAce,
                    nbCible
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.data.success == true) {
                setOpen(true)
                setErrors(null)
                setSuccess(response.data.message)
                setTimeout(() => {
                    err.current.focus()
                }, 500);
            } else {
                setSuccess(null)
                setErrors(response.data.errors)
                setOpen(true)
                setTimeout(() => {
                    err.current.focus()
                }, 500);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            globafetch()
        }
    };

    return (
        <div style={{ marginTop: "5em" }}>
            {
                objectif ? (
                    <Card className='p-4'>
                        <Collapse ref={err} tabIndex={-1} in={open}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => setOpen(false)}
                                    >
                                        <CloseButton style={{ fontSize: '.5em', color: 'white' }} />
                                    </IconButton>
                                }
                                severity={errors ? "error" : success ? "success" : ""}
                            >
                                <AlertTitle>
                                    {success ? ("Création reussie") : errors ? ("Erreur lors de la création:") : null}
                                </AlertTitle>
                                {success ?
                                    (
                                        <div>{success} <span>Pour revenir à la liste des objectifs cliquez sur le boutton ci-après ou réaliser d'autres modifications. <Link to='/dashboard/objectifs' style={{ textDecoration: "underline", cursor: 'pointer' }}> Revenir à la liste</Link> </span></div>
                                    ) :
                                    errors ? (errors) :
                                        null}
                            </Alert>
                        </Collapse>
                        <br />
                        <div className='d-flex flex-column gap-5 justify-content-center'>
                            <h5>Modification des objectifs d'un ACE de niveau {objectif.niveau_objectif} qui s'occupe d'un/une {objectif.designation} </h5>
                            <form >
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2 mb-md-0'>

                                            <TextField
                                                    tabIndex={-1}
                                                    select
                                                    fullWidth
                                                    value={domaine}
                                                    label="Domaine d'activité"
                                                    onChange={(e) => setDomaine(e.target.value)}
                                                >
                                                    <MenuItem value="">...</MenuItem>
                                                    {
                                                        detaildomaines.map((domaine) => {
                                                            return (
                                                                <MenuItem value={domaine.value}>{domaine.label} ({domaine.value})</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                        </div>

                                        <div className='col-md-3 mb-2 mb-md-0'>
                                            <label htmlFor='cform'>Cout de la formation: </label>
                                            <input id='cform' value={prixFormation} onChange={(e) => setPrixFormation(e.target.value)} className='form-control' type='number' />
                                        </div>
                                        <div className='col-md-3 mb-2 mb-md-0'>
                                            <label htmlFor='lvl'>Niveau de l'ACE: </label>
                                            <select id='domaine' onChange={(e) => setLvlAce(e.target.value)} class="form-select" aria-label="Default select example">
                                                <option selected={lvlAce == "1"} value="1">1</option>
                                                <option selected={lvlAce == "2"} value="2">2</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-md-6 mb-2 mb-md-0'>
                                            <Autocomplete
                                                id="country-select-demo2"
                                                onChange={(event, newValue) => {
                                                    setDevise(newValue ? newValue : null);
                                                }}

                                                value={devise}
                                                options={cc.data}
                                                autoHighlight
                                                fullWidth
                                                getOptionLabel={(option) => option ? option.currency : ''}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Choisir la devise"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password',
                                                        }}

                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='col-md-6 mb-2 mb-md-0'>
                                            <label htmlFor='nbent'>Nombre d'entreprises ciblées :</label>
                                            <input id='nbent' defaultValue={nbCible} onChange={(e) => setNbcible(e.target.value)} className='form-control' type='number' />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline-success' onClick={(e) => { e.preventDefault(); handleUpdateObjectif(objectif.id) }}> {loading ? "Validation en cours..." : "Valider"} </button> &nbsp;
                                    <Link to="/dashboard/objectifs" className='btn btn-outline-danger'  >Retourner à la liste</Link>
                                </div>
                            </form>
                        </div>
                    </Card>

                ) : "Veuillez patienter..."
            }
        </div>
    );
}

export default ModifObjectif;
