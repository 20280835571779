import { useState, useRef, useEffect } from "react";
import { Row, Col, Image, Form, Button, ListGroup, } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../../components/Card'
import axios from 'axios';
import Cookies from "js-cookie";
import Alert from '@mui/material/Alert';
// import { AuthUser } from "./controllers/AuthUser";


import auth1 from '../../../assets/images/auth/01.png'
import { Box } from "@mui/material";

const SignInChief = () => {
   const verif = Cookies.get("authUser");

   useEffect(() => {
      if (verif) {
        window.location.href = "/dashboard";
      }
    }, []);

   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(false);
   const [severity, setSeverity] = useState('');
   // Paramètres de soumission
   const [identifiant, setIdentifiant] = useState(null);
   const [password, setPassword] = useState(null);

   // Cible de la réponse
   const authentified = useRef();
   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;



   // Fonction de connexion admin
   const handleLoginAdmin = async (e) => {
      e.preventDefault();
      setIsLoading(true);

      try {
         // Appel d'API
         const response = await axios.post(`${apiBaseUrl}login/chief`, {
            identifiant,
            password,
         });

         const data = response.data;

         // Mise à jour du token et de l'utilisateur authentifié

         if (data.success == true) {
            authentified.current.textContent = '';
            setSeverity('success');
            authentified.current.appendChild(document.createTextNode(data.message));
            setTimeout(() => {
               navigate('/dashboard');
            }, 500);
            Cookies.set('authToken', data.token, { expires: 365 });
            Cookies.set('authUser', JSON.stringify(data.user), { expires: 365 });
            Cookies.set('authIdentity', JSON.stringify(data.type), { expires: 365 });
            Cookies.set('company_admin', 1, { expires: 365 });
         } else {
            authentified.current.textContent = '';
            setSeverity('error');
            authentified.current.appendChild(document.createTextNode(data.message));
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   return (
      <>
         <section className="login-content">
            <Row className="m-0 align-items-center bg-white vh-100">
               <Col md="6">
                  <Row className="justify-content-center">
                     <Col md="10">
                        <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                           <Card.Body>
                              <Link to="/" className="navbar-brand d-flex justify-content-center align-items-center mb-1">
                                 <img src="../logo.jpg" style={{ height: "90px", width: "90px", objectFit: "cover" }} />
                              </Link>
                              <h2 className="mb-2 text-center">Connexion</h2>
                              <p className="text-left">Entrez vos informations pour accéder à votre espace de travail.</p>
                              <Alert severity={severity} sx={{ mt: 3, mb:3 }}  > <span ref={authentified}></span></Alert>
                              <Form>
                                 <Row>
                                    <Col lg="12">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="email" className="">Email ou Téléphone</Form.Label>
                                          <Form.Control type="text" required
                                             fullWidth
                                             id="id"
                                             label="Identifiant"
                                             name="identifiant"
                                             autoComplete="identifiant"
                                             autoFocus
                                             onChange={(e) => setIdentifiant(e.target.value)} className="" aria-describedby="email" placeholder=" " />
                                       </Form.Group >
                                    </Col>
                                    <Col lg="12" className="">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="password" className="">Mot de passe</Form.Label>
                                          <Form.Control className="" required
                                             fullWidth
                                             name="password"
                                             label="Mot de passe"
                                             type="password"
                                             id="password"
                                             autoComplete="current-password"
                                             onChange={(e) => setPassword(e.target.value)} aria-describedby="password" placeholder=" " />
                                       </Form.Group>
                                    </Col>
                                    <Col lg="12" className="d-flex justify-content-between">
                                       <Box>
                                          <Link to="/">
                                             <Form.Check.Label role="button" className="text-underline" htmlFor="customCheck1"> <small>Je ne suis pas un administrateur d'entreprise</small> </Form.Check.Label>
                                          </Link>
                                       </Box>
                                    </Col>
                                    <Col lg="12" className="d-flex justify-content-end">
                                       <Link to="/auth/recoverpw_chief">Mot de passe oublié ?</Link>
                                    </Col>
                                 </Row>
                                 <div className="d-flex justify-content-center">
                                    <Button
                                       onClick={handleLoginAdmin}
                                       type="button"
                                       variant="btn btn-primary"
                                       disabled={isLoading} // Désactiver le bouton si isLoading est true
                                    >
                                       {isLoading ? 'Connexion en cours...' : 'Se connecter'}
                                    </Button>

                                 </div>
                                 {/* <p className="text-center my-3">or sign in with other accounts?</p> */}
                                 {/* <div className="d-flex justify-content-center">
                                    <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          <Link to="#"><Image src={facebook} alt="fb" /></Link>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          <Link to="#"><Image src={google} alt="gm" /></Link>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          <Link to="#"><Image src={instagram} alt="im" /></Link>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          <Link to="#"><Image src={linkedin} alt="li" /></Link>
                                       </ListGroup.Item>
                                    </ListGroup>
                                 </div> */}
                                 {/* <p className="mt-3 text-center">
                                    Don’t have an account? <Link to="/auth/sign-up" className="text-underline">Click here to sign up.</Link>
                                 </p> */}
                              </Form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
                  <div className="sign-bg">
                     <svg width="280" height="230" viewBox="0 0 431 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.05">
                           <rect x="-157.085" y="193.773" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 -157.085 193.773)" fill="#3B8AFF" />
                           <rect x="7.46875" y="358.327" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 7.46875 358.327)" fill="#3B8AFF" />
                           <rect x="61.9355" y="138.545" width="310.286" height="77.5714" rx="38.7857" transform="rotate(45 61.9355 138.545)" fill="#3B8AFF" />
                           <rect x="62.3154" y="-190.173" width="543" height="77.5714" rx="38.7857" transform="rotate(45 62.3154 -190.173)" fill="#3B8AFF" />
                        </g>
                     </svg>
                  </div>
               </Col>
               <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                  <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="images" />
               </Col>
            </Row>
         </section>
      </>
   )
}

export default SignInChief