import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Image, Form, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../../components/Card'
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from "@mui/lab/LoadingButton";

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Cookies from 'js-cookie';



// img
import auth2 from '../../../assets/images/auth/02.png'
const Recoverpw = () => {
   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
   let history = useNavigate()
   ///variables
   const send = useRef();
   const [isLoading, setIsLoading] = useState(false)
   const [email, setEmail] = useState('');
   const [fonction, setFonction] = useState(null)
   const [severity, setSeverity] = useState('');
   

   const defaultTheme = createTheme();
   //fin variable

   useEffect(() => {
      setFonction(Cookies.get('fonction'))
   }, []);
   //fonction d'envoi d'email de reinitialisation admin
   const handleSendAdminMail = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try {
         const response = await axios.post(`${apiBaseUrl}send-reset-admin-link`, { email });
         if (response.data.success == true) {
            send.current.textContent = ''
            setSeverity('success')
            send.current.appendChild(document.createTextNode(response.data.message));
         } else {
            send.current.textContent = ''
            setSeverity('error')
            send.current.appendChild(document.createTextNode(response.data.message));
         }
      } catch (error) {
         console.log(error)
      } finally {
         setIsLoading(false);
      }
   }
   //fin fonction d'envoi d'email de reinitialisation admin
   return (
      <>
         <section className="login-content">
            <Row className="m-0 align-items-center bg-white vh-100">
               <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                  <Image src={auth2} className="img-fluid gradient-main animated-scaleX" alt="images" />
               </Col>
               <Col md="6" className="p-0">
                  <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                     <Card.Body>
                        <Link to="/" className="navbar-brand d-flex justify-content-center align-items-center mb-1">
                           <img src="../logo.jpg" style={{ height: "90px", width: "90px", objectFit: "cover" }} />
                        </Link>
                        <h2 className="mb-2">Réinitialisation de mot de passe</h2>
                        <p>Entrez votre adresse e-mail et nous vous enverrons un e-mail contenant des instructions pour réinitialiser votre mot de passe.</p>
                        <Alert severity={severity} sx={{ mt: 3 }}  > <span ref={send}></span></Alert>
                        <Form>
                           <Row>
                              <Col lg="12" className="col-lg-12">
                                 <Form.Group className="floating-label">
                                    <Form.Label htmlFor="email" className="form-label">Email</Form.Label>
                                    <Form.Control type="email" className="form-control" id="email" name="email"
                                       autoComplete="email"
                                       autoFocus
                                       onChange={(e) => setEmail(e.target.value)} aria-describedby="email" placeholder=" " />
                                 </Form.Group>
                              </Col>
                           </Row>
                           <div className="d-flex justify-content-left mt-2">
                              <Button
                                 onClick={handleSendAdminMail}
                                 type="button"
                                 variant="btn btn-primary"
                                 disabled={isLoading} // Désactiver le bouton si isLoading est true
                              >
                                 {isLoading ? 'Envoi du lien en cours...' : 'Recevoir mon lien'}
                              </Button>

                           </div>
                        </Form>
                     </Card.Body>
                  </Card>
                  <div className="sign-bg sign-bg-right">
                     <svg width="280" height="230" viewBox="0 0 431 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.05">
                           <rect x="-157.085" y="193.773" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 -157.085 193.773)" fill="#3B8AFF" />
                           <rect x="7.46875" y="358.327" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 7.46875 358.327)" fill="#3B8AFF" />
                           <rect x="61.9355" y="138.545" width="310.286" height="77.5714" rx="38.7857" transform="rotate(45 61.9355 138.545)" fill="#3B8AFF" />
                           <rect x="62.3154" y="-190.173" width="543" height="77.5714" rx="38.7857" transform="rotate(45 62.3154 -190.173)" fill="#3B8AFF" />
                        </g>
                     </svg>
                  </div>
               </Col>
            </Row>
         </section>
      </>
   )
}

export default Recoverpw
