import {
  Box,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  Snackbar,
  Alert,
  MenuItem,
  Menu,
  alpha,
  styled,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { dataCalendar } from "../components/calendar";
import ReplyIcon from "@mui/icons-material/Reply";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import axios from "axios";
import Cookies from "js-cookie";
import loadingwait from "../components/animations/loading.json";
import Lottie from "lottie-web";
import * as dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import { ToastContainer, toast } from "react-toastify";

const Cotisation = () => {
  const { id } = useParams();
  dayjs.locale("fr");
  //cotisation
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [reloadData, setReloadData] = useState(false);
  const [entreprise, setEntreprise] = useState();
  const [cotisations, setCotisations] = useState();

  const [cotisation, setCotisation] = useState();
  const [month, setMonth] = useState("");
  const [sendData, setDataSend] = useState([]);
  const [versements, setVersements] = useState();

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const token = Cookies.get("authToken");

  function getMonthNameById(monthId) {
    return dayjs()
      .month(monthId - 1)
      .format("MMMM");
  }

  function handleClickOpen(month, dataCotisation) {
    setDataSend((prev) => [
      ...prev,
      {
        id: dataCotisation.id,
        month: dataCotisation.month,
      },
    ]);
    setMonth(month);
    setOpen(true);
  }

  function handleClickOpenM() {
    setDataSend([])
    setOpen2(true);
    
  }

  const handleClose2 = () => {
    setDataSend([]);
    setOpen2(false);
  };

  const handleCheckboxChange = (event, data) => {
    if (event.target.checked) {
      setDataSend((prev) => [
        ...prev,
        {
          id: data.id,
          month: data.month,
        },
      ]);
    } else {
      setDataSend((prev) => prev.filter((sendata) => sendata.id !== data.id));
    }
  };

  const handleClose = () => {
    setDataSend([]);
    setOpen(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  //données de l'entreprise
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/entreprises/show-cotisation/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data) {
          setCotisations(response.data.cotisations_mensuelles);
          setEntreprise(response.data.entreprise);
          setCotisation(response.data.entreprise.cotisation_mensuelle);
          setVersements(response.data.versements);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [reloadData]);

  //loading
  const loadingref = useRef();
  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  const handleAddCotisation = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/entreprises-cotisation/store/${id}`,
          {
            id_entreprise: id,
            montant: cotisation,
            datas: sendData,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success == true) {
          setReloadData((prev) => !prev);
          setSuccess(response.data.message);
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
          setOpen(false);
          setOpen2(false);
        } else {
          setSuccess();
          setError(response.data.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
  };

  const exportInfos = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/generate-company-cotisation-infos-pdf/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob", // Important pour télécharger le PDF correctement
      });

      // Créer une URL pour le blob
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));

      // Créer un lien et cliquer dessus pour télécharger le fichier
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `COTISATIONS DE L'ENTREPRISE ${entreprise.raison_sociale}.pdf`);
      document.body.appendChild(link);
      link.click();
      // Nettoyer l'URL de l'objet après le téléchargement
      window.URL.revokeObjectURL(url);
      toast.success("Exportation realisée avec succès!");
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la génération du PDF");
    }
  };

  const handleGeneratePDF = async () => {
    toast
      .promise(exportInfos(id), {
        pending: "Exportation en cours...",
      })
      .then(() => {})
      .catch((error) => {
        console.error("Erreur lors de l'exportation des informations", error);
      });
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 200,
      color: "rgb(55, 65, 81)",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[300],
      }),
    },
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedVersement, setSelectedVersement] = React.useState(null);
  const openDrop = Boolean(anchorEl);

  const handleClickDrop = (event, versement) => {
    setAnchorEl(event.currentTarget);
    setSelectedVersement(versement);
  };

  const handleCloseDrop = () => {
    setAnchorEl(null);
    setSelectedVersement(null);
  };

  const monnaie = entreprise && JSON.parse(entreprise.monnaie).code;

console.log(sendData);


  return (
    <>
      {(success || error) && (
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseSnack} action={action} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleCloseSnack} severity={success ? "success" : "error"} sx={{ width: "100%" }}>
            {success ? success : error}
          </Alert>
        </Snackbar>
      )}
      {entreprise && cotisations && cotisation ? (
        <>
          <Box className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center gap-3">
            <Box>
              {cotisation > 10000 ? "Abonnements" : "Cotisations"} de l'entreprise <span className="fw-bold">{entreprise.raison_sociale}</span> (exercice annuel{" "}
              {new Date().getFullYear()})
            </Box>
            <Box className="d-flex align-items-center justify-content-end gap-3">
                <Tooltip title="Verser pour plusieurs mois" onClick={() => handleClickOpenM()}>
                  <IconButton aria-label="settings">
                    <AutoAwesomeMotionIcon />
                  </IconButton>
                </Tooltip>
              <Tooltip title="Retourner à la liste">
                <Link to={"/dashboard/gestion-entreprise"}>
                  <IconButton aria-label="settings">
                    <ReplyIcon />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip title="Exporter au format pdf">
                <IconButton aria-label="print" onClick={handleGeneratePDF}>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box style={{ minHeight: "60vh" }} className="d-flex  align-items-center justify-content-center">
            <Grid container>
              {cotisations.map((data, index) => {
                const versement = versements.find((versement) => versement.mois == data.month);
                let mois_actuel = new Date().getMonth() + 1;

                return (
                  <Grid item xs={12} sm={6} md={4} xl={3} key={index} className="p-2 position-relative">
                    <div
                      className={`position-absolute ${
                        data.month < mois_actuel ? (versement ? "pulse-green" : "pulse-red") : mois_actuel == data.month && "pulse-yellow"
                      }`}
                      style={{
                        height: 15,
                        width: 15,
                        borderRadius: "50%",
                        backgroundColor: data.month < mois_actuel ? (versement ? "green" : "red") : mois_actuel == data.month && "yellow",
                        right: 15,
                        top: 15,
                      }}
                    ></div>
                    <Box className="bg-white shadow-sm h-100 p-3 ">
                      <Typography className="text-center fs-5 text-black my-3">{getMonthNameById(data.month)}</Typography>
                      <Grid container spacing={0.6}>
                        {versement ? (
                          <>
                            <Button
                              variant="outlined"
                              color="success"
                              className="w-100"
                              id="demo-customized-button"
                              aria-controls={openDrop ? "demo-customized-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={openDrop ? "true" : undefined}
                              disableElevation
                              onClick={(event) => handleClickDrop(event, versement)} // Passe le versement sélectionné
                            >
                              Voir les infos du versement
                            </Button>
                          </>
                        ) : (
                          <Button variant="contained" className="w-100" onClick={() => handleClickOpen(getMonthNameById(data.month), data)}>
                            Faire un versement
                          </Button>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}

              {/* Le menu est en dehors de la boucle */}
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={openDrop}
                onClose={handleCloseDrop}
              >
                {selectedVersement && (
                  <>
                    <MenuItem sx={{ fontSize: 14 }} disableRipple>
                      Initié par: <span className="fw-bold">{selectedVersement.user.lastname + " " + selectedVersement.user.firstname}</span>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem sx={{ fontSize: 14 }} disableRipple>
                      Montant: <span className="fw-bold">{selectedVersement.montant + " " + monnaie} </span>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem sx={{ fontSize: 14 }} disableRipple>
                      Date et heure de versement: <span className="fw-bold">{dayjs(selectedVersement.date_versement).format("DD/MM/YYYY HH:mm:ss")}</span>
                    </MenuItem>
                  </>
                )}
              </StyledMenu>

              {/* modal cotisation un seul mois*/}
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle className="text-md" id="alert-dialog-title">
                  {<>Versement pour le mois de {month}</>}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Box className="mt-5">
                      <FormControl fullWidth>
                        <NumericFormat
                          tabIndex={-1}
                          label="Cotisation"
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator=","
                          customInput={TextField}
                          value={entreprise && entreprise.cotisation_mensuelle}
                          allowNegative={false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{entreprise && JSON.parse(entreprise.monnaie).code}</InputAdornment>,
                          }}
                        />
                      </FormControl>
                    </Box>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Annuler</Button>
                  <LoadingButton onClick={handleAddCotisation} loading={loading} variant="outlined">
                    Confirmer
                  </LoadingButton>
                </DialogActions>
              </Dialog>

              {/* modal cotisation de plusieurs mois*/}
              <Dialog open={open2} onClose={handleClose2} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle className="text-md" id="alert-dialog-title">
                  {<>Versement pour plusieurs mois</>}
                </DialogTitle>
                <DialogContent>
                  <Box className="mt-5">
                    <FormGroup>
                      {cotisations.map((data) => {
                        const hasVersement = versements.some((versement) => versement.mois == data.month);
                        
                        if (!hasVersement) {
                          return (
                            <FormControlLabel
                              key={data.id}
                              value={data}
                              control={<Checkbox />}
                              label={getMonthNameById(data.month)}
                              onChange={(e) => handleCheckboxChange(e, data)}
                            />
                          );
                        }
                        // return null;
                      })}
                    </FormGroup>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose2}>Annuler</Button>
                  <LoadingButton disabled={sendData.length == 0 } onClick={handleAddCotisation} loading={loading} variant="outlined">
                    Confirmer
                  </LoadingButton>
                </DialogActions>
              </Dialog>

              {/* dropdown d'information de versement */}
            </Grid>
          </Box>
        </>
      ) : !entreprise ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
          <div style={{ maxHeight: "100px", maxWidth: "100px" }} ref={loadingref}></div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center gap-5" style={{ minHeight: "80vh" }}>
          <span>
            Cette entreprise n'a pas de cotisation mensuelle enregistrée , veuillez modifier les informations de l'entreprise et remplir cette information.
          </span>
          <Link to={`/dashboard/gestion-entreprise/edit/${id}`}>
            <Button variant="contained">Modifier entreprise</Button>
          </Link>
        </div>
      )}
      <ToastContainer autoClose={5000} hideProgressBar={true} pauseOnHover={false} />
    </>
  );
};

export default Cotisation;
