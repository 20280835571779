//router
// import IndexRouters from "./router/index"

//scss
import "./assets/scss/hope-ui.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/customizer.scss";
import "./assets/custom/css/style.css";
// Redux Selector / Action
import { useDispatch } from "react-redux";

// import state selectors
import { setSetting } from "./store/setting/actions";
import { useState } from "react";
import { EtatCompany } from "./store/setting/context";


const StatutEntrepriseProvider = ({ children }) => {
  const [etat, setEtat] = useState(1);
  return <EtatCompany.Provider value={{ etat, setEtat }}>{children}</EtatCompany.Provider>;
};

function App({ children }) {
  const dispatch = useDispatch();
  dispatch(setSetting());
  return (
    <div className="App">
      {/* <IndexRouters /> */}
      <StatutEntrepriseProvider>{children}</StatutEntrepriseProvider>
    </div>
  );
}

export default App;
