import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie"
import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card';
const Permissions = () => {
    const [permissions, setPermissions] = useState();

    useEffect(() => {
        const authToken = Cookies.get('authToken');

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/permissions`, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });

                const datas = response.data;
                setPermissions(datas.permissions);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    
    return (
        <Row>
            <Col sm="12">
                <Card>
                    <Card.Header className="d-flex justify-content-between flex-wrap">
                        <div className="header-title">
                            <h4 className="card-title mb-0">Permissions</h4>

                        </div>
                        
                    </Card.Header>
                    <Card.Body>
                        {
                            permissions ? (
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Titre</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            permissions.map((permission) => {
                                                return (
                                                    <tr>
                                                        <td >  {permission.name} </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            ):"Chargement..."
                        }
                    </Card.Body>
                </Card>

            </Col>
        </Row>
    );
}

export default Permissions;
