import { Box, CardHeader, IconButton, Tooltip, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Lottie from 'lottie-web';
import loadingwait from "../components/animations/loading.json"
import Cookies from 'js-cookie'
import axios from 'axios';
import ReplyIcon from '@mui/icons-material/Reply';
import { formatNumber } from '../../../utilities/numberFormat';
import { ToastContainer, toast } from 'react-toastify';
import Icon from '@mdi/react';
import { mdiFilePdfBox,mdiMicrosoftExcel } from '@mdi/js';
const DetailsYear = () => {
    const { id, month } = useParams()
    const loadingref = useRef()
    const token = Cookies.get('authToken')

    const [year, setYear] = useState()
    const [entreprise, setEntreprise] = useState()
    const [datas, setDatas] = useState()


    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: loadingref.current,
            animationData: loadingwait,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        return () => {
            animation.destroy();
        };
    }, []);

    //recuperation des infos de  changement du compte résultat
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/cr/${id}/details_year/${month}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setYear(response.data.year)
                setEntreprise(response.data.entreprise)
                setDatas(response.data.datas)

            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const months = [
        { "id": "1", "label": "Janvier" },
        { "id": "2", "label": "Février" },
        { "id": "3", "label": "Mars" },
        { "id": "4", "label": "Avril" },
        { "id": "5", "label": "Mai" },
        { "id": "6", "label": "Juin" },
        { "id": "7", "label": "Juillet" },
        { "id": "8", "label": "Août" },
        { "id": "9", "label": "Septembre" },
        { "id": "10", "label": "Octobre" },
        { "id": "11", "label": "Novembre" },
        { "id": "12", "label": "Décembre" }
    ];

    const handleGeneratePDF = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/generate-annual-bilan-pdf/${id}/${year}`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob', // Important pour télécharger le PDF correctement
            });
    
            // Créer une URL pour le blob
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    
            // Créer un lien et cliquer dessus pour télécharger le fichier
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Exercice au 31 Décembre ${year ? year : '...'}_${entreprise ? entreprise.raison_sociale : '...'}.pdf`);
            document.body.appendChild(link);
            link.click();
    
            // Nettoyer l'URL de l'objet après le téléchargement
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erreur lors de la génération du PDF:', error);
            toast.error('Erreur lors de la génération du PDF');
        }
    };

    const handleGenerateExcel = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/generate-annual-bilan-excel/${id}/${year}`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob', // Important pour télécharger le fichier correctement
            });
    
            // Créer une URL pour le blob
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    
            // Créer un lien et cliquer dessus pour télécharger le fichier
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Exercice au 31 Décembre ${year ? year : '...'}_${entreprise ? entreprise.raison_sociale : '...'}.xlsx`);
            document.body.appendChild(link);
            link.click();
    
            // Nettoyer l'URL de l'objet après le téléchargement
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erreur lors de la génération de l\'Excel:', error);
            toast.error('Erreur lors de la génération de l\'Excel');
        }
    };
    


    
    return (
        <>
        <ToastContainer
            autoClose={5000}
            hideProgressBar={true}
            pauseOnHover={false}
        />
        {(year && entreprise && datas) ? (
            <Box style={{ height: "100vh" }}>
                <CardHeader
                    subheader={
                        <div>
                            <span className='h5'>Exercice au 31 Décembre {year ? year : '...'}</span><br />
                            <Box className="d-flex gap-2 align-items-center">
                                <img src={`${process.env.REACT_APP_BASE_URL}${entreprise.logo}`} style={{ width: "50px", height: "50px", objectFit: "cover", aspectRatio: 1 }} />
                                <Box>
                                    <span>{entreprise ? entreprise.raison_sociale : '...'}</span>&nbsp;
                                    <span>(Devise: {entreprise ? JSON.parse(entreprise.monnaie).currency + ', ' + JSON.parse(entreprise.monnaie).code : '...'})</span>
                                </Box>
                            </Box>
                        </div>
                    }
                    action={
                        <div className='d-flex align-items-center'>
                            <Button onClick={handleGeneratePDF} style={{
                                marginLeft: '1em',
                                backgroundColor: '#f44336',
                                color: '#fff',
                                padding: '0.5em 1em',
                                borderRadius: '4px',
                                fontSize: '25px',
                                fontWeight: '500',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#000000'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f44336'}>
                            <Icon path={mdiFilePdfBox} size={1} />
                            </Button>
                            <Button onClick={handleGenerateExcel} style={{
                                marginLeft: '1em',
                                backgroundColor: '#388E3C',
                                color: '#fff',
                                padding: '0.5em 1em',
                                borderRadius: '4px',
                                fontSize: '25px',
                                fontWeight: '500',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#000000'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#388E3C'}>
                            <Icon path={mdiMicrosoftExcel} size={1} />
                            </Button>

                            <Tooltip title="Retourner à la selection">
                                <Link to={`/dashboard/details-enregistrement/compte/${id}`}>
                                    <IconButton aria-label="settings">
                                        <ReplyIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </div>
                    }
                    sx={{ height: "15%" }}
                />
                <div class="table-wrapper" >
                    <table>
                        <thead>
                            <tr class="sticky">
                                <th style={{ backgroundColor: "#4ab8b3" }}>REF</th>
                                <th class="sticky" style={{ backgroundColor: "#4ab8b3" }}>Libéllés</th>
                                {
                                    months.map((month, index) => (
                                        <th key={index} >{month.label}</th>
                                    ))
                                }
                                <th>Exercice au 31/12/{year} </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                datas.map((data) => {
                                    return (
                                        <tr>
                                            <td class="sticky">{data.ref}</td>
                                            <td class="sticky">{data.libelle}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_janvier)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_fevrier)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_mars)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_avril)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_mai)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_juin)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_juillet)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_aout)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_septembre)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_octobre)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_novembre)}</td>
                                            <td className="text-center">{formatNumber(data.valeurs_mois.valeur_decembre)}</td>
                                            <td className="text-center">{formatNumber(data.valeur_totale)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Box >

        ) : (
            <Box sx={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                <div ref={loadingref} style={{ height: 85, width: 85 }} >
                </div>
            </Box>
        )}
         </>
    );
}

export default DetailsYear;
