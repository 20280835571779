import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'
import { useState, useEffect, useRef, Fragment, createRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert, Autocomplete, Avatar, Badge, Box, Button, CardHeader, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, MenuItem, Paper, Snackbar, Stack, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import { countries, diplomes, nationalites, naturesPiece, niveauxEtude } from '../components/liste';
import Lottie from 'lottie-web';
import loadingwait from "../components/animations/loading.json"
import sendingCertif from "../components/animations/loaderSwitch.json"
import Dropzone from 'react-dropzone';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { NumericFormat } from 'react-number-format';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
const ModifAgent = () => {
    const { id } = useParams()
    const token = Cookies.get('authToken');
    const [user, setUser] = useState();
    const [loading, setLoading] = useState();
    const [certifLoading, setCertifLoading] = useState(true);
    const [certifLoadingSend, setCertifLoadingSend] = useState(false);

    const getFileExtension = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    const [success, setSuccess] = useState();
    const [errors, setErrors] = useState();
    const [errorse, setErrorse] = useState();

    const [reloadData, setReloadData] = useState();

    dayjs.locale('fr');
    const [openDialog, setOpenDialog] = useState(false);
    const [users, setUsers] = useState();
    const [certifications, setCertifications] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [cardPerPage, setCardPerPage] = useState(10);
    const [isEmpty, setIsEmpty] = useState(false);
    const [switchRefs, setSwitchRef] = useState([]);
    const [findIndex, setFindIndex] = useState();
    const [roles, setRoles] = useState();
    const [domaines, setDomaines] = useState();
    const [sliderLoading, setSliderLoader] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    const [idcertif, setIdcertif] = useState()

    ///refs
    const res = useRef();
    const resc = useRef();

    const loadingref = useRef()
    const containerRef = useRef()

    const reffirstname = useRef()
    const reflastname = useRef()
    const refemail = useRef()
    const refphone = useRef()
    const refpays = useRef()
    const refville = useRef()
    const refcommune = useRef()
    const refrue = useRef()
    const refnumPiece = useRef()
    const refnaturePiece = useRef()
    const refpiece_recto = useRef()
    const refpiece_verso = useRef()
    const refnationalite = useRef()
    const refbest_study = useRef()
    const refbest_diplome = useRef()
    const refspecialite = useRef()
    const refadhesion_date = useRef()
    const refformation_date = useRef()
    const refrecrut_date = useRef()
    const refniveauFormation = useRef()
    const refrole_id = useRef()
    const refgenre = useRef()
    const refbirthday = useRef()
    const refcertification = useRef()
    const refsolde = useRef()

    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [email, setEmail] = useState();
    const [indicator, setIndicator] = useState();
    const [phone, setPhone] = useState();
    const [pays, setPays] = useState();
    const [ville, setVille] = useState();
    const [commune, setCommune] = useState();
    const [rue, setRue] = useState();
    const [numPiece, setNumPiece] = useState();
    const [naturePiece, setNaturePiece] = useState();
    const [certification, setCertification] = useState();
    ///
    const [certificationadd, setCertificationAdd] = useState();
    const [soldeadd, setSoldeAdd] = useState();
    ///
    const [accomplissements, setAccomplissement] = useState();
    const [solde, setSolde] = useState();
    const [profil, setProfil] = useState();
    const [objectif, setObjectif] = useState()
    const [profilPreview, setProfilPreview] = useState()
    const [piece_recto, setPieceRecto] = useState();
    const [logoPreviewRecto, setLogoPreviewRecto] = useState()
    const [piece_verso, setPieceVerso] = useState();
    const [logoPreviewVerso, setLogoPreviewVerso] = useState()
    const [nationalite, setNationalite] = useState();
    const [best_study, setBestStudy] = useState();
    const [best_diplome, setBestDiplome] = useState();
    const [adhesion_date, setAdhesionDate] = useState();
    const [formation_date, setFormationDate] = useState();
    const [recrut_date, setRecrutDate] = useState();
    const [role_id, setRole] = useState();
    const [genre, setGenre] = useState();
    const [birthday, setBirthday] = useState();

    const [isACE, setTabValue] = useState();
    const [open, setOpen] = useState(false);
    const [isjobseeker, setJobstatut] = useState();
    const [job, setJob] = useState();
    const [prevjob, setPrevJob] = useState();

    const [currentRaison, setCurrentraison] = useState();
    const [currentAdress, setCurrentAdress] = useState();
    const [currentPhone, setCurrentPhone] = useState();
    const [currentEmail, setCurrentEmail] = useState();
    const [currentFonction, setCurrentFonction] = useState();


    const [lastRaison, setLastraison] = useState();
    const [LastAdress, setLastAdress] = useState();
    const [lastPhone, setLastPhone] = useState();
    const [lastEmail, setLastEmail] = useState();
    const [lastFonction, setLastFonction] = useState();


    const focusOnError = (errors, refs) => {
        for (const field in errors) {
            if (errors[field] && refs[field] && refs[field].current) {
                refs[field].current.focus({ behavior: 'smooth' });
                break;
            }
        }
    };

    const handleDropProfil = (acceptedFiles) => {
        setProfil(acceptedFiles);
        const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
        setProfilPreview(logoPreviewURL);
    };
    const handleRemoveProfil = () => {
        setProfilPreview();
        setProfil(user.image);
    };

    const handleDropRecto = (acceptedFiles) => {
        setPieceRecto(acceptedFiles);
        const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
        setLogoPreviewRecto(logoPreviewURL);
    };
    const handleRemoveRecto = () => {
        setLogoPreviewRecto();
        setPieceRecto(user.piece_recto);
    };
    const handleDropVerso = (acceptedFiles) => {
        setPieceVerso(acceptedFiles);
        const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
        setLogoPreviewVerso(logoPreviewURL);
    };

    const handleRemoveVerso = () => {
        setLogoPreviewVerso();
        setPieceVerso(user.piece_verso);
    };


    const handleSoldeChange = (value) => {
        let cout_certification = certification && certification.cout;
        let solded = (value !== 'undefined' && value.floatValue !== 'undefined') && value.floatValue

        if (solded > cout_certification) {
            console.log("trop grand");
        } else {
            console.log("acceptable");
        }
        // setSolde(sol)

    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    console.log(piece_recto)

    useEffect(() => {

        const fetchData = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/users/edit/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUser(response.data.user);
                setRoles(response.data.roles)
                setDomaines(response.data.domaines)
                const switchRefs = response.data.accomplissements && response.data.accomplissements.map(() => createRef())
                setSwitchRef(switchRefs)
                if (response.data.user) {
                    setFirstname(response.data.user.firstname)
                    setLastname(response.data.user.lastname)
                    setEmail(response.data.user.email)
                    setIndicator(response.data.user.phone.split(' ')[0])
                    setPhone(response.data.user.phone.split(' ')[1])
                    setPays(response.data.user.pays && JSON.parse(response.data.user.pays))
                    setVille(response.data.user.ville)
                    setCommune(response.data.user.commune)
                    setRue(response.data.user.rue)
                    setNumPiece(response.data.user.num_piece)
                    setNaturePiece(response.data.user.nature_piece)
                    setNationalite(response.data.user.nationalite)
                    setBestStudy(response.data.user.best_study)
                    setBestDiplome(response.data.user.best_diplome)
                    setAdhesionDate(response.data.user.adhesion_date && dayjs(response.data.user.adhesion_date))
                    setFormationDate(response.data.user.formation_date && dayjs(response.data.user.formation_date))
                    setRecrutDate(response.data.user.recrut_date && dayjs(response.data.user.recrut_date))
                    setRole(response.data.user.role)
                    setGenre(response.data.user.gender)
                    setBirthday(response.data.user.birthday && dayjs(response.data.user.birthday))
                    setCertifications(response.data.certifications)
                    setAccomplissement(response.data.accomplissements)
                    setProfil(response.data.user.image);
                    setPieceRecto(response.data.user.piece_recto);
                    setPieceVerso(response.data.user.piece_verso);
                    setObjectif(response.data.user.domaine && response.data.user.domaine.id)
                    setTabValue(response.data.user.isAce == 1 ? 1 : response.data.user.isAdmin == 1 ? 0 : response.data.user.isAgent == 1 ? 2 : response.data.user.isAnother == 1 ? 3 : 5)
                    setJobstatut(response.data.user.is_jobseeker)
                    setJob(response.data.user.is_job)
                    setPrevJob(response.data.user.asprevjob)
                    setCurrentraison(response.data.user.current_company_name)
                    setCurrentAdress(response.data.user.current_company_adress)
                    setCurrentPhone(response.data.user.current_company_phone)
                    setCurrentEmail(response.data.user.current_company_email)
                    setCurrentFonction(response.data.user.current_fonction)
                    setLastraison(response.data.user.old_company_name)
                    setLastAdress(response.data.user.old_company_adress)
                    setLastPhone(response.data.user.old_company_phone)
                    setLastEmail(response.data.user.old_company_email)
                    setLastFonction(response.data.user.old_fonction)
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [reloadData]);

    const handleChangeTabValue = (event, newValue) => {
        let confirmation = window.confirm('Attention si vous valider ce changement, certaines informations risquent d\'etre perdues.')
        if (!confirmation) {
            return;
        }
        setTabValue(newValue)
    };

    const reset = () => {
        setReloadData(prev => !prev)
        setErrors()
    }

    const handleUpdateUser = async (e, id) => {
        e.preventDefault()
        setLoading(true);
        if (isACE !== 1) {
            setObjectif()
            setCertification()
            setSolde()
        }
        try {

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}admin/users/update/${id}`,
                {
                    firstname,
                    lastname,
                    email,
                    phone: `${indicator ? `${indicator}` : ""} ${phone}`,
                    identifiant: phone,
                    pays,
                    ville,
                    commune,
                    rue,
                    numPiece,
                    naturePiece,
                    certification: certification && certification,
                    solde,
                    profil,
                    objectif,
                    profilPreview,
                    piece_recto,
                    logoPreviewRecto,
                    piece_verso,
                    logoPreviewVerso,
                    nationalite,
                    best_study,
                    best_diplome,
                    adhesion_date,
                    formation_date,
                    recrut_date,
                    role_id: role_id && role_id.id,
                    genre,
                    birthday,
                    fonction: 2,
                    currentRaison,
                    currentAdress,
                    currentPhone,
                    currentEmail,
                    currentFonction,
                    lastRaison,
                    LastAdress,
                    lastPhone,
                    lastEmail,
                    lastFonction,
                    isjobseeker,
                    job,
                    prevjob,
                    _method: "PUT"
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'

                    },
                }
            );

            if (response.data.success == true) {
                setReloadData(prev => !prev);
                setErrors()
                setSuccess(response.data.message)
                setTimeout(() => {
                    setOpenSnack(true)
                }, 50);
                setLogoPreviewRecto()
                setLogoPreviewVerso()
                setProfilPreview()
                res.current.reset()
            } else {
                setSuccess()
                focusOnError(response.data.errors, {
                    firstname: reffirstname,
                    lastname: reflastname,
                    email: refemail,
                    phone: refphone,
                    pays: refpays,
                    nationalite: refnationalite,
                    ville: refville,
                    commune: refcommune,
                    rue: refrue,
                    num_piece: refnumPiece,
                    naturePiece: refnaturePiece,
                    piece_recto: refpiece_recto,
                    piece_verso: refpiece_verso,
                    nationalite: refnationalite,
                    best_study: refbest_study,
                    best_diplome: refbest_diplome,
                    specialite: refspecialite,
                    adhesion_date: refadhesion_date,
                    formation_date: refformation_date,
                    recrut_date: refrecrut_date,
                    niveauFormation: refniveauFormation,
                    role_id: refrole_id,
                    genre: refgenre,
                    birthday: refbirthday,
                    solde: refsolde
                });
                setErrors(response.data.errors)
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (certifLoadingSend && findIndex !== undefined && switchRefs.length > 0 && switchRefs[findIndex] && switchRefs[findIndex].current) {
            const animation = Lottie.loadAnimation({
                container: switchRefs[findIndex].current,
                animationData: sendingCertif,
                renderer: 'svg',
                loop: true,
                autoplay: true,
            });

            return () => {
                animation.destroy();
            };
        }
    }, [certifLoadingSend]);

    const handleUpdateCertif = async (id) => {
        setCertifLoadingSend(true)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}admin/users/update/certification`,
                {
                    user: certification && certification.pivot && certification.pivot.user_id,
                    certification: certification && certification.pivot && certification.pivot.certification_id,
                    solde: solde
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                }
            );
            if (response.data.success == true) {
                setReloadData(prev => !prev);
                setErrors()
                setSuccess(response.data.message)
                setTimeout(() => {
                    setOpenSnack(true)
                }, 50);
                setFindIndex()
            } else {
                setSuccess()
                let errorMessages;
                if (typeof response.data.errors === 'string') {
                    errorMessages = response.data.errors;
                } else if (typeof response.data.errors === 'object' && response.data.errors !== null) {
                    errorMessages = Object.values(response.data.errors).join(', ') || "Erreur inattendue";
                } else {
                    errorMessages = "Erreur inattendue";
                }
                setErrors(errorMessages)
                setOpenSnack(true)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setCertifLoadingSend(false)
        }
    }
    const deleteCertif = async (certification) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}admin/users/delete/certification`,
                {
                    user: certification && certification.pivot && certification.pivot.user_id,
                    certification: certification && certification.pivot && certification.pivot.certification_id,
                }
                , {
                    headers: { Authorization: `Bearer ${token}` },
                });
        } catch (error) {
            console.log(error)
        }
    };
    const handleDeleteCertification = (certification) => {
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cette certification ?');

        if (!confirmDelete) {
            return;
        }
        toast.promise(
            deleteCertif(certification),
            {
                pending: 'Suppression en cours...',
                success: "Suppression realisée avec succès!",
                error: "Il y'a eu une erreur lors de la suppression"
            }
        ).then(() => {
            setReloadData(prev => !prev)
        }).catch((error) => {
            console.error('Erreur lors de la suppression de l\'objectif', error);
        });
    }
    const handlePrepare = (index, solde, certification) => {
        setFindIndex(index);
        setCertifLoading(false);
        setSolde(solde)
        setCertification(certification)
    }
    const handlePrepareClose = () => {
        setReloadData(prev => !prev)
        setFindIndex();
        setCertifLoading(true);
        setSolde()
        setCertification()
    }
    const handleAddCertification = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}admin/certification/store/individual`,
                {
                    id,
                    solde: soldeadd,
                    certification: certificationadd && certificationadd.id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            if (response.data.success == true) {
                setOpen(false)
                setReloadData(prev => !prev);
                setErrorse()
                setSuccess(response.data.message)
                setTimeout(() => {
                    setOpenSnack(true)
                }, 50);
                resc.current.reset()
                setCertificationAdd()
                setSoldeAdd()
            } else {
                let errorMessages;
                if (typeof response.data.errors === 'string') {
                    errorMessages = response.data.errors;
                } else if (typeof response.data.errors === 'object' && response.data.errors !== null) {
                    errorMessages = Object.values(response.data.errors).join(', ') || "Erreur inattendue";
                } else {
                    errorMessages = "Erreur inattendue";
                }
                setSuccess()
                focusOnError(response.data.errors, {
                    certification: refcertification,
                    solde: refsolde
                });
                setErrorse(errorMessages)
                setOpenSnack(true)
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: loadingref.current,
            animationData: loadingwait,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        return () => {
            animation.destroy();
        };
    }, []);

    const action = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnack}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );
    const handleClickOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        setCertificationAdd()
        setSoldeAdd()
        setErrorse()
    };

    console.log(errors)
    return (
        <div style={{ marginTop: "5em" }}>
            {
                (success || errors || errorse) && (
                    <Snackbar
                        open={openSnack}
                        autoHideDuration={success ? 5000 : 1000000}
                        onClose={handleCloseSnack}
                        action={action}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseSnack} severity={success ? 'success' : 'error'} sx={{ width: '100%' }}>
                            {success ? success : errors ? errors : errorse && errorse}
                        </Alert>
                    </Snackbar>
                )
            }
            {
                user ? (
                    <motion.div
                        className="mb-3"
                        initial={{}}
                        animate={{}}
                    >
                        <Paper elevation={1} className='p-3'>
                            <CardHeader
                                subheader={`Modification des informations de l'AGENT DE NOTATION ${user ? `${user.firstname} ${user.lastname}` : ""}`}
                            />
                            <Grid container >
                                <Grid item xs={12} className='d-flex justify-content-end align-items-center '>
                                    {/* {
                                        isACE == 1 && ( */}
                                            <Box >
                                                <Box className="d-flex gap-2">
                                                    <Button onClick={handleClickOpen} sx={{ fontSize: ".75em" }} variant="outlined" startIcon={<AddIcon sx={{ fontSize: ".75em" }} />}>
                                                        Ajouter une certification
                                                    </Button>
                                                </Box>
                                                <Dialog
                                                    fullWidth
                                                    maxWidth="md"
                                                    open={open}
                                                    onClose={(event, reason) => {
                                                        if (reason != "backdropClick") {
                                                            onClose();
                                                        }
                                                    }}
                                                    disableEscapeKeyDown
                                                >
                                                    <DialogTitle>Ajout de certification</DialogTitle>
                                                    <DialogContent>
                                                        <form ref={resc} className='container-fluid mt-3'>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth>
                                                                        <Autocomplete
                                                                            tabIndex={-1}
                                                                            id="country-select-demo2"
                                                                            onChange={(event, newValue) => {
                                                                                setCertificationAdd(newValue && newValue);
                                                                            }}
                                                                            value={certificationadd}
                                                                            options={certifications ? certifications : []}
                                                                            autoHighlight
                                                                            fullWidth
                                                                            getOptionLabel={(option) => option ? `${option.programme}-${option.niveau}-${option.domaine}-${option.cout}${JSON.parse(option.devise).currency}` : ''}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Choisir la formation suivie"

                                                                                    inputProps={{
                                                                                        ...params.inputProps,
                                                                                        autoComplete: 'new-password',
                                                                                    }}
                                                                                    ref={refcertification}
                                                                                    error={errorse && errorse.certification}
                                                                                    helperText={
                                                                                        errorse && errorse.certification && errorse.certification.join(', ')
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth>
                                                                        <NumericFormat
                                                                            tabIndex={-1}
                                                                            label="Montant payé"
                                                                            ref={refsolde}
                                                                            error={errorse && errorse.solde}
                                                                            helperText={errorse && errorse.solde && errorse.solde.join(', ')}
                                                                            fixedDecimalScale
                                                                            allowLeadingZeros
                                                                            thousandSeparator=","
                                                                            customInput={TextField}
                                                                            value={soldeadd}
                                                                            onValueChange={(value) => (value !== 'undefined' && value.floatValue !== 'undefined') && setSoldeAdd(value.floatValue)}
                                                                            allowNegative={false}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="end">{(certificationadd && certificationadd.devise) ? JSON.parse(certificationadd.devise).code : ""}</InputAdornment>,
                                                                            }}
                                                                        />

                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </form>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Stack direction="row" spacing={2}>
                                                            <LoadingButton loading={loading} variant="outlined" onClick={handleAddCertification} >
                                                                Enregistrer
                                                            </LoadingButton>
                                                            {
                                                                !loading && (
                                                                    <Button type='reset' variant="outlined" onClick={onClose}>
                                                                        Annuler
                                                                    </Button>
                                                                )
                                                            }
                                                        </Stack>
                                                    </DialogActions>
                                                </Dialog>
                                            </Box>
                                        {/* )

                                    } */}
                                    <Tooltip title="Retourner à la liste">
                                        <Link to={`/dashboard/agents`}>
                                            <IconButton aria-label="settings">
                                                <ReplyIcon />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                            <Divider />
                            <br />
                            <Box className='mt-3'>

                                <form ref={res} onSubmit={(e) => handleUpdateUser(e, id)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className='my-1 d-flex justify-content-center align-items-center'>
                                            <Dropzone  onDrop={handleDropProfil}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section className="mb-2">
                                                        <div {...getRootProps()}>
                                                            {!profilPreview && <input {...getInputProps()} />}
                                                            <Box>
                                                                <Badge
                                                                    role="button"
                                                                    overlap="circular"
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                    sx={{
                                                                        borderRadius: "50%",
                                                                        boxShadow: "22px 22px 58px #d0d0d0,-22px -22px 58px #f0f0f0"
                                                                    }}
                                                                    className='d-flex justify-content-center align-items-center'
                                                                    badgeContent={
                                                                        <>
                                                                            {
                                                                                !profilPreview ? (
                                                                                    <IconButton aria-label="share"
                                                                                        sx={{
                                                                                            backgroundColor: "white",
                                                                                            borderRadius: "50%",
                                                                                            boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                                            '&:hover': {
                                                                                                backgroundColor: "white"
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                ) : (
                                                                                    <IconButton aria-label="share"
                                                                                        onClick={handleRemoveProfil}
                                                                                        sx={{
                                                                                            backgroundColor: "white",
                                                                                            borderRadius: "50%",
                                                                                            boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                                            '&:hover': {
                                                                                                backgroundColor: "white"
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <DeleteSweepIcon />
                                                                                    </IconButton>
                                                                                )

                                                                            }


                                                                        </>
                                                                    }
                                                                >
                                                                    <div className='p-1 '>
                                                                        <Avatar
                                                                            alt="Logo"
                                                                            src={profilPreview ? profilPreview : `${process.env.REACT_APP_BASE_URL}${profil}`}
                                                                            role='button'
                                                                            loading="lazy"
                                                                            sx={{ width: 120, height: 120 }}
                                                                        />
                                                                    </div>
                                                                </Badge>


                                                            </Box>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {

                                                isACE == 3 && (

                                                    <Stack spacing={4} direction="row" className='d-flex align-items-center'>
                                                        <Box>Demandeur d'emploi ?</Box>
                                                        <Box>
                                                            <Stack spacing={4} direction="row">
                                                                <div role="button"><Chip onClick={() => setJobstatut(1)} className='rounded-0' label="Oui" color={`${isjobseeker == 1 ? "success" : "default"}`} /></div>
                                                                <div role="button"><Chip onClick={() => setJobstatut(0)} className='rounded-0' label="Non" color={`${isjobseeker == 0 ? "success" : "default"}`} /></div>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>

                                                )

                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            {

                                                isACE == 3 && (

                                                    <Stack spacing={4} direction="row" className='d-flex align-items-center'>
                                                        <Box>Statut professionnel </Box>
                                                        <Box>
                                                            <Stack spacing={4} direction="row">
                                                                <div role="button"><Chip onClick={() => setJob(0)} className='rounded-0' label="Sans emploi" color={`${job == 0 ? "success" : "default"}`} /></div>
                                                                <div role="button"><Chip onClick={() => setJob(1)} className='rounded-0' label="Travaille dans une entreprise" color={`${job == 1 ? "success" : "default"}`} /></div>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>

                                                )

                                            }
                                        </Grid>
                                        <Grid item xs={12} className=''>
                                            {
                                                isACE == 3 && (
                                                    job == 1 ? (

                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={4}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        tabIndex={-1}

                                                                        fullWidth
                                                                        value={currentRaison}
                                                                        label="Raison sociale de l'entreprise"
                                                                        onChange={(e) => setCurrentraison(e.target.value)}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />

                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        tabIndex={-1}
                                                                        fullWidth
                                                                        value={currentAdress}
                                                                        label="Adresse de l'entreprise"
                                                                        onChange={(e) => setCurrentAdress(e.target.value)}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />

                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        tabIndex={-1}

                                                                        fullWidth
                                                                        value={currentPhone}
                                                                        label="Téléphone de l'entreprise"
                                                                        onChange={(e) => setCurrentPhone(e.target.value)}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />

                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        tabIndex={-1}

                                                                        fullWidth
                                                                        value={currentEmail}
                                                                        label="Email de l'entreprise"
                                                                        onChange={(e) => setCurrentEmail(e.target.value)}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />

                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        tabIndex={-1}

                                                                        fullWidth
                                                                        value={currentFonction}
                                                                        label="Poste occupé"
                                                                        onChange={(e) => setCurrentFonction(e.target.value)}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />

                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>

                                                    ) : (
                                                        <Stack spacing={4} direction="row" className='d-flex align-items-center'>
                                                            <Box>Déja travaillé dans une entreprise ? </Box>
                                                            <Box>
                                                                <Stack spacing={4} direction="row">
                                                                    <div role="button"><Chip onClick={() => setPrevJob(1)} className='rounded-0' label="Oui" color={`${prevjob == 1 ? "success" : "default"}`} /></div>
                                                                    <div role="button"><Chip onClick={() => setPrevJob(0)} className='rounded-0' label="Non" color={`${prevjob == 0 ? "success" : "default"}`} /></div>
                                                                </Stack>
                                                            </Box>
                                                        </Stack>
                                                    )
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                job == 0 && (
                                                    isACE == 3 && (
                                                        prevjob == 1 ? (

                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={4}>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            tabIndex={-1}
                                                                            ref={reffirstname}

                                                                            fullWidth
                                                                            value={lastRaison}
                                                                            label="Raison sociale de la dernière entreprise"
                                                                            onChange={(e) => setLastraison(e.target.value)}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />

                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            tabIndex={-1}

                                                                            fullWidth
                                                                            value={LastAdress}
                                                                            label="Adresse de la dernière entreprise"
                                                                            onChange={(e) => setLastAdress(e.target.value)}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />

                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            tabIndex={-1}

                                                                            fullWidth
                                                                            value={lastPhone}
                                                                            label="Téléphone de la dernière entreprise"
                                                                            onChange={(e) => setLastPhone(e.target.value)}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />

                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            tabIndex={-1}
                                                                            ref={reffirstname}

                                                                            fullWidth
                                                                            value={lastEmail}
                                                                            label="Email de la dernière entreprise"
                                                                            onChange={(e) => setLastEmail(e.target.value)}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />

                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            tabIndex={-1}
                                                                            fullWidth
                                                                            value={lastFonction}
                                                                            label="Poste occupé dans la dernière entreprise"
                                                                            onChange={(e) => setLastFonction(e.target.value)}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />

                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>

                                                        ) : (
                                                            ""
                                                        )
                                                    )
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {
                                                isACE != 3 && (
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            tabIndex={-1}
                                                            id="country-select-demo2"
                                                            onChange={(event, newValue) => {
                                                                setRole(newValue && newValue);
                                                            }}
                                                            ref={refrole_id}
                                                            value={role_id ? role_id : ""}
                                                            options={roles ? roles : []}
                                                            autoHighlight
                                                            fullWidth
                                                            getOptionLabel={(option) => option ? option.title : ''}
                                                            isOptionEqualToValue={(option) => option.title == role_id ? role_id.title : ""}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Choisir la fonction de l'AGENT DE NOTATION"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: 'new-password',
                                                                    }}

                                                                    error={errors && errors.role_id}
                                                                    helperText={
                                                                        errors && errors.role_id && errors.role_id.join(', ')
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {
                                                (isACE == 1) && (
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            tabIndex={-1}
                                                            ref={refspecialite}
                                                            select
                                                            error={errors && errors.specialite}
                                                            helperText={
                                                                errors && errors.specialite && errors.specialite.join(', ')
                                                            }
                                                            fullWidth
                                                            value={objectif}
                                                            label="Information d'objectif"
                                                            onChange={(e) => setObjectif(e.target.value)}
                                                        >
                                                            <MenuItem value="">...</MenuItem>
                                                            {
                                                                domaines.map((domaine) => {
                                                                    return (
                                                                        <MenuItem value={domaine.id}>{`${domaine.designation} - niveau ${domaine.niveau_objectif} - ${domaine.cout_formation}${domaine.devise_paiement}`}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </TextField>
                                                    </FormControl>
                                                )
                                            }
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={reffirstname}
                                                    error={errors && errors.firstname}
                                                    helperText={
                                                        errors && errors.firstname && errors.firstname.join(', ')
                                                    }
                                                    fullWidth
                                                    value={firstname}
                                                    label="Nom"
                                                    onChange={(e) => setFirstname(e.target.value)}
                                                />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                tabIndex={-1}
                                                ref={reflastname}
                                                error={errors && errors.lastname}
                                                helperText={
                                                    errors && errors.lastname && errors.lastname.join(', ')
                                                }
                                                fullWidth
                                                value={lastname}
                                                label="Prénoms"
                                                onChange={(e) => setLastname(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refgenre}
                                                    select
                                                    error={errors && errors.genre}
                                                    helperText={
                                                        errors && errors.genre && errors.genre.join(', ')
                                                    }
                                                    fullWidth
                                                    value={genre}
                                                    label="Genre"
                                                    onChange={(e) => setGenre(e.target.value)}
                                                >
                                                    <MenuItem value="homme">Homme</MenuItem>
                                                    <MenuItem value="femme">Femme</MenuItem>
                                                    <MenuItem value="n/a">Non defini</MenuItem>
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="country-select-demo"
                                                    value={pays}
                                                    onChange={(event, newValue) => {
                                                        setPays(newValue ? newValue : null);
                                                    }}

                                                    fullWidth

                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.label}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                alt=""
                                                            />
                                                            {option.label} ({option.code}) +{option.phone}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choisir le pays"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                            }}
                                                            ref={refpays}
                                                            error={errors && errors.pays}
                                                            helperText={errors && errors.pays && errors.pays.join(', ')}
                                                        />
                                                    )}
                                                />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refville}
                                                    error={errors && errors.ville}
                                                    helperText={
                                                        errors && errors.ville && errors.ville.join(', ')
                                                    }
                                                    fullWidth
                                                    value={ville}
                                                    label="Ville"
                                                    onChange={(e) => setVille(e.target.value)}
                                                />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refcommune}
                                                    error={errors && errors.commune}
                                                    helperText={
                                                        errors && errors.commune && errors.commune.join(', ')
                                                    }
                                                    fullWidth
                                                    value={commune}
                                                    label="Commune"
                                                    onChange={(e) => setCommune(e.target.value)}
                                                />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refrue}
                                                    error={errors && errors.rue}
                                                    helperText={
                                                        errors && errors.rue && errors.rue.join(', ')
                                                    }
                                                    fullWidth
                                                    value={rue}
                                                    label="Rue"
                                                    onChange={(e) => setRue(e.target.value)}
                                                />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                                <FormControl fullWidth>
                                                    <DatePicker
                                                        onChange={(date) => setBirthday(date)}
                                                        label="Date de naissance"
                                                        value={birthday}
                                                    />
                                                </FormControl>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refemail}
                                                    error={errors && errors.email}
                                                    helperText={
                                                        errors && errors.email && errors.email.join(', ')
                                                    }
                                                    fullWidth
                                                    value={email}
                                                    label="Email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={5} className='d-flex'>
                                            <FormControl fullWidth sx={{ maxWidth: "80px" }}>
                                                <Autocomplete
                                                    sx={{ "& .MuiAutocomplete-inputRoot": { borderRadius: 0 } }}
                                                    disableClearable={true}
                                                    forcePopupIcon={false}
                                                    id="country-select-demo"
                                                    value={indicator}
                                                    onChange={(event, newValue) => {
                                                        setIndicator(newValue ? `+${newValue.phone}` : null);
                                                    }}
                                                    isOptionEqualToValue={(option) => option.phone == indicator}
                                                    fullWidth
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.phone ? `+${option.phone}` : indicator}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { flexShrink: 0 }, '& > span': { display: 'none' } }} {...props}>
                                                            {`+${option.phone}`}
                                                        </Box>
                                                    )}

                                                    renderInput={(params) => (
                                                        <TextField

                                                            {...params}
                                                            label="Indice"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',

                                                            }}
                                                            ref={refpays}
                                                            error={errors && errors.pays}
                                                            helperText={errors && errors.pays && errors.pays.join(', ')}

                                                        />
                                                    )}
                                                />

                                            </FormControl>
                                            <FormControl fullWidth sx={{ borderRadius: 0 }}>
                                                <TextField
                                                    sx={{ "& .MuiInputBase-root": { borderRadius: 0 } }}
                                                    type="number"
                                                    tabIndex={-1}
                                                    ref={refphone}
                                                    error={errors && errors.phone}
                                                    helperText={
                                                        errors && errors.phone && errors.phone.join(', ')
                                                    }
                                                    fullWidth
                                                    value={phone}
                                                    label="Téléphone"
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />

                                            </FormControl>


                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                id="country-select-demo2"
                                                onChange={(event, newValue) => {
                                                    setNationalite(newValue && newValue.label);
                                                }}
                                                value={nationalite}
                                                options={nationalites}
                                                autoHighlight
                                                fullWidth

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Nationalité"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password',
                                                        }}
                                                        ref={refnationalite}
                                                        error={errors && errors.nationalite}
                                                        helperText={
                                                            errors && errors.nationalite && errors.nationalite.join(', ')
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refbest_study}
                                                    select
                                                    error={errors && errors.best_study}
                                                    helperText={
                                                        errors && errors.best_study && errors.best_study.join(', ')
                                                    }
                                                    fullWidth
                                                    value={best_study}
                                                    label="Meilleur niveau d'étude"
                                                    onChange={(e) => setBestStudy(e.target.value)}
                                                >
                                                    <MenuItem value="">...</MenuItem>
                                                    {
                                                        niveauxEtude.map((study, index) => {
                                                            return (
                                                                <MenuItem key={index} value={study.value}>{study.label}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refbest_diplome}
                                                    select
                                                    error={errors && errors.best_diplome}
                                                    helperText={
                                                        errors && errors.best_diplome && errors.best_diplome.join(', ')
                                                    }
                                                    fullWidth
                                                    value={best_diplome}
                                                    label="Meilleur diplome"
                                                    onChange={(e) => setBestDiplome(e.target.value)}
                                                >
                                                    <MenuItem value="">...</MenuItem>
                                                    {
                                                        diplomes.map((diplome, index) => {
                                                            return (
                                                                <MenuItem key={index} value={diplome.value}>{diplome.label}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                                <FormControl fullWidth>
                                                    <DatePicker
                                                        onChange={(date) => setAdhesionDate(date)}
                                                        label="Date de debut de formation"
                                                        value={adhesion_date}
                                                    />
                                                </FormControl>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                                <FormControl fullWidth>
                                                    <DatePicker
                                                        onChange={(date) => setFormationDate(date)}
                                                        label="Date de fin de formation"
                                                        value={formation_date}
                                                    />
                                                </FormControl>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                                <FormControl fullWidth>
                                                    <DatePicker
                                                        onChange={(date) => setRecrutDate(date)}
                                                        label="Date de recrutement"
                                                        value={recrut_date}
                                                    />
                                                </FormControl>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} className='mt-2'>
                                            <span className='h6 fw-light'>Veuillez renseigner ci-dessous les informations concernant une pièce d'identité de l'<strong>agent de notation (ANE)</strong></span>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refnaturePiece}
                                                    select
                                                    error={errors && errors.naturePiece}
                                                    helperText={
                                                        errors && errors.naturePiece && errors.naturePiece.join(', ')
                                                    }
                                                    fullWidth
                                                    value={naturePiece}
                                                    label="Nature de la pièce"
                                                    onChange={(e) => setNaturePiece(e.target.value)}
                                                >
                                                    <MenuItem value="">...</MenuItem>
                                                    {
                                                        naturesPiece.map((nature, index) => {
                                                            return (
                                                                <MenuItem key={index} value={nature.value}>{nature.label}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refnumPiece}
                                                    error={errors && errors.num_piece}
                                                    helperText={
                                                        errors && errors.num_piece && errors.num_piece.join(', ')
                                                    }
                                                    fullWidth
                                                    value={numPiece}
                                                    label="Numero de la pièce"
                                                    onChange={(e) => setNumPiece(e.target.value)}
                                                />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3} className='d-flex justify-content-center align-items-center '>
                                            <Dropzone accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onDrop={handleDropRecto}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()} >
                                                        {!logoPreviewRecto && <input {...getInputProps()} />}
                                                        <Badge
                                                            role="button"
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            sx={{
                                                                border: "2px solid #f3f7fb"
                                                            }}
                                                            badgeContent={
                                                                <>
                                                                    {
                                                                        !logoPreviewRecto ? (
                                                                            <IconButton aria-label="share"
                                                                                sx={{
                                                                                    backgroundColor: "white",
                                                                                    borderRadius: "50%",
                                                                                    boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                                    '&:hover': {
                                                                                        backgroundColor: "white"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        ) : (
                                                                            <IconButton aria-label="share"
                                                                                onClick={handleRemoveRecto}
                                                                                sx={{
                                                                                    backgroundColor: "white",
                                                                                    borderRadius: "50%",
                                                                                    boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                                    '&:hover': {
                                                                                        backgroundColor: "white"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <DeleteSweepIcon />
                                                                            </IconButton>
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                        >
                                                            <Box sx={{ height: "150px", width: "150px" }} className="d-flex flex-column justify-content-center align-items-center p-2">
                                                                <div>Recto</div>
                                                                <img
                                                                    alt="Piece-recto"
                                                                    src={logoPreviewRecto ? logoPreviewRecto : `${process.env.REACT_APP_BASE_URL}${piece_recto}`}
                                                                    className='img-fluid'
                                                                    role='button'
                                                                    loading="lazy"
                                                                    sx={{ width: "100%", height: "100%" }}
                                                                />
                                                            </Box>
                                                        </Badge>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </Grid>
                                        <Grid item xs={12} sm={3} className='d-flex justify-content-center align-items-center '>
                                            <Dropzone accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onDrop={handleDropVerso}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()} >
                                                        {!logoPreviewVerso && <input {...getInputProps()} />}
                                                        <Badge
                                                            role="button"
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            sx={{
                                                                border: "2px solid #f3f7fb"
                                                            }}
                                                            badgeContent={
                                                                <>
                                                                    {
                                                                        !logoPreviewVerso ? (
                                                                            <IconButton aria-label="share"
                                                                                sx={{
                                                                                    backgroundColor: "white",
                                                                                    borderRadius: "50%",
                                                                                    boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                                    '&:hover': {
                                                                                        backgroundColor: "white"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        ) : (
                                                                            <IconButton aria-label="share"
                                                                                onClick={handleRemoveVerso}
                                                                                sx={{
                                                                                    backgroundColor: "white",
                                                                                    borderRadius: "50%",
                                                                                    boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                                                                    '&:hover': {
                                                                                        backgroundColor: "white"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <DeleteSweepIcon />
                                                                            </IconButton>
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                        >
                                                            <Box sx={{ height: "150px", width: "150px" }} className="d-flex flex-column justify-content-center align-items-center p-2">
                                                                <div>Verso</div>
                                                                <img
                                                                    alt="Piece-recto"
                                                                    src={logoPreviewVerso ? logoPreviewVerso : `${process.env.REACT_APP_BASE_URL}${piece_verso}`}
                                                                    className='img-fluid'
                                                                    role='button'
                                                                    loading="lazy"
                                                                    sx={{ width: "100%", height: "100%" }}
                                                                />
                                                            </Box>
                                                        </Badge>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </Grid>
                                        
                                        {/* {
                                            (isACE == 1 || isACE == 3) && ( */}
                                                <>
                                                    {
                                                        (accomplissements && accomplissements.length > 0) && (
                                                            <Grid item xs={12} className='my-4'>
                                                                <span className='h6 fw-light'>Information concernant la certification CYVAN de l'<strong>agent de notation (ANE)</strong></span>
                                                            </Grid>
                                                        )
                                                    }
                                                    {
                                                        (accomplissements && accomplissements.length > 0) && accomplissements.map((accomplis, index) => {

                                                            return (
                                                                <>
                                                                    <Grid item xs={12} sm={6} key={accomplis.id}>
                                                                        <FormControl fullWidth>
                                                                            <Autocomplete
                                                                                readOnly
                                                                                tabIndex={-1}
                                                                                id="country-select-demo2"
                                                                                onChange={(event, newValue) => {
                                                                                    setCertification(newValue && newValue);
                                                                                }}
                                                                                value={accomplis}
                                                                                options={certifications ? certifications : []}
                                                                                getOptionLabel={(option) =>
                                                                                    `${option.programme}-${option.niveau}-${option.domaine}-${option.cout}${JSON.parse(option.devise).currency}`
                                                                                }
                                                                                fullWidth
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        label="Choisir la formation suivie"

                                                                                        inputProps={{
                                                                                            ...params.inputProps,
                                                                                            autoComplete: 'new-password',
                                                                                        }}
                                                                                    // ref={refcertification}
                                                                                    // error={errors && errors.certification}
                                                                                    // helperText={
                                                                                    //     errors && errors.certification && errors.certification.join(', ')
                                                                                    // }
                                                                                    />
                                                                                )}
                                                                                disabled={findIndex == index ? false : true}
                                                                            />

                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={5} sm={2}>
                                                                        <FormControl fullWidth>

                                                                            <NumericFormat
                                                                                tabIndex={-1}
                                                                                label="Montant payé"
                                                                                // ref={refsolde}
                                                                                // error={errors && errors.solde}
                                                                                // helperText={errors && errors.solde && errors.solde.join(', ')}
                                                                                fixedDecimalScale
                                                                                allowLeadingZeros
                                                                                thousandSeparator=","
                                                                                customInput={TextField}
                                                                                value={accomplis.pivot.solded}
                                                                                onValueChange={(value) => (value !== 'undefined' && value.floatValue !== 'undefined') && setSolde(value.floatValue)} allowNegative={false}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="end">{accomplis.devise ? JSON.parse(accomplis.devise).code : ""}</InputAdornment>,
                                                                                }}
                                                                                disabled={findIndex == index ? false : true}
                                                                            />

                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={5} sm={2}>
                                                                        <FormControl fullWidth>

                                                                            <NumericFormat
                                                                                disabled
                                                                                tabIndex={-1}
                                                                                label="Reste"
                                                                                // ref={refsolde}
                                                                                // error={errors && errors.solde}
                                                                                // helperText={errors && errors.solde && errors.solde.join(', ')}
                                                                                fixedDecimalScale
                                                                                allowLeadingZeros
                                                                                thousandSeparator=","
                                                                                customInput={TextField}
                                                                                value={accomplis.pivot.pending}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="end">{accomplis.devise ? JSON.parse(accomplis.devise).code : ""}</InputAdornment>,
                                                                                }}

                                                                            />

                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={2} sm={2} className='d-flex justify-content-center align-items-center'>
                                                                        {
                                                                            findIndex == index ? (
                                                                                <Stack direction="row" >
                                                                                    {
                                                                                        certifLoadingSend && findIndex == index ? (
                                                                                            <div className='d-flex justify-content-start'
                                                                                                style={{ height: "35px", width: "35px" }}>
                                                                                                <div ref={switchRefs[index]} />
                                                                                            </div>
                                                                                        ) : (
                                                                                            <IconButton key={index} size='small' onClick={() => handleUpdateCertif(accomplis.id)}>
                                                                                                <SaveIcon color='primary' />
                                                                                            </IconButton>
                                                                                        )
                                                                                    }

                                                                                    {
                                                                                        !certifLoadingSend && (
                                                                                            <IconButton size='small' onClick={handlePrepareClose}>
                                                                                                <ClearIcon color='error' />
                                                                                            </IconButton>
                                                                                        )
                                                                                    }
                                                                                </Stack>
                                                                            ) : (
                                                                                !certifLoadingSend && (
                                                                                    <Stack direction="row" spacing={1}>
                                                                                        <IconButton size='small' onClick={() => handlePrepare(index, accomplis.pivot.solded, accomplis)}>
                                                                                            <EditIcon color='primary' />
                                                                                        </IconButton>
                                                                                        <IconButton size='small' onClick={() => handleDeleteCertification(accomplis)}>
                                                                                            <DeleteSweepIcon color='error' />
                                                                                        </IconButton>
                                                                                    </Stack>
                                                                                )
                                                                            )

                                                                        }
                                                                    </Grid>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </>
                                            {/* )
                                        } */}
                                        <Grid item xs={12} className='d-flex justify-content-end'>
                                            <Stack direction="row" spacing={2}>
                                                <LoadingButton loading={loading} type="submit" variant="outlined"  >
                                                    Enregistrer
                                                </LoadingButton>
                                                {
                                                    !loading && (
                                                        <Button variant="outlined" onClick={reset}>
                                                            Annuler
                                                        </Button>
                                                    )
                                                }
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                </form>
                            </Box>
                        </Paper>
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar={true}
                            pauseOnHover={false}
                        />
                    </motion.div>
                ) : (
                    <div
                        className=' d-flex justify-content-center align-items-center' style={{ minHeight: '65vh' }}
                    >
                        <div
                            style={{ maxHeight: "100px", maxWidth: "100px" }}
                            ref={loadingref}
                        >

                        </div>
                    </div>
                )
            }
        </div >
    );
}

export default ModifAgent;
