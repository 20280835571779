import React from 'react'
import Index from '../views/dashboard/index'
const HorizontalMulti2Router = () => {
    return (
        <div>
            <Index />
        </div>
    )
}

export default HorizontalMulti2Router
