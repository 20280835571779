import { useEffect, memo, Fragment, useContext } from "react";
import { useLocation, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
//react-shepherd
import { ShepherdTourContext } from "react-shepherd";

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal"; // Importez le composant Modal de Bootstrap
import Button from "react-bootstrap/Button"; // Importez le composant Button de Bootstrap

//react-bootstrap
import { Link } from "react-router-dom";

// header
import Header from "../../components/partials/dashboard/HeaderStyle/header";

//subheader
import SubHeader from "../../components/partials/dashboard/HeaderStyle/sub-header";

//sidebar
import Sidebar from "../../components/partials/dashboard/SidebarStyle/sidebar";

//footer
import Footer from "../../components/partials/dashboard/FooterStyle/footer";

//default
// import {DefaultRouter} from '../../router/default-router'

//seetingoffCanvas
import SettingOffCanvas from "../../components/setting/SettingOffCanvas";

import Loader from "../../components/Loader";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const Default = memo((props) => {
  const appName = useSelector(SettingSelector.app_name);

  const verif = Cookies.get("authUser");

  const { pathname } = useLocation();

  const Tour = () => {
    const tour = useContext(ShepherdTourContext);
    useEffect(() => {
      if (pathname === "/dashboard" && sessionStorage.getItem("tour") !== "true") {
        tour?.start();
      }
    });
    return <Fragment></Fragment>;
  };

  useEffect(() => {
    if (!verif) {
      window.location.href = "/";
    }
  }, [verif]);

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      // Empêche le navigateur de proposer automatiquement l'installation de la PWA
      event.preventDefault();
      // Stocke l'événement pour pouvoir y accéder ultérieurement
      setDeferredPrompt(event);
      // Affiche la modal pour inciter l'utilisateur à installer la PWA
      setShowModal(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Nettoyage des écouteurs d'événements lors du démontage du composant
    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const installPwa = () => {
    if (deferredPrompt) {
      // Déclenche l'installation de la PWA en utilisant l'événement différé
      deferredPrompt.prompt();
      // Attend le résultat de l'installation
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("L'utilisateur a accepté l'installation de la PWA");
        } else {
          console.log("L'utilisateur a refusé l'installation de la PWA");
        }
        // Efface l'événement différé une fois l'installation effectuée
        setDeferredPrompt(null);
      });
    }
    // Masque la modal après avoir cliqué sur le bouton "Installer"
    setShowModal(false);
  };

  const handleCloseModal = () => {
    // Masque la modal si l'utilisateur clique sur "Plus tard"
    setShowModal(false);
  };

  return (
    <Fragment>
      <Loader />
      {verif ? (
        <Fragment>
          <Loader />
          <Sidebar app_name={appName} />
          <Tour />
          <main className="main-content">
            <div className="position-relative">
              <Header />
            </div>
            <div className="py-2 px-3 px-md-4 container-fluid content-inner mt-n5" style={{minHeight:"auto"}}>
              <Outlet />
            </div>
            {/* Affichez la modal pour inciter l'utilisateur à installer la PWA */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Installer l'application ACIS || AYECHIN ORGANIZATION</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {/* Colonne pour l'image (col-4) */}
                  <div className="col-4">
                    {/* Ajoutez le logo de l'application */}
                    <img src="https://api.acis.ayechin.org/assets/logo.png" alt="AYECHIN Company Income Statement for Traceability (ACIS)" className="img-fluid" />
                  </div>
                  {/* Colonne pour le texte (col-8) */}
                  <div className="col-8">
                    <p>Installer cette application sur votre appareil pour profiter d'une expérience hors ligne améliorée.</p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Plus tard
                </Button>
                <Button variant="primary" onClick={installPwa}>
                  Installer
                </Button>
              </Modal.Footer>
            </Modal>
          </main>
        </Fragment>
      ) : null}
    </Fragment>
  );
});

Default.displayName = "Default";
export default Default;
