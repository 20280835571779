import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-web";
import loadingwait from "../components/animations/loading.json";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Chip,
  ImageList,
  useTheme,
  ImageListItem,
  ImageListItemBar,
  MobileStepper,
  Stack,
  Typography,
  CardHeader,
  CardContent,
  LinearProgress,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import TransgenderIcon from "@mui/icons-material/Transgender";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";
import MailIcon from "@mui/icons-material/MailOutlined";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import FlagCircleIcon from "@mui/icons-material/FlagCircleOutlined";
import CakeIcon from "@mui/icons-material/CakeOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import { ToastContainer, toast } from "react-toastify";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfoIcon from "@mui/icons-material/Info";
import { AnimatePresence, motion } from "framer-motion";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import EventIcon from "@mui/icons-material/Event";
import SchoolIcon from "@mui/icons-material/School";
import NotationIcon from "@mui/icons-material/EditNote";
import BlindsIcon from "@mui/icons-material/Blinds";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import dayjs from "dayjs";
import "dayjs/locale/fr";

const AceShow = () => {
  const { id } = useParams();
  const [user, setUser] = useState();
  const [preview, setPreview] = useState();
  const [accomplissements, setAccomplissements] = useState();
  const token = Cookies.get("authToken");
  const [imageClicked, setImageClicked] = useState(false);

  const handleClick = () => {
    setImageClicked(!imageClicked);
  };
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/users/show/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data.user);
        setAccomplissements(response.data.accomplissements);
        response.data.user.piece_recto ? setPreview(response.data.user.piece_recto) : response.data.user.piece_verso && setPreview(response.data.user.piece_verso);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  dayjs.locale("fr");
  const loadingref = useRef();
  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);
  const verifieAge = (birthday) => {
    if (birthday) {
      const dateNaissance = new Date(birthday);
      const dateActuelle = new Date();
      let age = dateActuelle.getFullYear() - dateNaissance.getFullYear();

      if (
        dateActuelle.getMonth() < dateNaissance.getMonth() ||
        (dateActuelle.getMonth() === dateNaissance.getMonth() && dateActuelle.getDate() < dateNaissance.getDate())
      ) {
        age--;
      }

      return age;
    }
  };

  const deleteObjectif = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}admin/users/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteUser = async (id) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?");

    if (!confirmDelete) {
      return;
    }

    toast
      .promise(deleteObjectif(id), {
        pending: "Suppression en cours...",
        success: "Suppression realisée avec succès!",
        error: "Il y'a eu une erreur lors de la suppression",
      })
      .then(() => {
        setTimeout(() => {
          navigate("/dashboard/users");
        }, 2000);
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression de l'objectif", error);
      });
  };

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };

  const changeUrl = (val) => {
    setPreview(val);
  };

  const previewImage = (url) => {
    return url ? (
      <motion.img
        key={url}
        initial={{ opacity: 0 }}
        animate={{ opacity: [0.5, 0.9, 1] }}
        exit={{ opacity: [1, 0] }}
        srcSet={`${process.env.REACT_APP_BASE_URL}${url}`}
        src={`${process.env.REACT_APP_BASE_URL}${url}`}
        alt={user.firstname}
        loading="lazy"
        style={{ height: "auto", width: "60%" }}
      />
    ) : (
      ""
    );
  };

  return (
    <>
      {user ? (
        <motion.div>
          <Stack spacing={2}>
            <Box>
              <Card className="p-3 position-relative">
                <Box
                  sx={{ height: "30px", width: "100px", background: "#81a7d5", bottom: "0", right: "0", borderTopLeftRadius: ".7em" }}
                  className="position-absolute d-flex justify-content-evenly align-items-center"
                >
                  <Link to={`/dashboard/admins`}>
                    <IconButton size="small">
                      <ReplyOutlinedIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Link>
                  <Link to={`/dashboard/admin/edit/${user.id}`}>
                    <IconButton size="small">
                      <BorderColorOutlinedIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Link>

                  <IconButton size="small" onClick={() => handleDeleteUser(user.id)}>
                    <DeleteSweepOutlinedIcon sx={{ color: "white" }} />
                  </IconButton>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={7} className="d-flex  align-items-center">
                    <Stack spacing={2} direction="row">
                      <div className="position-relative">
                        <motion.div onClick={handleClick}>
                          <Avatar
                            src={`${process.env.REACT_APP_BASE_URL}${user.image}`}
                            sx={{ height: "100px", width: "100px" }}
                            alt={`${user.firstname}`}
                            role="button"
                          />
                        </motion.div>
                        <AnimatePresence>
                          {imageClicked && (
                            <motion.div
                              initial={{
                                opacity: 0,
                                scale: 0,
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                translateX: "-50%",
                                translateY: "-50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              animate={{ opacity: 1, scale: 1 }}
                              exit={{ opacity: 0, scale: 0 }}
                              onClick={handleClick}
                              style={{ height: "100%", width: "100%", backdropFilter: "blur(1px)", backgroundColor: "transparent", zIndex: 9999 }}
                              role="button"
                            >
                              <Avatar
                                src={`${process.env.REACT_APP_BASE_URL}${user.image}`}
                                sx={{ height: "250px", width: "250px" }}
                                alt={`${user.firstname}`}
                                role="button"
                              />
                            </motion.div>
                          )}
                        </AnimatePresence>
                        {user.gender == "homme" ? (
                          <IconButton
                            size="small"
                            className="position-absolute"
                            sx={{ backgroundColor: "#546285", top: 0, right: 0, "&:hover": { backgroundColor: "#546285" } }}
                          >
                            <MaleIcon fontSize="small" sx={{ color: "white" }} />
                          </IconButton>
                        ) : user.gender == "femme" ? (
                          <IconButton
                            size="small"
                            className="position-absolute"
                            sx={{ backgroundColor: "#846587", top: 0, right: 0, "&:hover": { backgroundColor: "#846587" } }}
                          >
                            <FemaleIcon fontSize="small" sx={{ color: "white" }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            className="position-absolute"
                            sx={{ backgroundColor: "#818287", top: 0, right: 0, "&:hover": { backgroundColor: "#818287" } }}
                          >
                            <TransgenderIcon fontSize="small" sx={{ color: "white" }} />
                          </IconButton>
                        )}
                      </div>

                      <Box>
                        <Stack direction="column" spacing={2}>
                          <Stack>
                            <Typography className="text-capitalize" component="span" sx={{ fontSize: "1.2em", fontFamily: "verdana" }}>
                              {" "}
                              {user.lastname} {user.firstname}{" "}
                            </Typography>
                            <Typography className="text-capitalize" component="span" sx={{ fontSize: ".7em", fontFamily: "verdana" }}>
                              {" "}
                              {user.role && user.role.title}{" "}
                            </Typography>
                            <Typography
                              className="text-capitalize d-flex align-items-center"
                              component="span"
                              sx={{ fontSize: ".8em", fontFamily: "verdana", color: "#81a7d5" }}
                            >
                              {user.isAdmin == 1 ? (
                                <>
                                  <DashboardIcon fontSize="small" /> <span>Employé administrateur</span>
                                </>
                              ) : user.isAce == 1 ? (
                                <>
                                  <AssignmentIndIcon fontSize="small" /> <span>Agent ACE</span>
                                </>
                              ) : user.isAgent == 1 ? (
                                <>
                                  <NotationIcon fontSize="small" /> <span>Agent de notation</span>
                                </>
                              ) : user.isAnother == 1 ? (
                                <>
                                  <AssignmentIndIcon fontSize="small" />{" "}
                                  <span>{user.is_jobseeker == 1 ? "Demandeur d'emploi" : user.is_jobseeker == 0 && "Demandeur de formation"}</span>
                                </>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Stack>
                          <Box>
                            <Stack spacing={1} direction="row">
                              <a href={`mailto:${user.email}`} className="p-1 d-flex justify-content-center align-items-center  btn btn-outline-success btn-sm">
                                Envoyer mail
                              </a>
                              <a href={`tel:${user.phone}`} className="p-1 d-flex justify-content-center align-items-center  btn btn-outline-primary btn-sm">
                                Appeler
                              </a>
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={5} className="d-flex align-items-center">
                    <Stack spacing={1}>
                      {user.birthday && (
                        <Box className="d-flex align-items-center">
                          <CakeIcon />
                          &nbsp;
                          <span
                            className="text-capitalize d-flex align-items-center"
                            component="span"
                            sx={{ fontFamily: "verdana", fontSize: ".9em", color: "hsl(240 5.2% 33.9%)" }}
                          >
                            {verifieAge(user.birthday)} ans
                          </span>
                        </Box>
                      )}
                      {(user.pays || user.commune || user.rue || user.ville) && (
                        <Box className="d-flex align-items-center">
                          <LocationOnIcon />
                          &nbsp;
                          <span
                            className="text-capitalize d-flex align-items-center"
                            component="span"
                            sx={{ fontFamily: "verdana", fontSize: ".9em", color: "hsl(240 5.2% 33.9%)" }}
                          >
                            {user.pays && JSON.parse(user.pays).label} {user.ville && user.ville} {user.commune && user.commune}
                          </span>
                        </Box>
                      )}
                      {user.nationalite && (
                        <Box className="d-flex align-items-center">
                          <FlagCircleIcon />
                          &nbsp;
                          <span
                            className="text-capitalize d-flex align-items-center"
                            component="span"
                            sx={{ fontFamily: "verdana", fontSize: ".9em", color: "hsl(240 5.2% 33.9%)" }}
                          >
                            {user.nationalite}
                          </span>
                        </Box>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Box>
            <Box>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      {(user.best_diplome || user.best_study || user.adhesion_date || user.formation_date || user.recrut_date || accomplissements) && (
                        <Tab label="Formations" value="1" />
                      )}
                      {user.domaine_id && <Tab label="Objectifs" value="2" />}
                      {(user.nature_piece || user.num_piece || user.piece_recto || user.piece_verso) && <Tab label="Documents" value="3" />}
                    </TabList>
                  </Box>
                  {(user.best_diplome ||
                    user.best_study ||
                    user.adhesion_date ||
                    user.formation_date ||
                    user.recrut_date ||
                    user.certification_id ||
                    accomplissements) && (
                    <TabPanel value="1" className="px-0">
                      <Grid container spacing={2}>
                        {(user.best_diplome || user.best_study || user.adhesion_date || user.formation_date || user.recrut_date || user.certification_id) && (
                          <Grid item className="d-flex flex-column" xs={12} sm={5} spacing={2}>
                            <Card>
                              <CardContent className="d-flex flex-column gap-3">
                                {user.best_study && (
                                  <>
                                    <Stack direction="column">
                                      <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                        Niveau d'étude
                                      </Box>
                                      <Box className="text-end text-capitalize" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                        {user.best_study}
                                      </Box>
                                    </Stack>

                                    <Divider variant="middle" />
                                  </>
                                )}
                                {user.best_diplome && (
                                  <>
                                    <Stack direction="column">
                                      <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                        Plus haut diplome
                                      </Box>
                                      <Box className="text-end text-capitalize" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                        {user.best_diplome}
                                      </Box>
                                    </Stack>
                                    <Divider variant="middle" />
                                  </>
                                )}
                                {user.recrut_date && (
                                  <>
                                    <Stack direction="column">
                                      <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                        Date de recrutement
                                      </Box>
                                      <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                        {dayjs(user.recrut_date).format("DD/MM/YYYY")}
                                      </Box>
                                    </Stack>
                                    <Divider variant="middle" />
                                  </>
                                )}
                                {user.adhesion_date && (
                                  <>
                                    <Stack direction="column">
                                      <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                        Date d'adhesion
                                      </Box>
                                      <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                        {dayjs(user.adhesion_date).format("DD/MM/YYYY")}
                                      </Box>
                                    </Stack>
                                    <Divider variant="middle" />
                                  </>
                                )}
                                {user.formation_date && (
                                  <Stack direction="column">
                                    <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                      Date de formation
                                    </Box>
                                    <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                      {dayjs(user.formation_date).format("DD/MM/YYYY")}
                                    </Box>
                                  </Stack>
                                )}
                              </CardContent>
                            </Card>
                          </Grid>
                        )}

                        {accomplissements && (
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              {accomplissements &&
                                accomplissements.map((accomplissement) => {
                                  return (
                                    <Grid item xs={12} sm={6}>
                                      <Card>
                                        <CardContent>
                                          <>
                                            <Chip icon={<BlindsIcon />} label="CERTIFICATION CYVAN" variant="outlined" color="primary" />
                                            <Box className="text-capitalize text-center my-4" sx={{}}>
                                              {accomplissement.programme}
                                            </Box>
                                            <Grid container spacing={3} direction="row" className="mb-5 d-flex justify-content-center align-items-center">
                                              <Grid item xs={6} sm={4}>
                                                <Card className="p-2 d-flex  justify-content-center align-items-center">
                                                  <Box className=" d-flex flex-column gap-2  ">
                                                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>Domaine</span>
                                                    <span style={{ fontSize: ".9em", fontWeight: "550" }}>{accomplissement.domaine}</span>
                                                  </Box>
                                                </Card>
                                              </Grid>
                                              <Grid item xs={6} sm={4}>
                                                <Card className="p-2 d-flex  justify-content-center align-items-center">
                                                  <Box className=" d-flex flex-column gap-2  ">
                                                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>Niveau</span>
                                                    <span style={{ fontSize: ".9em", fontWeight: "550" }}>{accomplissement.niveau}</span>
                                                  </Box>
                                                </Card>
                                              </Grid>
                                              <Grid item xs={6} sm={4}>
                                                <Card className="p-2 d-flex  justify-content-center align-items-center">
                                                  <Box className=" d-flex flex-column gap-2  ">
                                                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>Cout</span>
                                                    <span style={{ fontSize: ".9em", fontWeight: "550" }}>{`${accomplissement.cout
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${JSON.parse(accomplissement.devise).currency}`}</span>
                                                  </Box>
                                                </Card>
                                              </Grid>
                                              <Grid item xs={6} sm={4}>
                                                <Card className="p-2 d-flex  justify-content-center align-items-center">
                                                  <Box className=" d-flex flex-column gap-2  ">
                                                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>Soldé</span>
                                                    <span style={{ fontSize: ".9em", fontWeight: "550" }}>{`${accomplissement.pivot.solded
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${JSON.parse(accomplissement.devise).currency}`}</span>
                                                  </Box>
                                                </Card>
                                              </Grid>
                                              <Grid item xs={6} sm={4}>
                                                <Card className="p-2 d-flex  justify-content-center align-items-center">
                                                  <Box className=" d-flex flex-column gap-2  ">
                                                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>Reste</span>
                                                    <span style={{ fontSize: ".9em", fontWeight: "550" }}>{`${accomplissement.pivot.pending
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${JSON.parse(accomplissement.devise).currency}`}</span>
                                                  </Box>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                            {accomplissement.pivot.solded && (
                                              <div sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                <Box>
                                                  <Chip
                                                    variant="filled"
                                                    color={accomplissement.pivot.statut == 0 ? "error" : "success"}
                                                    label={accomplissement.pivot.statut == 0 ? "En cours" : "Payé"}
                                                  />
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                  <Box sx={{ width: "100%", mr: 1 }}>
                                                    <LinearProgress variant="determinate" value={(accomplissement.pivot.solded / accomplissement.cout) * 100} />
                                                  </Box>
                                                  <Box sx={{ minWidth: 100 }} className="d-flex flex-column gap-3">
                                                    <Typography variant="body2" color="text.secondary">
                                                      {`${accomplissement.pivot.solded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/${accomplissement.cout
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              </div>
                                            )}

                                            <div className="d-flex justify-content-between align-items-center gap-4 mt-3">
                                              <Button variant="outlined" startIcon={<VisibilityIcon />}>
                                                Apercu du dîplome
                                              </Button>
                                              {accomplissement.pivot.pending && (
                                                <Button variant="outlined" endIcon={<PrintIcon />}>
                                                  Imprimer le diplome
                                                </Button>
                                              )}
                                            </div>
                                          </>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      {user.isAnother != 0 && (
                        <Grid item xs={12}>
                          {user.is_job ? (
                            <>
                              <Box className="text-center">Travaille actuellement dans une entreprise</Box>

                              <Stack direction="column">
                                <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                  Entreprise actuelle
                                </Box>
                                <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                  {user.current_company_name}
                                </Box>
                              </Stack>
                              <Stack direction="column">
                                <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                  Adresse de l'entreprise
                                </Box>
                                <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                  {user.current_company_adress}
                                </Box>
                              </Stack>
                              <Stack direction="column">
                                <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                  Email de l'entreprise
                                </Box>
                                <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                  {user.current_company_email}
                                </Box>
                              </Stack>
                              <Stack direction="column">
                                <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                  Téléphone de l'entreprise
                                </Box>
                                <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                  {user.current_company_phone}
                                </Box>
                              </Stack>
                              <Stack direction="column">
                                <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                  Fonction occupée dans l'entreprise
                                </Box>
                                <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                  {user.current_fonction}
                                </Box>
                              </Stack>
                            </>
                          ) : (
                            user.asprevjob && (
                              <>
                                <Box className="text-center">Ne travaille pas actuellement</Box>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Entreprise précédente
                                  </Box>
                                  <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.old_company_name}
                                  </Box>
                                </Stack>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Adresse de l'entreprise
                                  </Box>
                                  <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.old_company_adress}
                                  </Box>
                                </Stack>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Email de l'entreprise
                                  </Box>
                                  <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.old_company_email}
                                  </Box>
                                </Stack>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Téléphone de l'entreprise
                                  </Box>
                                  <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.old_company_phone}
                                  </Box>
                                </Stack>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Fonction occupée dans l'entreprise
                                  </Box>
                                  <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.old_fonction}
                                  </Box>
                                </Stack>
                              </>
                            )
                          )}
                        </Grid>
                      )}
                    </TabPanel>
                  )}
                  {user.domaine_id && (
                    <TabPanel value="2" className="px-0">
                      <Box className="d-flex flex-column" xs={12}>
                        <Card>
                          <CardContent className="d-flex flex-column gap-3">
                            {user.domaine.designation && (
                              <>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Domaine de formation
                                  </Box>
                                  <Box className="text-end text-capitalize" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.domaine.designation}
                                  </Box>
                                </Stack>

                                <Divider variant="middle" />
                              </>
                            )}
                            {user.domaine.nb_entreprise_target && (
                              <>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Nombre d'entreprises cible
                                  </Box>
                                  <Box className="text-end text-capitalize" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.domaine.nb_entreprise_target}
                                  </Box>
                                </Stack>
                                <Divider variant="middle" />
                              </>
                            )}
                            {user.domaine.niveau_objectif && (
                              <>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Niveau de l'ACE
                                  </Box>
                                  <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.domaine.niveau_objectif}
                                  </Box>
                                </Stack>
                                <Divider variant="middle" />
                              </>
                            )}
                            {user.domaine.cout_formation && (
                              <>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Cout par entreprise
                                  </Box>
                                  <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.domaine.cout_formation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                    {JSON.parse(user.domaine.devise_paiement).currency}
                                  </Box>
                                </Stack>
                                <Divider variant="middle" />
                              </>
                            )}
                            {user.domaine.objectif_mensuel && (
                              <>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Objectif mensuel
                                  </Box>
                                  <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.domaine.objectif_mensuel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                    {JSON.parse(user.domaine.devise_paiement).currency}
                                  </Box>
                                </Stack>
                                <Divider variant="middle" />
                              </>
                            )}
                            {user.domaine.objectif_annuel && (
                              <>
                                <Stack direction="column">
                                  <Box className="text-start text-uppercase" sx={{ fontSize: ".65em", color: "#8A92A6" }}>
                                    Objectif annuel
                                  </Box>
                                  <Box className="text-end" sx={{ fontSize: ".9em", fontWeight: "550" }}>
                                    {user.domaine.objectif_annuel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                    {JSON.parse(user.domaine.devise_paiement).currency}
                                  </Box>
                                </Stack>
                                <Divider variant="middle" />
                              </>
                            )}
                          </CardContent>
                        </Card>
                      </Box>
                    </TabPanel>
                  )}
                  {(user.nature_piece || user.num_piece || user.piece_recto || user.piece_verso) && (
                    <TabPanel value="3" className="px-0">
                      {user.nature_piece && (
                        <Typography component="h6" className="mb-2 fw-bold">
                          {" "}
                          {user.nature_piece} {user.num_piece && `Nº ${user.num_piece}`}{" "}
                        </Typography>
                      )}
                      {getFileExtension(user.piece_recto ? user.piece_recto : "") == "doc" ||
                      getFileExtension(user.piece_recto ? user.piece_recto : "") == "pdf" ||
                      getFileExtension(user.piece_recto ? user.piece_recto : "") == "docx" ||
                      getFileExtension(user.piece_verso ? user.piece_verso : "") == "doc" ||
                      getFileExtension(user.piece_verso ? user.piece_verso : "") == "pdf" ||
                      getFileExtension(user.piece_verso ? user.piece_verso : "") == "docx" ? (
                        <Box className="d-flex justify-content-center align-items-center">
                          <a
                            className="btn btn-primary"
                            target="_blank"
                            href={user.piece_recto ? `${process.env.REACT_APP_BASE_URL}${user.piece_recto}` : `${process.env.REACT_APP_BASE_URL}${user.piece_verso}`}
                          >
                            Voir le fichier
                          </a>
                        </Box>
                      ) : (
                        (getFileExtension(user.piece_recto ? user.piece_recto : "") == "jpg" ||
                          getFileExtension(user.piece_recto ? user.piece_recto : "") == "png" ||
                          getFileExtension(user.piece_recto ? user.piece_recto : "") == "jpeg" ||
                          getFileExtension(user.piece_verso ? user.piece_verso : "") == "jpg" ||
                          getFileExtension(user.piece_verso ? user.piece_verso : "") == "png" ||
                          getFileExtension(user.piece_verso ? user.piece_verso : "") == "jpeg") && (
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={3}>
                              <ImageList sx={{ width: "100%", maxHeight: 450 }} cols="1">
                                <ImageListItem key={user.piece_recto} onClick={() => changeUrl(user.piece_recto)} role="button">
                                  <img
                                    srcSet={`${process.env.REACT_APP_BASE_URL}${user.piece_recto}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${process.env.REACT_APP_BASE_URL}${user.piece_recto}?w=248&fit=crop&auto=format`}
                                    alt={user.firstname}
                                    loading="lazy"
                                  />
                                  <ImageListItemBar title="Recto" />
                                </ImageListItem>
                                <ImageListItem key={user.piece_verso} onClick={() => changeUrl(user.piece_verso)} role="button">
                                  <img
                                    srcSet={`${process.env.REACT_APP_BASE_URL}${user.piece_verso}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${process.env.REACT_APP_BASE_URL}${user.piece_verso}?w=248&fit=crop&auto=format`}
                                    alt={user.firstname}
                                    loading="lazy"
                                  />
                                  <ImageListItemBar title="Verso" />
                                </ImageListItem>
                              </ImageList>
                            </Grid>
                            <Grid item xs={12} sm={9} className="d-none d-md-block ">
                              <div className="d-flex justify-content-center align-items-center ">{previewImage(preview)}</div>
                            </Grid>
                          </Grid>
                        )
                      )}
                    </TabPanel>
                  )}
                </TabContext>
              </Box>
            </Box>
          </Stack>
        </motion.div>
      ) : (
        <div className=" d-flex justify-content-center align-items-center" style={{ minHeight: "65vh" }}>
          <div style={{ maxHeight: "100px", maxWidth: "100px" }} ref={loadingref}></div>
        </div>
      )}
      <ToastContainer autoClose={3000} hideProgressBar={true} pauseOnHover={false} />
    </>
  );
};

export default AceShow;
