import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Image, Form, Button, ListGroup } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Card from '../../../components/Card'

import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from "@mui/lab/LoadingButton";

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Cookies from 'js-cookie';
// img
import facebook from '../../../assets/images/brands/fb.svg'
import google from '../../../assets/images/brands/gm.svg'
import instagram from '../../../assets/images/brands/im.svg'
import linkedin from '../../../assets/images/brands/li.svg'
import auth5 from '../../../assets/images/auth/05.png'

const NewPassword = () => {
   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
   const navigate = useNavigate()
   //variables
   const token = new URLSearchParams(window.location.search).get('token');
   // Extraction de l'e-mail de la chaîne de requête
   const email = new URLSearchParams(window.location.search).get('email');
   const confirm = useRef();
   const [isLoading, setIsLoading] = useState(false);
   const [password, setNewPassword] = useState('');
   const [cpassword, setCpassword] = useState('');
   const [fonction, setFonction] = useState();
   const [severity, setSeverity] = useState('');

   ///fonction de reinitialisation de mot de passe admin
   const handleAdminReset = async (e) => {
      e.preventDefault();
      if (password !== cpassword) {
          setSeverity('error');
          confirm.current.textContent = '';
          confirm.current.appendChild(document.createTextNode("Les mots de passe ne correspondent pas."));
      } else {
          setIsLoading(true);
          try {
              const response = await axios.post(`${apiBaseUrl}reset-admin-password`, { 
               password: password,
               email: email,
               token: token
            });
              if (response.data.success === true) {
                  confirm.current.textContent = '';
                  setSeverity('success');
                  confirm.current.appendChild(document.createTextNode(response.data.message));
                  setTimeout(() => {
                     navigate('/');
                 }, 2000);
              } else {
                  confirm.current.textContent = '';
                  setSeverity('error');
                  confirm.current.appendChild(document.createTextNode(response.data.message));
              }
              
          } catch (error) {
              console.error(error);
          } finally {
              setIsLoading(false);
          }
      }
  };
  ///fin fonction de reinitialisation de mot de passe admin

   return (
      <>
         <section className="login-content">
            <Row className="m-0 align-items-center bg-white vh-100">
               <div className="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                  <Image src={auth5} className="Image-fluid gradient-main animated-scaleX" alt="images" />
               </div>
               <Col md="6">
                  <Row className="justify-content-center">
                     <Col md="10">
                        <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                           <Card.Body>
                           <Link to="/" className="navbar-brand d-flex justify-content-center align-items-center mb-1">
                                 <img src="../logo.jpg" style={{height:"90px",width:"90px",objectFit:"cover"}} />
                              </Link>
                              <h2 className="mb-2 text-center">Réinitialisation de mot de passe</h2>
                              <p className="text-center">Entrer le nouveau mot de passe</p>
                              <Alert severity={severity} sx={{ mt: 3 }}> <span ref={confirm}></span></Alert>
                              <Form>
                                 <Row>
                                 <input type="hidden" name="token" value={token} />
                                 {/* Champ d'e-mail hidden */}
                                 <input type="hidden" name="email" value={email} />

                                    <Col lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="password" className="">Nouveau mot de passe</Form.Label>
                                          <Form.Control 
                                             required
                                             name="password"
                                             label="Mot de passe"
                                             type="password"
                                             id="password"
                                             autoComplete="current-password"
                                             value={password}
                                             onChange={(e) => setNewPassword(e.target.value)}
                                          />
                                       </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="confirm-password" className="">Confirmer le mot de passe</Form.Label>
                                          <Form.Control 
                                             required
                                             name="cpassword"
                                             label="Confirmer le mot de passe"
                                             type="password"
                                             id="cpassword"
                                             autoComplete="current-cpassword"
                                             value={cpassword}
                                             onChange={(e) => setCpassword(e.target.value)}
                                          />
                                       </Form.Group>
                                    </Col>
                                    
                                 </Row>
                                 <div className="d-flex justify-content-center">
                                 {isLoading ? (
                                    <Button
                                       type="button"
                                       variant="btn btn-primary"
                                       disabled
                                    >
                                       Creation du nouveau mot de passe...
                                    </Button>
                                 ) : (
                                    <Button
                                       onClick={handleAdminReset}
                                       type="button"
                                       variant="btn btn-primary"
                                       disabled={isLoading}
                                    >
                                       Confirmer
                                    </Button>
                                 )}

                                 </div>
                                 
                              </Form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
                  <div className="sign-bg sign-bg-right">
                     <svg width="280" height="230" viewBox="0 0 421 359" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.05">
                           <rect x="-15.0845" y="154.773" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 -15.0845 154.773)" fill="#3A57E8" />
                           <rect x="149.47" y="319.328" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 149.47 319.328)" fill="#3A57E8" />
                           <rect x="203.936" y="99.543" width="310.286" height="77.5714" rx="38.7857" transform="rotate(45 203.936 99.543)" fill="#3A57E8" />
                           <rect x="204.316" y="-229.172" width="543" height="77.5714" rx="38.7857" transform="rotate(45 204.316 -229.172)" fill="#3A57E8" />
                        </g>
                     </svg>
                  </div>
               </Col>
            </Row>
         </section>
      </>
   )
}

export default NewPassword
