import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { LineChart } from '@mui/x-charts/LineChart';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
const SessionStats = ({ count ,data,total}) => {

  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Cotisations mensuelles
        </Typography>
        <Stack sx={{ justifyContent: "space-between" }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: "center", sm: "flex-start" },
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              {total.toLocaleString()}
            </Typography>
            <TrendingUpOutlinedIcon fontSize='large'/>
          </Stack>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            Progression des cotisations pendant l'année en cours
          </Typography>

          <LineChart
            dataset={data}
            xAxis={[
              {
                id: "month",
                dataKey: "month",
                scaleType: "point"
              },
            ]}
            series={[
              {
                id: "total",
                dataKey: "total",
                area: true,
                showMark: false
              }
            ]}
            height={400}
            margin={{ left: 70  }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SessionStats;
