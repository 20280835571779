import React, { useEffect, useState, useRef, Fragment, useContext } from "react";
import PageHeader from "../components/pageHeader";
import Cookies from "js-cookie";
import MyDataTable from "../components/MyDataTable";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Alert, Autocomplete, Box, FormControl, Grid, IconButton, Snackbar, TextField } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import DescriptionIcon from "@mui/icons-material/Description";
import { EtatCompany } from "../../../store/setting/context";

const IndexCompte = () => {
  const { etat, setEtat } = useContext(EtatCompany);
  const { id } = useParams();
  function generateYears() {
    var years = [];
    var currentYear = new Date().getFullYear();
    for (var i = currentYear - 2; i <= currentYear + 15; i++) {
      years.push(i);
    }
    return years;
  }
  const years = generateYears();

  const token = Cookies.get("authToken");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [cres, setCres] = useState();
  const [success, setSuccess] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [errors, setErrors] = useState();
  const [errorExist, setReerorExist] = useState();
  const [reloadData, setReloadData] = useState(false);

  //sending
  const [libelle, setLibelle] = useState();
  const [year, setAnnee] = useState();
  const [annee, setYear] = useState();
  //sending

  //ref
  const res = useRef();

  const reflib = useRef();
  const refanne = useRef();
  const Content = () => {
    return (
      <Box>
        <form ref={res}>
          <Grid container spacing={2} className="pt-3 d-flex justify-content-center align-items-center">
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <Autocomplete
                  id="country-select-demo2"
                  onChange={(event, newValue) => {
                    setYear(newValue);
                  }}
                  value={annee}
                  options={years}
                  autoHighlight
                  fullWidth
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choisir l'année"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      ref={refanne}
                      error={errors && errors.annee}
                      helperText={errors && errors.annee && errors.annee.join(", ")}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  };

  const columns = [
    { field: "libelle", headerName: "Libelle", flex: 1, sortable: true, minWidth: 150, renderCell: (params) => <>{libelle}</> },
    { field: "annee", headerName: "Année", flex: 1, sortable: true, minWidth: 150 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            {/* <Link to={`/dashboard/compte-resultat/edit/${params.id}`}>
                            <IconButton aria-label="share">
                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.041506V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.31501 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.1655 4.60254L19.7315 9.16854" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </IconButton>
                        </Link> */}
            {/*<Link to={`/dashboard/enregistrement/compte/${params.id}`}>
                            <IconButton aria-label="share">
                                <svg width="20" fill="#534e79" viewBox="0 0 16 16" id="register-16px" xmlns="http://www.w3.org/2000/svg" stroke="#534e79"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="Path_184" data-name="Path 184" d="M57.5,41a.5.5,0,0,0-.5.5V43H47V31h2v.5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V31h2v.5a.5.5,0,0,0,1,0v-1a.5.5,0,0,0-.5-.5H55v-.5A1.5,1.5,0,0,0,53.5,28h-3A1.5,1.5,0,0,0,49,29.5V30H46.5a.5.5,0,0,0-.5.5v13a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,57.5,41ZM50,29.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5V31H50Zm11.854,4.646-2-2a.5.5,0,0,0-.708,0l-6,6A.5.5,0,0,0,53,38.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.354-.146l6-6A.5.5,0,0,0,61.854,34.146ZM54,40V38.707l5.5-5.5L60.793,34.5l-5.5,5.5Zm-2,.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,52,40.5Zm0-3a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,52,37.5ZM54.5,35h-5a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1Z" transform="translate(-46 -28)"></path> </g></svg>
                            </IconButton>
                        </Link>*/}
            <Link to={`/dashboard/details-enregistrement/compte/${params.id}`}>
              <IconButton aria-label="share">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  {" "}
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />{" "}
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />{" "}
                </svg>
              </IconButton>
            </Link>
            <IconButton aria-label="settings" onClick={() => handleDeleteCompte(params.id)}>
              <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="red">
                <path
                  d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                  stroke="red"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path d="M20.708 6.23975H3.75" stroke="red" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path
                  d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.150258 6.23973 7.01758 6.23973"
                  stroke="red"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    setOpenDialog(false);
    setErrors();
    setSuccess();
    setReerorExist();
    setLibelle();
    setYear();
  };

  const focusOnError = (errors, refs) => {
    for (const field in errors) {
      if (errors[field] && refs[field] && refs[field].current) {
        refs[field].current.focus({ behavior: "smooth" });
        break;
      }
    }
  };
  const handleAddCompte = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/cres/store`,
        {
          libelle,
          annee,
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setErrors();
        setReerorExist();
        setSuccess(response.data.message);
        setOpenDialog(false);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
        res.current.reset();
        setLibelle();
        setYear();
      } else {
        setSuccess();
        focusOnError(response.data.errors, {
          libelle: reflib,
          annee: refanne,
        });
        setErrors(response.data.errors);
        setReerorExist(response.data.errorExist);
        response.data.errorExist &&
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCompte = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}admin/cr/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCompte = async (id) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce compte résultat ? Cela risque de supprimer des informations sensibles.");

    if (!confirmDelete) {
      return;
    }

    toast
      .promise(deleteCompte(id), {
        pending: "Suppression en cours...",
        success: "Suppression realisée avec succès!",
        error: "Il y'a eu une erreur lors de la suppression",
      })
      .then(() => {
        setReloadData((prev) => !prev);
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression des informations", error);
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/cres/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const datas = response.data;
        console.log(datas);
        setLibelle(`Compte de resultat ${datas.entreprise && datas.entreprise.raison_sociale}`);
        setEtat(datas.entreprise && datas.entreprise.statut);
        setCres(datas.cres);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reloadData]);


  return (
    <>
      {success ? (
        <Snackbar open={openSnack} autoHideDuration={5000} onClose={handleCloseSnack} action={action} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleCloseSnack} severity="success" sx={{ width: "100%" }}>
            {success}
          </Alert>
        </Snackbar>
      ) : (
        errorExist && (
          <Snackbar open={openSnack} onClose={handleCloseSnack} action={action} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <Alert onClose={handleCloseSnack} severity="error" sx={{ width: "100%" }}>
              {errorExist}
            </Alert>
          </Snackbar>
        )
      )}

      <PageHeader
        title={libelle}
        dialogTitle="Enregistrement"
        subtitle="Liste des comptes de resultats"
        labelButton="Créer"
        content={Content()}
        loading={loading}
        icon={<DescriptionIcon sx={{ color: "#3887BE", fontSize: "2em" }} />}
        onClick={handleAddCompte}
        onClose={handleClose}
        open={openDialog}
        setOpen={setOpenDialog}
      />

      <MyDataTable data={cres} columns={columns} />
      <ToastContainer autoClose={5000} hideProgressBar={true} pauseOnHover={false} />
    </>
  );
};

export default IndexCompte;
