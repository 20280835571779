import React, { useRef, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PageHeader from "../components/pageHeader";
import MyDataTable from "../components/MyDataTable";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NotesIcon from "@mui/icons-material/Notes";
import InfoIcon from "@mui/icons-material/Info";
import PaidIcon from "@mui/icons-material/Paid";
import {
  Box,
  FormControl,
  Grid,
  styled,
  Card,
  Collapse,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Slider,
  Alert,
  Avatar,
  MenuItem,
  Stack,
  TextField,
  Autocomplete,
  InputAdornment,
  CircularProgress,
  ListItemText,
  Skeleton,
  Pagination,
  Badge,
  Tooltip,
  Menu,
  Button,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { countries, domaines, juridiques, regimes } from "../components/liste";
import Cookies from "js-cookie";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { motion, AnimatePresence } from "framer-motion";
import { NumericFormat } from "react-number-format";
import Lottie from "lottie-web";
import animationData from "../components/animations/not-found.json";
import loadingwait from "../components/animations/loading.json";
import Dropzone from "react-dropzone";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { secteurs } from "../components/liste";

const Entreprise = () => {
  dayjs.locale("fr");
  var cc = require("currency-codes");
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [entreprises, setEntreprise] = useState();
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [logoPreview, setLogoPreview] = useState("");
  const [logoPreviewParrain, setLogoPreviewParrain] = useState("");

  const [cardPerPage, setCardPerPage] = useState(10);

  const token = Cookies.get("authToken");

  const res = useRef();
  const refpa = useRef();
  const refmo = useRef();
  const refno = useRef();
  const reflo = useRef();
  const refty = useRef();
  const refvi = useRef();
  const refco = useRef();
  const refru = useRef();
  const refra = useRef();
  const refac = useRef();
  const refdo = useRef();
  const refsi = useRef();
  const refdac = useRef();
  const refdaa = useRef();
  const refdaf = useRef();
  const refdas = useRef();
  const refcot = useRef();
  const refcotcnps = useRef();
  const refcn = useRef();
  const refdf = useRef();
  const refrc = useRef();
  const refnba = useRef();
  const refnpb = useRef();
  const refvp = useRef();
  const refcap = useRef();
  const refinv = useRef();
  const refcre = useRef();
  const refdet = useRef();
  const refch = useRef();
  const refval = useRef();
  const refep = useRef();
  const refga = useRef();
  const reftit = useRef();
  const refdept = useRef();
  const refres = useRef();
  const reflas = useRef();
  const loadingref = useRef();
  const containerRef = useRef();
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  //sending
  const [pays, setPays] = useState();
  const [agent, setAgent] = useState();
  const [ace, setAce] = useState();

  const [monnaie, setMonnaie] = useState();
  const [note, setNote] = useState(5);
  const [logo, setLogo] = useState();
  const [logoParrain, setLogoParrain] = useState();

  const [typeEntreprise, setTypeEntreprise] = useState();
  const [ville, setVille] = useState();
  const [secteur, setSecteur] = useState();
  const [commune, setCommune] = useState();
  const [rue, setRue] = useState();
  const [raisonSociale, setRaisonSociale] = useState();
  const [activitePrincipale, setActivitePrincipale] = useState();
  const [domaine, setDomaine] = useState();
  const [forme, setForme] = useState();
  const [regime, setRegime] = useState();
  const [parrain, setParrain] = useState();

  const [siege, setSiege] = useState();
  const [taux, setTaux] = useState(35.0);
  const [tauxChargeSociale, setTauxChargeSociale] = useState(84.0);
  const [tauxParticipationTravailler, setTauxParticipationTravailler] = useState(10.0);
  const [dateCreation, setDateCreation] = useState();
  const [dateAdhesion, setDateAdhesion] = useState();
  const [dateFormation, setDateFormation] = useState();
  const [dateSelection, setDateSelection] = useState();
  const [cotisation, setCotisation] = useState();
  const [cnps, setCnps] = useState();
  const [cotisationCnps, setCotisationCnps] = useState();
  const [cotisationCmu, setCotisationCmu] = useState();
  const [dfe, setDfe] = useState();
  const [rccm, setRccm] = useState();
  const [nbAssocie, setNbAssocie] = useState(1);
  const [nbPart, setNbpart] = useState();
  const [valeurPart, setValeurPart] = useState();
  const [capital, setCapital] = useState();
  const [investissement, setInvestissement] = useState();
  const [creance, setCreance] = useState();
  const [dette, setDette] = useState();
  const [chiffreAffaire, setChiffreAffaire] = useState();
  const [valeurStock, setValeurStock] = useState();
  const [epargne, setEpargne] = useState();
  const [gage, setGage] = useState();
  const [titrePlacement, setTitrePlacement] = useState();
  const [depotTerme, setDepoTerme] = useState();
  const [reserve, setReserve] = useState();
  const [lastUpdate, setLastUpdate] = useState();
  //endsending

  const handleClose = () => {
    setOpenDialog(false);
    setErrors();
    setSuccess();
    setOpenDialog(false);
    res.current && res.current.reset();
    setPays();
    setMonnaie("");
    // setNote()
    setTaux();
    setTauxChargeSociale();
    setTauxParticipationTravailler();
    setLogo();
    setLogoParrain();
    setParrain();
    setTypeEntreprise();
    setCotisationCmu();
    setVille();
    setCommune();
    setRue();
    setRaisonSociale();
    setActivitePrincipale();
    setDomaine();
    setSiege();
    setDateCreation();
    setDateAdhesion();
    setDateFormation();
    setDateSelection();
    setCotisation();
    setCnps();
    setCotisationCnps();
    setDfe();
    setRccm();
    setNbAssocie(1);
    setNbpart();
    setMonnaie();
    setValeurPart();
    setCapital();
    setInvestissement();
    setCreance();
    setDette();
    setChiffreAffaire();
    setValeurStock();
    setEpargne();
    setGage();
    setTitrePlacement();
    setDepoTerme();
    setReserve();
    setForme();
    // setLastUpdate(null)
  };
  const focusOnError = (errors, refs) => {
    for (const field in errors) {
      if (errors[field] && refs[field] && refs[field].current) {
        refs[field].current.focus({ behavior: "smooth" });
        break;
      }
    }
  };

  const handleDrop = (acceptedFiles) => {
    setLogo(acceptedFiles);

    const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
    setLogoPreview(logoPreviewURL);
  };

  const handleDropParrain = (acceptedFiles) => {
    setLogoParrain(acceptedFiles);

    const logoPreviewURL = URL.createObjectURL(acceptedFiles[0]);
    setLogoPreviewParrain(logoPreviewURL);
  };

  const handleRemove = () => {
    setLogo();
  };
  const handleRemoveParrain = () => {
    setLogoParrain();
  };
  const Content = () => {
    return (
      <Box>
        <form ref={res}>
          <Stack>
            <span style={{ fontSize: ".85em", fontWeight: "lighter", color: "#4E5053", marginBottom: ".95em" }}>Informations générales de l'entreprise</span>
            <Grid container spacing={2}>
              <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                <div className="col-lg-3 col-md-5  col-8 rounded-circle ">
                  <Dropzone onDrop={handleDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <section className="mb-2">
                        <div {...getRootProps()}>
                          {!logo && <input {...getInputProps()} />}
                          <Box>
                            <Badge
                              role="button"
                              overlap="circular"
                              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                              sx={{
                                borderRadius: "50%",
                                boxShadow: "22px 22px 58px #d0d0d0,-22px -22px 58px #f0f0f0",
                              }}
                              badgeContent={
                                <>
                                  {!logo ? (
                                    <IconButton
                                      aria-label="share"
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                        "&:hover": {
                                          backgroundColor: "white",
                                        },
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      aria-label="share"
                                      onClick={handleRemove}
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                        "&:hover": {
                                          backgroundColor: "white",
                                        },
                                      }}
                                    >
                                      <DeleteSweepIcon />
                                    </IconButton>
                                  )}
                                </>
                              }
                            >
                              <Avatar alt="Logo" src={logo ? logoPreview : "not"} role="button" loading="lazy" sx={{ width: 120, height: 120 }} />
                            </Badge>
                          </Box>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </Grid>
              <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <TextField ref={refvi} variant="outlined" onChange={(e) => setParrain(e.target.value)} label="Parrain" value={parrain} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} className="d-flex justify-content-center align-items-center">
                    <Dropzone onDrop={handleDropParrain}>
                      {({ getRootProps, getInputProps }) => (
                        <Tooltip title="Logo du parrain">
                          <section className="mb-2">
                            <div {...getRootProps()}>
                              {!logoParrain && <input {...getInputProps()} />}
                              <Box>
                                <Badge
                                  role="button"
                                  overlap="circular"
                                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                  sx={{
                                    borderRadius: "50%",
                                    boxShadow: "22px 22px 58px #d0d0d0,-22px -22px 58px #f0f0f0",
                                  }}
                                  badgeContent={
                                    <>
                                      {!logoParrain ? (
                                        <IconButton
                                          aria-label="share"
                                          sx={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                            "&:hover": {
                                              backgroundColor: "white",
                                            },
                                          }}
                                        >
                                          <EditIcon sx={{ width: 10, height: 10 }} />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          aria-label="share"
                                          onClick={handleRemoveParrain}
                                          sx={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            boxShadow: "20px 20px 59px #c5c5c5,-20px -20px 59px #fbfbfb",
                                            "&:hover": {
                                              backgroundColor: "white",
                                            },
                                          }}
                                        >
                                          <DeleteSweepIcon sx={{ width: 10, height: 10 }} />
                                        </IconButton>
                                      )}
                                    </>
                                  }
                                >
                                  <Avatar
                                    alt="Logo Parrain"
                                    src={logoParrain ? logoPreviewParrain : "Parrain"}
                                    role="button"
                                    loading="lazy"
                                    sx={{ width: 50, height: 50 }}
                                  />
                                </Badge>
                              </Box>
                            </div>
                          </section>
                        </Tooltip>
                      )}
                    </Dropzone>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="country-select-demo"
                    value={pays}
                    onChange={(event, newValue) => {
                      setPays(newValue ? newValue : null);
                    }}
                    fullWidth
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label} ({option.code}) +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choisir le pays"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        ref={refpa}
                        error={errors && errors.pays}
                        helperText={errors && errors.pays && errors.pays.join(", ")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    ref={refvi}
                    variant="outlined"
                    onChange={(e) => setVille(e.target.value)}
                    label="Ville"
                    error={errors && errors.ville}
                    helperText={errors && errors.ville && errors.ville.join(", ")}
                    value={ville}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    ref={refco}
                    variant="outlined"
                    onChange={(e) => setCommune(e.target.value)}
                    label="Commune"
                    error={errors && errors.commune}
                    helperText={errors && errors.commune && errors.commune.join(", ")}
                    value={commune}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    ref={refru}
                    variant="outlined"
                    onChange={(e) => setRue(e.target.value)}
                    label="Rue"
                    error={errors && errors.rue}
                    helperText={errors && errors.rue && errors.rue.join(", ")}
                    value={rue}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    tabIndex={-1}
                    ref={refra}
                    error={errors && errors.raisonSociale}
                    onChange={(e) => setRaisonSociale(e.target.value)}
                    label="Raison sociale"
                    helperText={errors && errors.raisonSociale && errors.raisonSociale.join(", ")}
                    value={raisonSociale}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    tabIndex={-1}
                    ref={refac}
                    variant="outlined"
                    onChange={(e) => setActivitePrincipale(e.target.value)}
                    label="Activité principale"
                    error={errors && errors.activitePrincipale}
                    helperText={errors && errors.activitePrincipale && errors.activitePrincipale.join(", ")}
                    value={activitePrincipale}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="country-select-demo2"
                    onChange={(event, newValue) => {
                      setTypeEntreprise(newValue);
                    }}
                    value={typeEntreprise}
                    options={domaines}
                    autoHighlight
                    fullWidth
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type d'entreprise"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="country-select-demo2"
                    onChange={(event, newValue) => {
                      setRegime(newValue);
                    }}
                    value={regime}
                    options={regimes}
                    autoHighlight
                    fullWidth
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Régime d'imposition"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="country-select-demo2"
                    onChange={(event, newValue) => {
                      setSecteur(newValue);
                    }}
                    value={secteur}
                    options={secteurs}
                    autoHighlight
                    fullWidth
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Secteur d'activité"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    tabIndex={-1}
                    ref={refsi}
                    variant="outlined"
                    onChange={(e) => setSiege(e.target.value)}
                    label="Siege"
                    error={errors && errors.siege}
                    helperText={errors && errors.siege && errors.siege.join(", ")}
                    value={siege}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="country-select-demo2"
                    onChange={(event, newValue) => {
                      setForme(newValue);
                    }}
                    value={forme}
                    options={juridiques}
                    autoHighlight
                    fullWidth
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Forme juridique"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="country-select-demo2"
                    onChange={(event, newValue) => {
                      setMonnaie(newValue ? newValue : null);
                    }}
                    value={monnaie}
                    options={cc.data}
                    autoHighlight
                    fullWidth
                    getOptionLabel={(option) => (option ? option.currency : "")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choisir la monnaie"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        ref={refmo}
                        error={errors && errors.monnaie}
                        helperText={errors && errors.monnaie && errors.monnaie.join(", ")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>

                                    <NumericFormat
                                        tabIndex={-1}
                                        label="Cotisation mensuelle"
                                        error={errors && errors.cotisation}
                                        helperText={errors && errors.cotisation && errors.cotisation.join(', ')}
                                        fixedDecimalScale
                                        allowLeadingZeros
                                        thousandSeparator=","
                                        customInput={TextField}
                                        value={cotisation}
                                        onValueChange={(value) => setCotisation((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : "")}
                                        allowNegative={false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{(monnaie && monnaie !== "") && monnaie.code}</InputAdornment>,
                                        }}
                                    />

                                </FormControl>
                            </Grid> */}

              <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                  <FormControl fullWidth>
                    <DatePicker onChange={(date) => setDateCreation(date)} label="Date de création" value={dateCreation} />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                  <FormControl fullWidth>
                    <DatePicker onChange={(date) => setDateAdhesion(date)} fullWidth label="Date d'adhésion" value={dateAdhesion} />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                  <FormControl fullWidth>
                    <DatePicker onChange={(date) => setDateSelection(date)} fullWidth label="Date de selection" value={dateSelection} />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                  <FormControl fullWidth>
                    <DatePicker onChange={(date) => setDateFormation(date)} fullWidth label="Date de formation" value={dateFormation} />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <br />
            <span style={{ fontSize: ".85em", fontWeight: "lighter", color: "#4E5053", margin: ".95em 0 .95em 0" }}>Informations financières de l'entreprise</span>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refnba}
                    variant="outlined"
                    onValueChange={(value) => setNbAssocie(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Nombre d'associés"
                    error={errors && errors.nbAssocie}
                    helperText={errors && errors.nbAssocie && errors.nbAssocie.join(", ")}
                    value={nbAssocie}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refnpb}
                    variant="outlined"
                    onValueChange={(value) => setNbpart(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Nombre de parts"
                    error={errors && errors.nbPart}
                    helperText={errors && errors.nbPart && errors.nbPart.join(", ")}
                    value={nbPart}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refval}
                    variant="outlined"
                    onValueChange={(value) => setValeurPart(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Valeur de la part"
                    error={errors && errors.valeurPart}
                    helperText={errors && errors.valeurPart && errors.valeurPart.join(", ")}
                    value={valeurPart}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refcap}
                    variant="outlined"
                    label="Capital"
                    error={errors && errors.capital}
                    helperText={errors && errors.capital && errors.capital.join(", ")}
                    value={nbPart * valeurPart}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refinv}
                    variant="outlined"
                    onValueChange={(value) => setInvestissement(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Investissement"
                    error={errors && errors.investissement}
                    helperText={errors && errors.investissement && errors.investissement.join(", ")}
                    value={investissement}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refcre}
                    variant="outlined"
                    onValueChange={(value) => setCreance(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Créance"
                    error={errors && errors.creance}
                    helperText={errors && errors.creance && errors.creance.join(", ")}
                    value={creance}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refdet}
                    variant="outlined"
                    onValueChange={(value) => setDette(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Dette"
                    error={errors && errors.dette}
                    helperText={errors && errors.dette && errors.dette.join(", ")}
                    value={dette}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refch}
                    variant="outlined"
                    onValueChange={(value) => setChiffreAffaire(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Chiffre d'affaire"
                    error={errors && errors.chiffreAffaire}
                    helperText={errors && errors.chiffreAffaire && errors.chiffreAffaire.join(", ")}
                    value={chiffreAffaire}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refval}
                    variant="outlined"
                    onValueChange={(value) => setValeurStock(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Valeur du stock"
                    error={errors && errors.valeurStock}
                    helperText={errors && errors.valeurStock && errors.valeurStock.join(", ")}
                    value={valeurStock}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refep}
                    variant="outlined"
                    onValueChange={(value) => setEpargne(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Epargne"
                    error={errors && errors.epargne}
                    helperText={errors && errors.epargne && errors.epargne.join(", ")}
                    value={epargne}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refga}
                    variant="outlined"
                    onValueChange={(value) => setGage(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Gage"
                    error={errors && errors.gage}
                    helperText={errors && errors.gage && errors.gage.join(", ")}
                    value={gage}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={reftit}
                    variant="outlined"
                    onValueChange={(value) => setTitrePlacement(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Titre du placement"
                    error={errors && errors.titrePlacement}
                    helperText={errors && errors.titrePlacement && errors.titrePlacement.join(", ")}
                    value={titrePlacement}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refdept}
                    variant="outlined"
                    onValueChange={(value) => setDepoTerme(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Dépot à terme"
                    error={errors && errors.depotTerme}
                    helperText={errors && errors.depotTerme && errors.depotTerme.join(", ")}
                    value={depotTerme}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    ref={refres}
                    variant="outlined"
                    onValueChange={(value) => setReserve(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Réserve"
                    error={errors && errors.reserve}
                    helperText={errors && errors.reserve && errors.reserve.join(", ")}
                    value={reserve}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <NumericFormat
                    tabIndex={-1}
                    label="Cotisation mensuelle"
                    error={errors && errors.cotisation}
                    helperText={errors && errors.cotisation && errors.cotisation.join(", ")}
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    value={cotisation}
                    onValueChange={(value) => setCotisation(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    allowNegative={false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    variant="outlined"
                    onValueChange={(value) => setCotisationCnps(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Cotisation CNPS"
                    error={errors && errors.cotisationCnps}
                    helperText={errors && errors.cotisationCnps && errors.cotisationCnps.join(", ")}
                    value={cotisationCnps}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    variant="outlined"
                    onValueChange={(value) => setCotisationCmu(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : "")}
                    label="Cotisation CMU"
                    value={cotisationCmu}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{monnaie && monnaie !== "" && monnaie.code}</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <span style={{ fontSize: ".85em", fontWeight: "lighter", color: "#4E5053", margin: ".95em 0 .95em 0" }}>
              Informations administratives de l'entreprise
            </span>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    tabIndex={-1}
                    ref={refrc}
                    variant="outlined"
                    onChange={(e) => setRccm(e.target.value)}
                    label="Numero RCCM"
                    error={errors && errors.rccm}
                    helperText={errors && errors.rccm && errors.rccm.join(", ")}
                    value={rccm}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    tabIndex={-1}
                    ref={refdf}
                    variant="outlined"
                    onChange={(e) => setDfe(e.target.value)}
                    label="Numero DFE"
                    error={errors && errors.dfe}
                    helperText={errors && errors.dfe && errors.dfe.join(", ")}
                    value={dfe}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    tabIndex={-1}
                    ref={refcn}
                    variant="outlined"
                    onChange={(e) => setCnps(e.target.value)}
                    label="Numero CNPS"
                    error={errors && errors.cnps}
                    helperText={errors && errors.cnps && errors.cnps.join(", ")}
                    value={cnps}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <span style={{ fontSize: ".85em", fontWeight: "lighter", color: "#4E5053", margin: ".95em 0 .95em 0" }}>Informations supplémentaires</span>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    variant="outlined"
                    onValueChange={(value) => setTaux(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : 0.0)}
                    label="Taux d'impot sur le bénéfice"
                    allowNegative={false}
                    value={taux}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    value={tauxChargeSociale}
                    variant="outlined"
                    onValueChange={(value) => setTauxChargeSociale(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : 0.0)}
                    label="Taux de charge de sécurité sociale"
                    allowNegative={false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    value={tauxParticipationTravailler}
                    variant="outlined"
                    onValueChange={(value) => setTauxParticipationTravailler(value !== "undefined" && value.floatValue !== "undefined" ? value.floatValue : 0.0)}
                    label="Taux participation des travailleurs"
                    allowNegative={false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Box>
    );
  };
  //fonctions api
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/entreprises`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const datas = response.data;
        setEntreprise(datas.entreprises);
        setAce(datas.aces);
        setAgent(datas.agents);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reloadData]);

  const handleAddEntreprise = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/entreprises/store`,
        {
          pays,
          parrain,
          logoParrain,
          logo,
          typeEntreprise,
          ville,
          commune,
          rue,
          raisonSociale,
          activitePrincipale,
          siege,
          dateCreation,
          dateAdhesion,
          dateFormation,
          dateSelection,
          cotisation,
          cotisationCnps,
          cotisationCmu,
          cnps,
          cotisationCnps,
          dfe,
          rccm,
          nbAssocie,
          nbPart,
          monnaie,
          valeurPart,
          investissement,
          creance,
          dette,
          chiffreAffaire,
          valeurStock,
          epargne,
          gage,
          titrePlacement,
          depotTerme,
          reserve,
          secteur,
          forme,
          regime,
          taux,
          tauxChargeSociale,
          tauxParticipationTravailler,
          // lastUpdate
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setErrors();
        setSuccess(response.data.message);
        setOpenDialog(false);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
        res.current.reset();
        setPays();
        setMonnaie();
        setNote();
        setLogo();
        setTypeEntreprise();
        setVille();
        setCommune();
        setRue();
        setRaisonSociale();
        setActivitePrincipale();
        setDomaine();
        setSiege();
        setDateCreation();
        setDateAdhesion();
        setDateFormation();
        setDateSelection();
        setCotisation();
        setCotisationCnps();
        setCotisationCmu();
        setCnps();
        setDfe();
        setRccm();
        setNbAssocie(1);
        setNbpart();
        setValeurPart();
        setCapital();
        setInvestissement();
        setCreance();
        setDette();
        setChiffreAffaire();
        setValeurStock();
        setEpargne();
        setGage();
        setTitrePlacement();
        setDepoTerme();
        setReserve();
        setLastUpdate();
        setSecteur();
        setRegime();
        setForme();
        setParrain();
        setLogoParrain();
      } else {
        setSuccess();
        focusOnError(response.data.errors, {
          pays: refpa,
          monnaie: refmo,
          note: refno,
          logo: reflo,
          typeEntreprise: refty,
          ville: refvi,
          commune: refco,
          rue: refru,
          raisonSociale: refra,
          activitePrincipale: refac,
          domaine: refdo,
          siege: refsi,
          dateCreation: refdac,
          dateAdhesion: refdaa,
          dateFormation: refdaf,
          dateSelection: refdas,
          cotisation: refcot,
          cotisationCnps: refcotcnps,
          cnps: refcn,
          dfe: refdf,
          rccm: refrc,
          nbAssocie: refnba,
          nbPart: refnpb,
          valeurPart: refvp,
          capital: refcap,
          investissement: refinv,
          creance: refcre,
          dette: refdet,
          chiffreAffaire: refch,
          valeurStock: refval,
          epargne: refep,
          gage: refga,
          titrePlacement: reftit,
          depotTerme: refdept,
          reserve: refres,
          lastUpdate: reflas,
        });
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteObjectif = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}admin/entreprises/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ces informations ?");

    if (!confirmDelete) {
      return;
    }

    toast
      .promise(deleteObjectif(id), {
        pending: "Suppression en cours...",
        success: "Suppression realisée avec succès!",
        error: "Il y'a eu une erreur lors de la suppression",
      })
      .then(() => {
        setReloadData((prev) => !prev);
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression des informations", error);
      });
  };
  //fin
  const action = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const searchFields = [
    "raison_sociale",
    "pays",
    "ville",
    "com_prov",
    "rue",
    "activite_principale",
    "forme_juridique",
    "siege",
    "type_entreprise",
    "cnps",
    "dfe",
    "rccm",
    "monnaie",
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * cardPerPage;
  const indexOfFirstItem = indexOfLastItem - cardPerPage;
  const currentItems = entreprises && entreprises.slice(indexOfFirstItem, indexOfLastItem);
  const filteredItems =
    searchTerm !== "" && entreprises
      ? entreprises.filter((entreprise) => searchFields.some((field) => entreprise[field]?.toLowerCase().includes(searchTerm.toLowerCase())))
      : currentItems && currentItems.filter((entreprise) => searchFields.some((field) => entreprise[field]?.toLowerCase().includes(searchTerm.toLowerCase())));

  const totalPages = Math.ceil(entreprises && entreprises.length / cardPerPage);
  const totalPagesFiltered = Math.ceil(filteredItems && filteredItems.length / cardPerPage);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    handleChangePage(null, 1);
  }, [searchTerm]);

  useEffect(() => {
    filteredItems && filteredItems.length == 0 ? setIsEmpty(true) : setIsEmpty(false);
  }, [filteredItems]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: containerRef.current,
      animationData: animationData,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, [isEmpty]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);
  const container = {
    hide: {
      opacity: 0,
      scale: 0,
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.3,
      },
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };
  const item = {
    hide: {
      y: -50,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentCard, setCurrentCard] = React.useState(null);

  const openDrop = Boolean(anchorEl);

  const handleClickDrop = (event, entrepriseId) => {
    setAnchorEl(event.currentTarget);
    setCurrentCard(entrepriseId); 
  };

  const handleCloseDrop = () => {
    setAnchorEl(null);
    setCurrentCard(null); // Clear the current card id
  };

  const exportInfos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/generate-all-company-cotisations-pdf`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob", // Important pour télécharger le PDF correctement
      });

      // Créer une URL pour le blob
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));

      // Créer un lien et cliquer dessus pour télécharger le fichier
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `COTISATION_DE_TOUTE_LES_ENTREPRISES.pdf`);
      document.body.appendChild(link);
      link.click();
      // Nettoyer l'URL de l'objet après le téléchargement
      window.URL.revokeObjectURL(url);
      toast.success("Exportation realisée avec succès!");
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la génération du PDF");
    }
  };

  const handleGeneratePDF = async () => {
    toast
      .promise(exportInfos(), {
        pending: "Exportation en cours...",
      })
      .then(() => {})
      .catch((error) => {
        console.error("Erreur lors de l'exportation des informations", error);
      });
  };

  return (
    <>
      {success && (
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseSnack} action={action} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleCloseSnack} severity="success" sx={{ width: "100%" }}>
            {success}
          </Alert>
        </Snackbar>
      )}
      <PageHeader
        title="Entreprises"
        dialogTitle="Enregistrement des informations d'une entreprise"
        subtitle="Liste des entreprises"
        labelButton="Ajouter une entreprise"
        content={Content()}
        loading={loading}
        icon={<HomeWorkIcon sx={{ color: "#3887BE", fontSize: "2em" }} />}
        onClick={handleAddEntreprise}
        onClose={handleClose}
        open={openDialog}
        setOpen={setOpenDialog}
        print
        handleGeneratePDF={handleGeneratePDF}
      />
      {/* <MyDataTable
                data={entreprises}
                columns={columns}
            /> */}
      <br />
      {filteredItems && (
        <motion.div transition={{ duration: 0.3, ease: "easeInOut" }}>
          <Stack spacing={2} direction="row">
            <FormControl className="col-8 col-md-10">
              <TextField
                variant="standard"
                onChange={(e) => setSearchTerm(e.target.value)}
                label="Rechercher ici"
                value={searchTerm}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl className="col-4 col-md-2">
              <TextField select value={cardPerPage} label="Cadre par page" onChange={(e) => setCardPerPage(e.target.value)} sx={{ minWidth: "3em" }}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={entreprises && entreprises.length}>Tout</MenuItem>
              </TextField>
            </FormControl>
          </Stack>
        </motion.div>
      )}
      {!filteredItems ? (
        <div className=" d-flex justify-content-center align-items-center" style={{ minHeight: "45vh" }}>
          <div style={{ maxHeight: "100px", maxWidth: "100px" }} ref={loadingref}></div>
        </div>
      ) : filteredItems.length > 0 ? (
        <motion.div variants={container} initial="hide" animate="show" exit="exit" className="my-2">
          <Grid container spacing={2} className="my-1">
            <AnimatePresence>
              {filteredItems &&
                filteredItems.map((entreprise, index) => {
                  return (
                    <Grid item xs={12} sm={6} key={index}>
                      <motion.div
                        whileHover={{
                          animation: "ease",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                        variants={item}
                        exit={{ y: 50, opacity: 0 }}
                      >
                        <Card>
                          <CardHeader
                            avatar={
                              !entreprise.logo ? (
                                <Skeleton variant="circular" width={40} height={40} />
                              ) : (
                                <Avatar
                                  alt={`${entreprise.raison_sociale}`}
                                  src={`${process.env.REACT_APP_BASE_URL}${entreprise.logo}`}
                                  role="button"
                                  loading="lazy"
                                />
                              )
                            }
                            title={`${entreprise.raison_sociale} (${
                              entreprise.activite_principale
                                ? `${entreprise.activite_principale}, ${entreprise.forme_juridique ? `${entreprise.forme_juridique}` : ""}`
                                : ""
                            })`}
                            subheader={`${entreprise.type_entreprise !== null ? `${entreprise.type_entreprise}` : ""} ${
                              entreprise.siege !== null ? `${entreprise.siege}` : ""
                            } ${JSON.parse(entreprise.pays) !== null ? `${JSON.parse(entreprise.pays).label}` : ""}`}
                          />
                          <CardContent>
                            <div className="small">
                              {" "}
                              <span className="fw-bold">Agent ACE:</span> {entreprise.nom_ace ? entreprise.nom_ace + " " + entreprise.prenoms_ace : "Non attribué"}{" "}
                            </div>
                            <div className="small">
                              {" "}
                              <span className="fw-bold">Agent de notation:</span>{" "}
                              {entreprise.nom_agent ? entreprise.nom_agent + " " + entreprise.prenoms_agent : "Non attribué"}{" "}
                            </div>
                            {/* <div className='small' > <span className='fw-bold' >Administrateur:</span>  {entreprise.nom_admin ? (entreprise.nom_admin + ' ' + entreprise.prenoms_admin) : 'Non attribué'} </div> */}
                            <div className="small">
                              {" "}
                              <span className="fw-bold">Parrain:</span> {entreprise.parrain ? entreprise.parrain : "Pas de parrain"}{" "}
                            </div>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="fw-bold">Capital</TableCell>
                                    <TableCell className="fw-bold">Investissement</TableCell>
                                    <TableCell className="fw-bold">Chiffre d'affaire</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell>{entreprise.capital && entreprise.capital.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                    <TableCell>{entreprise.investissement && entreprise.investissement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                    <TableCell>
                                      {entreprise.chiffre_affaire && entreprise.chiffre_affaire.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="fw-bold">Monnaie</TableCell>
                                    <TableCell className="text-end" colSpan={2}>
                                      {JSON.parse(entreprise.monnaie).currency}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                          <CardActions className="d-flex justify-content-end" disableSpacing>
                            <>
                              <IconButton
                                onClick={(e)=>handleClickDrop(e,entreprise.id)}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={openDrop ? "account-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={openDrop ? "true" : undefined}
                              >
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={openDrop && currentCard == entreprise.id}
                                onClose={handleCloseDrop}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&::before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform: "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                              >
                                <MenuItem>
                                  <Link to={`/dashboard/entreprise-comptes/${entreprise.id}`} className="w-100 btn btn-sm btn-icon text-start">
                                    <NotesIcon />
                                    &nbsp;
                                    <span className="text-black">Acceder aux comptes de resultats</span>
                                  </Link>
                                </MenuItem>
                                <MenuItem>
                                  <Link to={`/dashboard/gestion-entreprise/show/${entreprise.id}`} className="w-100 btn btn-sm btn-icon text-start">
                                    <InfoIcon />
                                    &nbsp;
                                    <span className="text-black">Voir plus d'informations</span>
                                  </Link>
                                </MenuItem>
                                <MenuItem>
                                  <Link to={`/dashboard/gestion-entreprise/edit/${entreprise.id}`} className="w-100 btn btn-sm btn-icon text-start">
                                    <EditIcon />
                                    &nbsp;
                                    <span className="text-black">Modifier des informations</span>
                                  </Link>
                                </MenuItem>
                                <MenuItem>
                                  <Link to={`/dashboard/gestion-entreprise/cotisation/${entreprise.id}`} className="w-100 btn btn-sm btn-icon text-start">
                                    <PaidIcon />
                                    &nbsp;
                                    <span className="text-black">Gerer les {entreprise.cotisation_mensuelle > 10000 ? "abonnements" : "cotisations"}</span>
                                  </Link>
                                </MenuItem>
                                <MenuItem>
                                  <Box onClick={() => handleDelete(entreprise.id)} className="w-100 btn btn-sm btn-icon text-start">
                                    <DeleteSweepIcon />
                                    &nbsp;
                                    <span className="text-black">Supprimer l'entreprise</span>
                                  </Box>
                                </MenuItem>
                              </Menu>
                            </>
                          </CardActions>
                        </Card>
                      </motion.div>
                    </Grid>
                  );
                })}
            </AnimatePresence>
          </Grid>
          <br />
          <Box className="d-flex justify-content-center align-items-center">
            <Pagination
              count={searchTerm == "" ? totalPages : totalPagesFiltered}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
          <br />
        </motion.div>
      ) : filteredItems.length == 0 ? (
        <div className="text-center d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
          <motion.div
            ref={containerRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          ></motion.div>
        </div>
      ) : (
        ""
      )}

      <ToastContainer autoClose={5000} hideProgressBar={true} pauseOnHover={false} />
    </>
  );
};

export default Entreprise;
