import React from 'react'
const Logo = (props) => {

    return (
        <>
            <img src={`${process.env.REACT_APP_BASE_URL}assets/logo.png`} style={{ height: "60px", width: "60px", objectFit: "cover" }} />
        </>
    )
}

export default Logo
