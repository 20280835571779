import React from 'react';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import Cookies from "js-cookie"
import { Row, Col, Modal, Button, CloseButton } from 'react-bootstrap'
import Card from '../../../components/Card';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Alert, AlertTitle, Autocomplete, Collapse, IconButton, TextField } from '@mui/material';
import { id } from 'date-fns/locale';
import { detaildomaines } from '../components/liste';

const Objectifs = () => {
    var cc = require('currency-codes');
    const [devise, setDevise] = useState();
    const [domaine, setDomaine] = useState();
    const [prixFormation, setPrixFormation] = useState();
    const [lvlAce, setLvlAce] = useState();
    const [nbCible, setNbcible] = useState()
    const [open, setOpen] = useState(false);
    const err = useRef();
    const res = useRef();
    const [loading, setLoading] = useState(false)
    const [objectifs, setObjectifs] = useState([])
    const [errors, setErrors] = useState()
    const [success, setSuccess] = useState()

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        setSuccess(null)
        setErrors(null)
        setOpen(false)
    };
    const handleShow = () => setShow(true);
    const token = Cookies.get('authToken');


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/objectifs-ace`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setObjectifs(response.data.objectifs);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const globalfetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/objectifs-ace`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setObjectifs(response.data.objectifs);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAdd = async (e) => {
        e.preventDefault()
        setOpen(false)
        setLoading(true)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}admin/objectifs-ace/store`,
                {
                    devise,
                    domaine,
                    prixFormation,
                    lvlAce,
                    nbCible
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.success == true) {
                setOpen(true)
                setErrors(null)
                setSuccess(response.data.message)
                setTimeout(() => {
                    err.current.focus()
                }, 500);
                res.current.reset()
                setDevise(null)
                setDomaine(null)
                setPrixFormation(null)
                setLvlAce(null)
                setNbcible(null)

            } else {
                setSuccess(null)
                setErrors(response.data.errors)
                setOpen(true)
                setTimeout(() => {
                    err.current.focus()
                }, 500);
            }
        } catch (errors) {
            console.log(errors)
        } finally {
            setLoading(false)
            globalfetchData()
        }
    };
    const deleteObjectif = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}admin/objectifs/delete/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            console.log(response.data.message);
        } catch (error) {
            console.log(error.response.data.message);
        }
    };
    const handleDeleteObjectif = (id) => {
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer ceci ?');

        if (!confirmDelete) {
            return;
        }

        toast.promise(
            deleteObjectif(id),
            {
                pending: 'Suppression en cours...',
                success: "Suppression realisée avec succès!",
                error: "Il y'a eu une erreur lors de la suppression"
            }
        ).then(() => {
            globalfetchData();
        }).catch((error) => {
            console.error('Erreur lors de la suppression de l\'objectif', error);
        });

    };

    return (
        <Row>
            <Col sm="12">
                <Card>
                    <Card.Header className="d-flex justify-content-between flex-wrap">
                        <div className="header-title">
                            <h5 className="card-title mb-0 "> Objectifs mensuels et annuels des ACE </h5>
                        </div>

                        <div>
                            <Button className="text-center btn-primary btn-icon me-2 mt-lg-0 mt-md-0 mt-3" onClick={handleShow}>
                                <i className="btn-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                </i>
                                <span>Ajouter un objectif</span>
                            </Button>
                            <Modal show={show} onHide={handleClose} centered backdrop="static" size='lg'>

                                <Modal.Header closeButton>
                                    <Modal.Title>Ajouter un objectif</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Collapse ref={err} tabIndex={-1} in={open}>
                                        <Alert
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <CloseButton style={{ fontSize: '.5em', color: 'white' }} />
                                                </IconButton>
                                            }
                                            severity={errors ? "error" : success ? "success" : ""}
                                        >
                                            <AlertTitle>
                                                {success ? ("Création reussie") : errors ? ("Erreur lors de la création:") : null}
                                            </AlertTitle>
                                            {success ?
                                                (
                                                    <div>{success} <span>Pour revenir à la liste des objectifs cliquez sur le boutton ci-après ou réaliser un autre enregistrement. <span onClick={handleClose} style={{ textDecoration: "underline", cursor: 'pointer' }}> Revenir à la liste</span> </span></div>
                                                ) :
                                                errors ? (errors) :
                                                    null}
                                        </Alert>
                                    </Collapse>

                                    <br />
                                    <form ref={res}>
                                        <div className='container-fluid'>
                                            <div className='row '>
                                                <div className='col-md-6 mb-2 mb-md-0 '>
                                                    <Autocomplete
                                                        id="country-select-demo2"
                                                        onChange={(event, newValue) => {
                                                            setDomaine(newValue.value);
                                                        }}

                                                        value={domaine}
                                                        options={detaildomaines}
                                                        autoHighlight
                                                        fullWidth
                                                        getOptionLabel={(option) => option.label +' ('+option.value+')'}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Domaine d'activité"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: 'new-password',
                                                                }}

                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className='col-md-3 mb-2 mb-md-0 '>
                                                    <label htmlFor='cform'>Coût/Entreprise: </label>
                                                    <input id='cform' onChange={(e) => setPrixFormation(e.target.value)} className='form-control' type='number' />
                                                </div>
                                                <div className='col-md-3 mb-2 mb-md-0'>
                                                    <label htmlFor='lvl'>Niveau de l'ACE: </label>
                                                    <select id='domaine' onChange={(e) => setLvlAce(e.target.value)} class="form-select" aria-label="Default select example">
                                                        <option > Niveaux</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>


                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col-md-6 mb-2 mb-md-0'>

                                                    <Autocomplete
                                                        id="country-select-demo2"
                                                        onChange={(event, newValue) => {
                                                            setDevise(newValue ? newValue : null);
                                                        }}

                                                        value={devise}
                                                        options={cc.data}
                                                        autoHighlight
                                                        fullWidth
                                                        getOptionLabel={(option) => option ? option.currency : ''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Choisir la devise"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: 'new-password',
                                                                }}
                                                                
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className='col-md-6 mb-2 mb-md-0'>
                                                    <label htmlFor='nbent'>Nombre d'entreprises ciblées :</label>
                                                    <input id='nbent' onChange={(e) => setNbcible(e.target.value)} className='form-control' type='number' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3 d-flex justify-content-end'>
                                            <button className='btn btn-outline-success' onClick={handleAdd}> {loading ? "Validation en cours..." : "Valider"} </button> &nbsp;
                                            <button className='btn btn-outline-danger' type='reset' onClick={handleClose}>Fermer</button> {' '}
                                        </div>
                                    </form>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {
                            objectifs.length > 0 ? (

                                <>
                                    <Table bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Domaine</th>
                                                <th>Niveau ACE</th>
                                                <th>Nombre d'entreprise </th>
                                                <th>Coût/entreprise</th>
                                                <th>Objectifs mensuels</th>
                                                <th>Objectifs annuels</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {objectifs.map((objectif) => (
                                                <tr key={objectif.id}>
                                                    <td>{objectif.designation}</td>
                                                    <td>{objectif.niveau_objectif}</td>
                                                    <td>{objectif.nb_entreprise_target}</td>
                                                    {/* <td>{objectif.cout_formation}</td> */}
                                                    <td>
                                                    {parseFloat(objectif.cout_formation).toLocaleString('en-EN', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) + ' ' + (objectif.devise_paiement ? JSON.parse(objectif.devise_paiement).currency : '')}
                                                    </td>


                                                    {/* <td>{objectif.objectif_mensuel + ' ' + JSON.parse(objectif.devise_paiement).currency}</td>
                                                    <td>{objectif.objectif_annuel + ' ' + JSON.parse(objectif.devise_paiement).currency}</td> */}
                                                    {/* <td>
                                                    {objectif.objectif_mensuel + ' ' + (objectif.devise_paiement ? JSON.parse(objectif.devise_paiement).currency : '')}
                                                    </td>
                                                    <td>
                                                        {objectif.objectif_annuel + ' ' + (objectif.devise_paiement ? JSON.parse(objectif.devise_paiement).currency : '')}
                                                    </td> */}
                                                    <td>
                                                    {parseFloat(objectif.objectif_mensuel).toLocaleString('en-EN', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) + ' ' + (objectif.devise_paiement ? JSON.parse(objectif.devise_paiement).currency : '')}
                                                    </td>
                                                    <td>
                                                    {parseFloat(objectif.objectif_annuel).toLocaleString('en-EN', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) + ' ' + (objectif.devise_paiement ? JSON.parse(objectif.devise_paiement).currency : '')}
                                                    </td>


                                                    <td>
                                                        <Link to={`/dashboard/objectifs/edit/${objectif.id}`} data-bs-toolTip='tooltip' className="btn btn-sm btn-icon text-primary flex-end" title="Modifier informations"  >
                                                            <span className="btn-inner">
                                                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                        </Link>


                                                        <a onClick={(e) => { e.preventDefault(); handleDeleteObjectif(objectif.id) }} className="btn btn-sm btn-icon text-danger"  >
                                                            <span className="btn-inner">
                                                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                                    <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <ToastContainer

                                    />
                                </>
                            ) : objectifs.length == 0 ? (
                                <div className='text-center'>Aucune information pour le moment</div>
                            ) : !objectifs && (
                                "Chargement..."
                            )
                        }


                    </Card.Body>
                </Card>

            </Col>

        </Row>
    );
}

export default Objectifs;
