import React, { useRef, useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Lottie from 'lottie-web';
import { motion } from 'framer-motion';
import loadingwait from "../components/animations/loading.json"
import animationData from "../components/animations/not-found.json"
import { Box } from '@mui/material';
import { FormControl } from 'react-bootstrap';

const MyDataTable = (props) => {
    function QuickSearchToolbar() {
        return (

            <GridToolbarQuickFilter label="Recherchez ici" placeholder='' className='my-3' fullWidth />

        );
    }

    const { data, columns } = props
    const [isEmpty, setIsEmpty] = useState(false)


    //refs
    const loadingref = useRef()
    const containerRef = useRef()

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: loadingref.current,
            animationData: loadingwait,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        return () => {
            animation.destroy();
        };
    }, []);

    useEffect(() => {
        data && data.length == 0 ? setIsEmpty(true) : setIsEmpty(false)
    }, [data]);

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: containerRef.current,
            animationData: animationData,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        return () => {
            animation.destroy();
        };
    }, [isEmpty]);

    

    return (
        <>
            <br />
            {
                !data ? (
                    <div
                        className=' d-flex justify-content-center align-items-center' style={{ minHeight: '45vh' }}
                    >
                        <div
                            style={{ maxHeight: "100px", maxWidth: "100px" }}
                            ref={loadingref}
                        >
                        </div>
                    </div>
                ) : data.length > 0 ? (
                    <TableContainer component={Paper} className="  my-4">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                        >
                            <DataGrid
                                sx={{
                                    padding: "1em",
                                    border: "none",
                                    background: "white",
                                    boxShadow: "none",
                                    borderRadius: "3em",
                                    '& .MuiDataGrid-columnHeaders': {
                                        borderBottom: "2px solid #8792c3",
                                    },
                                    '& .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-cell:focus-within,& .MuiDataGrid-columnHeader:focus': {
                                        outline: 'none',
                                        boxShadow: 'none',
                                    },
                                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                        height: "7px"
                                    },
                                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                                        backgroundColor: "#a3aecb",
                                        borderRadius: "5px",
                                    },
                                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                                        backgroundColor: "#e0e5f0"
                                    }

                                }}
                                rows={data}
                                getRowId={(row) => row.id}
                                columns={columns}
                                autoHeight
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                slots={{ toolbar: QuickSearchToolbar }}

                                slotProps={{ toolbar: { showQuickFilter: true } }}

                                disableColumnFilter
                                disableColumnMenu
                                disableColumnSelector
                                disableDensitySelector
                                pageSizeOptions={[10, 25, 30, { label: 'Tout', value: data.length }]}
                                disableRowSelectionOnClick
                                density='comfortable'
                                localeText={{
                                    noRowsLabel: "Pas de données disponibles",
                                    noResultsOverlayLabel: "Aucun resultat",
                                    footerTotalVisibleRows: (visibleCount, totalCount) =>
                                        `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,

                                }}
                            />
                        </motion.div>

                    </TableContainer>
                ) : data.length == 0 ? (
                    <div
                        className='text-center d-flex justify-content-center align-items-center' style={{ minHeight: '60vh' }}>
                        <motion.div
                            ref={containerRef}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                        ></motion.div>
                    </div>
                ) : ""
            }


        </>
    );
}

export default MyDataTable;
